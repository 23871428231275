
    import Vue, { PropOptions } from 'vue';
    import { DateTime as LuxonDateTime } from 'luxon';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import Avatar from '@/components/Avatar.vue';
    import { UserService, PublicUser, Match } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'PastDateRow',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            match: {
                type: Object,
                required: true,
            } as PropOptions<Match>,
        },

        data() {
            return {
                monthDayText: '',
            };
        },

        watch: {
            match: {
                immediate: true,
                deep: true,
                handler(newVal: Match, oldVal: Match) {
                    if (newVal && newVal.partnerUserId) {
                        const lDate = LuxonDateTime.fromISO(newVal.date);
                        this.monthDayText = lDate.toFormat('EEE, MMM dd');
                    }
                },
            },
        },

        computed: {},

        methods: {},
    });
