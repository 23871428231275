
    import Vue from 'vue';
    import FlagChannelModal from '@/generic-modals/AdminFlagChannelModal.vue';
    import OpenTickets from '@/components/admin/OpenTickets.vue';
    import { AdminService, Ticket } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'SupportAdmin',

        components: {
            'flag-channel-modal': FlagChannelModal,
            'open-tickets-ui': OpenTickets,
        },

        props: {},

        data() {
            return {
                showFlagChannelModal: false,
                tickets: [] as Ticket[],
            };
        },

        mounted() {},

        computed: {},

        methods: {},
    });
