/*
 * Copyright 2022-2023 Lola - All rights reserved.
 * File: main.ts
 * Project: lola
 */
import Vue from 'vue';
import router from './tsfiles/router';
import './plugins/bootstrap-vue';
import './plugins/axios';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import { ToggleButton } from 'vue-js-toggle-button';
import Vue2TouchEvents from 'vue2-touch-events';
import store from './tsfiles/store';
import config from '@/config';
import { log } from '@/tsfiles/errorlog';
import { Utils } from '@/tsfiles/utils';

//
// We need to see if there is a config-env.ts to load.  In dev,
// there never will be one.  In prod and stage, one is copied in
// by the docker build (see appropriate dockerfile).  The settings
// will override default configs.  Since the file may not exist,
// we wrap in a try and use require.
//
let configOverload;
try {
    // tslint:disable-next-line
    configOverload = require('./config-env');
} catch (error) {
    // tslint: disable:no-empty
}

//
// Initialize configs, which are different if prod versus stage registry builds.
// We have to make sure the require found the object, and the object contains
// the function.  If it does, call it.
//
if (configOverload && typeof configOverload.initConfigs === 'function') {
    configOverload.initConfigs();
}

window.onerror = (msg, url, line, col, error) => {
    log('Uncaught javascript exception: ', msg, url, line, col, error);
    return false;
};

Vue.config.productionTip = false;
Vue.config.errorHandler = (err, vm, info) => {
    log('Uncaught Vue exception: ', err, vm, info);
};

const ret = Utils.GetTopLevelVueAndRoutes(); // Ignoring ret.routes (see router.ts)

new Vue({
    store,
    router,
    beforeCreate() {
        this.$store.commit('initializeStore');
    },
    render: (h) => h(ret.render),
}).$mount('#app');

Vue.use(ToggleButton);
Vue.use(VueReCaptcha, { siteKey: config.recaptchaSiteKey });
Vue.use(Vue2TouchEvents);
