
    import Vue from 'vue';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default Vue.extend({
        name: 'AuthedUserPageRedirect',

        components: {},

        props: {},

        data() {
            return {};
        },

        //
        // Generic redirect to an authed user's page.  This was most likely
        // called during signin, trying to redirect to a page that requires
        // the userId, or can be used by something like a support page redirect.
        //
        mounted() {
            if (!this.$store.getters.getAuthedUserId || (!this.$router.currentRoute.query.page && !this.$router.currentRoute.query.encodedpath)) {
                logInvalidParams(this.$options.name, 'mounted');
            }

            //
            // If page is part of the query, assume a name/params router push:
            //    aupr?page=admin_support
            //
            // If path is part of the query, assume we have a full path.  By
            // the time we get it, there's no need to decode.  Only the server
            // will encode.
            // cspell:ignore Fticket
            //    aupr?encodedpath=/USER_ID/admin/ticket%3FticketId%3D141
            //

            if (this.$router.currentRoute.query.page) {
                this.$router.replace({
                    name: this.$router.currentRoute.query.page as string,
                    params: { userId: this.$store.getters.getAuthedUserId },
                });
            } else if (this.$router.currentRoute.query.encodedpath) {
                //
                // If USER_ID is part of the query, replace with authed User.  The
                // server doesn't know which user might click on the email button, such
                // as a support ticket link, so we need to replace here.  There are
                // probably better solutions?
                //
                const incoming = this.$router.currentRoute.query.encodedpath as string;
                const newPath = incoming.replace('USER_ID', this.$store.getters.getAuthedUserId);
                this.$router.replace({ path: newPath });
            } else {
                logInvalidParams(this.$options.name, 'mounted');
            }
        },

        computed: {},

        methods: {},
    });
