
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as analytics from '@/tsfiles/analytics';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import BadWordsModal from '@/generic-modals/BadWordsModal.vue';
    import {
        SharedConstants,
        UserService,
        AuthedUser,
        Profile,
        ProfileDefinitions,
        ProfileListDefinition,
        UintVisibility,
        StringVisibility,
        StringArrayVisibility,
        ProfileSocialMediaDefinition,
        SocialMediaItem,
        SocialMediaVisibility,
    } from '@bostonventurestudio/lola-api';
    import { log, logInvalidParams } from '@/tsfiles/errorlog';

    export default mixins(VueConstants).extend({
        name: 'UserProfileSettings',

        components: {
            'bad-words-modal': BadWordsModal,
        },

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<AuthedUser>,
        },

        data() {
            return {
                localProfile: {} as Profile,
                profileDefinitions: {} as ProfileDefinitions,
                optionListProfileDefinitions: [] as ProfileListDefinition[],
                submitting: false,

                // Types that are text inputs that need to be converted to
                // an string visibility array.  We only show what the user types
                // in for now, but it's stored as a json array in the db.
                hometownValue: '',
                originalHometownValue: '',
                workValue: '',
                originalWorkValue: '',
                schoolValue: '',
                originalSchoolValue: '',

                // Social Media v-model storage
                socialMediaData: [] as SocialMediaItem[],
                origSocialMediaData: [] as SocialMediaItem[],
                showAllSocialMedia: false,
                badWordsModal: false,
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: AuthedUser, oldVal: AuthedUser) {
                    if (newVal) {
                        this.localProfile = Utils.deepCopy(newVal.publicUser?.profile);
                        this.fetchProfileDefinitions();

                        //
                        // Some visibility fields cannot be undefined.
                        //
                        if (!this.localProfile.heightInInches) {
                            this.localProfile.heightInInches = {} as UintVisibility;
                        }

                        if (!this.localProfile.aboutMe) {
                            this.localProfile.aboutMe = {} as StringVisibility;
                        }

                        if (!this.localProfile.loveLanguage) {
                            this.localProfile.loveLanguage = {} as StringVisibility;
                        }

                        if (!this.localProfile.socialMedia) {
                            Vue.set(this.localProfile, 'socialMedia', {} as SocialMediaVisibility);
                        }

                        // Some fields are stringArrayVisibility, but we only have single input boxes
                        if (this.localProfile.hometown && this.localProfile.hometown.list?.[0]) {
                            this.hometownValue = this.localProfile.hometown.list?.[0] as string;
                            this.originalHometownValue = this.hometownValue;
                        } else {
                            Vue.set(this.localProfile, 'hometown', {} as StringArrayVisibility);
                        }

                        if (this.localProfile.schools && this.localProfile.schools.list?.[0]) {
                            this.schoolValue = this.localProfile.schools.list?.[0] as string;
                            this.originalSchoolValue = this.schoolValue;
                        } else {
                            Vue.set(this.localProfile, 'schools', {} as StringArrayVisibility);
                        }
                        if (this.localProfile.companies && this.localProfile.companies.list?.[0]) {
                            this.workValue = this.localProfile.companies.list?.[0] as string;
                            this.originalWorkValue = this.workValue;
                        } else {
                            Vue.set(this.localProfile, 'companies', {} as StringArrayVisibility);
                        }
                    }
                },
            },
        },

        computed: {
            //
            // See if any of the profile definitions have changed (localProfile versus Profile)
            // Utils.deepEqual is not the greatest, at the top level profile, for determining empty
            // versus undefined, so we do the checks manually, plus if you don't
            // check individual fields, isDirty won't even get called.
            //
            isDirty(): boolean {
                const hasChanges =
                    this.areProfileDefinitionsDifferent ||
                    this.isSocialMediaDifferent ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.aboutMe?.value, this.localProfile.aboutMe?.value) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.aboutMe?.publiclyVisible, this.localProfile.aboutMe?.publiclyVisible) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.loveLanguage?.value, this.localProfile.loveLanguage?.value) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.loveLanguage?.publiclyVisible, this.localProfile.loveLanguage?.publiclyVisible) ||
                    this.isHeightDifferent ||
                    !Utils.deepEqual(
                        this.user.publicUser?.profile?.heightInInches?.publiclyVisible,
                        this.localProfile.heightInInches?.publiclyVisible,
                    ) ||
                    !Utils.deepEqual(this.originalHometownValue, this.hometownValue) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.hometown?.publiclyVisible, this.localProfile.hometown?.publiclyVisible) ||
                    !Utils.deepEqual(this.originalSchoolValue, this.schoolValue) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.schools?.publiclyVisible, this.localProfile.schools?.publiclyVisible) ||
                    !Utils.deepEqual(this.originalWorkValue, this.workValue) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.companies?.publiclyVisible, this.localProfile.companies?.publiclyVisible) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.zodiacSign?.publiclyVisible, this.localProfile.zodiacSign?.publiclyVisible);
                this.$emit('set-settings-dirty', hasChanges);
                return hasChanges;
            },

            //
            // Return true if any of the profileDefinition values are difference.  We
            // cannot use Utils.deepEqual on the top level profile, since some
            // properties (fields) of the objects are undefined if they are not on the server
            // yet (NULL).  The obj lengths would be different.  We can use deepEqual on the
            // simple arrays though.
            //
            areProfileDefinitionsDifferent(): boolean {
                if (!this.profileDefinitions || !this.localProfile || !this.user.publicUser?.profile) {
                    logInvalidParams(this.$options.name, 'areProfileDefinitionsDifferent');
                    return false;
                }

                for (const prop in this.profileDefinitions) {
                    if (prop) {
                        if (Object.prototype.hasOwnProperty.call(this.profileDefinitions, prop)) {
                            const obj = this.profileDefinitions[prop as keyof ProfileDefinitions] as any;
                            if (obj.hasOwnProperty('optionList')) {
                                const profileVal = this.user.publicUser?.profile[prop as keyof Profile] as StringArrayVisibility;
                                const localVal = this.localProfile[prop as keyof Profile] as StringArrayVisibility;

                                // If there is no profileVal or localVal, there is a discrepency between
                                // profileDefinitions and the returned userservice.Profile
                                if (!profileVal || !localVal) {
                                    log('areProfileDefinitionsDifferent: missing data for property - ', prop);
                                    return false;
                                } else if (
                                    !Utils.deepEqual(profileVal.list, localVal.list) ||
                                    !Utils.deepEqual(profileVal.publiclyVisible, localVal.publiclyVisible)
                                ) {
                                    return true;
                                }
                            }
                        }
                    }
                }

                return false;
            },

            heightValid(): boolean {
                // If there never was a height, we can leave as empty
                if (!this.user.publicUser?.profile?.heightInInches?.value && !this.localProfile.heightInInches?.value) {
                    return true;
                } else if (!this.localProfile.heightInInches?.value) {
                    return false;
                }

                return (
                    this.localProfile.heightInInches?.value >= SharedConstants.PROFILE_MIN_HEIGHT_IN_INCHES &&
                    this.localProfile.heightInInches?.value <= SharedConstants.PROFILE_MAX_HEIGHT_IN_INCHES
                );
            },

            isHeightDifferent(): boolean {
                const fromProfile = this.user.publicUser?.profile?.heightInInches?.value;
                const fromLocal = this.localProfile.heightInInches?.value;

                if ((!fromProfile && !fromLocal) || (!fromProfile && Number(fromLocal) === 0)) {
                    return false;
                }

                return fromProfile !== fromLocal;
            },

            moreSocialMediaOptions(): boolean {
                if (
                    !this.showAllSocialMedia &&
                    (!this.localProfile ||
                        !this.localProfile.socialMedia ||
                        !this.localProfile.socialMedia.list ||
                        this.localProfile.socialMedia.list.length !== this.socialMediaData.length)
                ) {
                    return true;
                }

                return false;
            },

            isSocialMediaDifferent(): boolean {
                // Check links first
                for (let i = 0; i < this.socialMediaData.length; i++) {
                    if (!this.mediaLinkValid(i)) {
                        return false;
                    }
                }

                return (
                    !Utils.deepEqual(this.origSocialMediaData, this.socialMediaData) ||
                    !Utils.deepEqual(this.user.publicUser?.profile?.socialMedia?.publiclyVisible, this.localProfile.socialMedia?.publiclyVisible)
                );
            },
        },

        methods: {
            // Return true if all the inputs are valid.  This will help determine whether
            // the Save button should be disabled.
            inputsValid(): boolean {
                return this.isDirty && this.heightValid;
            },

            //
            // Retrieve the profile definitions from the server.  These are mainly the
            // common single and multiselect properties within the profile (e.g., gender, languages, etc.).
            // Create 'optionListProfileDefinitions' array, which contains ONLY the single and multi-select
            // profile data.  This makes v-for easier in the template.
            //
            async fetchProfileDefinitions() {
                try {
                    this.profileDefinitions = await ApiUtils.apiWrapper(UserService.getProfileDefinitions);

                    if (this.profileDefinitions) {
                        this.optionListProfileDefinitions = [] as ProfileListDefinition[];
                        for (const prop in this.profileDefinitions) {
                            if (prop) {
                                if (Object.prototype.hasOwnProperty.call(this.profileDefinitions, prop)) {
                                    const obj = this.profileDefinitions[prop as keyof ProfileDefinitions] as any;
                                    if (obj.hasOwnProperty('optionList') && obj.hasOwnProperty('active')) {
                                        this.optionListProfileDefinitions.push(obj);
                                    }
                                }
                            }
                        }

                        // Reset local social media data storage
                        if (this.profileDefinitions.socialMedia) {
                            this.socialMediaData = [] as SocialMediaItem[];
                            this.origSocialMediaData = [] as SocialMediaItem[];

                            // Init and fill
                            for (const item of this.profileDefinitions.socialMedia) {
                                let curVal = '';
                                if (this.localProfile.socialMedia?.list) {
                                    for (let existingItem of this.localProfile.socialMedia.list) {
                                        if (existingItem.provider === item.provider) {
                                            curVal = existingItem.value as string;
                                            break;
                                        }
                                    }
                                }

                                this.socialMediaData.push({ provider: item.provider, value: curVal });
                                this.origSocialMediaData.push({ provider: item.provider, value: curVal });
                            }
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async handleSubmit(event: Event) {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    // Dynamically set mask based on what's different
                    this.localProfile.updateMask = {
                        paths: this.createUpdateMask(),
                    };

                    // If zodiac publicyVisible changed, add to updateMask
                    if (
                        !Utils.deepEqual(this.localProfile.zodiacSign?.publiclyVisible, this.user.publicUser?.profile?.zodiacSign?.publiclyVisible) &&
                        this.localProfile.updateMask.paths
                    ) {
                        this.localProfile.updateMask.paths.push('zodiacSign');
                    }

                    // Copy in social media content.  Only add if something set.
                    const social = [] as SocialMediaItem[];
                    for (const item of this.socialMediaData) {
                        if (item.value && item.value.trim() !== '') {
                            item.value = Utils.cleanSocialMediaLink(item.provider as string, item.value as string);
                            social.push(item);
                        }
                    }

                    if (this.localProfile.socialMedia) {
                        this.localProfile.socialMedia.list = social;
                    }

                    // Fill in single entry fields that need to be a stringArrayVisibility value
                    // Messy...
                    const addedMask = [] as string[];
                    if (this.localProfile.hometown && this.hometownValue != this.originalHometownValue) {
                        this.localProfile.hometown.list = [this.hometownValue.trim()];
                    }
                    if (!Utils.deepEqual(this.localProfile.hometown, this.user.publicUser?.profile?.hometown)) {
                        addedMask.push('hometown');
                    }

                    if (this.localProfile.schools && this.schoolValue != this.originalSchoolValue) {
                        this.localProfile.schools.list = [this.schoolValue.trim()];
                    }
                    if (!Utils.deepEqual(this.localProfile.schools, this.user.publicUser?.profile?.schools)) {
                        addedMask.push('schools');
                    }
                    if (this.localProfile.companies && this.workValue != this.originalWorkValue) {
                        this.localProfile.companies.list = [this.workValue.trim()];
                    }
                    if (!Utils.deepEqual(this.localProfile.companies, this.user.publicUser?.profile?.companies)) {
                        addedMask.push('companies');
                    }

                    if (this.localProfile.updateMask && this.localProfile.updateMask.paths) {
                        this.localProfile.updateMask.paths.push(...addedMask);
                    }

                    const updatedProfile = await ApiUtils.apiWrapper(UserService.saveProfile, this.localProfile);

                    if (this.user.userId) {
                        analytics.logAppInteraction(analytics.ANALYTICS_ACTION_UPDATE_PROFILE, this.user.userId.toString());
                    }

                    // Tell the parent about the updates, since we need the new values to
                    // compare against, store updates, etc.
                    this.$emit('profile-updated', updatedProfile);

                    this.submitting = false;
                } catch (error) {
                    if ((error as any).response.data.message === SharedConstants.WARNING_INVALID_PROFILE_BAD_WORDS) {
                        this.badWordsModal = true;
                    } else {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            //
            // Create the updateMask for the user object that will be sent to the server.  These
            // are the fields that will get updated in the database.
            //
            createUpdateMask(): string[] {
                const mask = [] as string[];
                if (!this.user.publicUser?.profile || !this.localProfile) {
                    logInvalidParams(this.$options.name, 'createUpdateMask');
                    return mask;
                }

                [
                    'aboutMe',
                    'heightInInches',
                    'gender',
                    'sexualOrientation',
                    'datingGoals',
                    'familyGoals',
                    'pronouns',
                    'politics',
                    'languages',
                    'religions',
                    'children',
                    'drinking',
                    'tobacco',
                    'weed',
                    'drugs',
                    'dateActivities',
                    'vaccines',
                    'pets',
                    'loveLanguage',
                ].map((item) => {
                    if (
                        this.user.publicUser?.profile &&
                        !Utils.deepEqual(this.user.publicUser?.profile[item as keyof Profile], this.localProfile[item as keyof Profile])
                    ) {
                        mask.push(item);
                    }
                });

                //
                // Add in any non-profileDefinitions, or those that are not compared using deepEqual with
                // the map above.
                //
                if (this.isSocialMediaDifferent) {
                    mask.push('socialMedia');
                }

                return mask;
            },

            //
            // For a given propertyName, return the string array.
            //
            getCurrentProfileValues(propertyName: string): string[] {
                if (!this.localProfile) {
                    logInvalidParams(this.$options.name, 'getCurrentProfileValues');
                    return [] as string[];
                }

                const stringArrayVisibility = this.localProfile[propertyName as keyof Profile] as StringArrayVisibility;
                if (stringArrayVisibility) {
                    return stringArrayVisibility.list as string[];
                }

                return [] as string[];
            },

            //
            // Called when a single-selection dropdown item is selected.  The key value
            // is saved directly into this.localProfile[propertyName].
            //
            singleSelection(propertyName: string | undefined, keyToSave: string | undefined) {
                if (!this.localProfile || !keyToSave) {
                    logInvalidParams(this.$options.name, 'singleSelection');
                    return '';
                }

                // Even single selection values are arrays, in case the design
                // changes to become multi-select.
                const newVal = [] as string[];
                newVal.push(keyToSave);

                const stringArrayVisibility = this.localProfile[propertyName as keyof Profile] as StringArrayVisibility;
                Vue.set(stringArrayVisibility, 'list', newVal);
            },

            singleSelectVisibilityChanged(propertyName: string | undefined) {
                if (!this.localProfile) {
                    logInvalidParams(this.$options.name, 'visibilityChanged');
                    return '';
                }

                const prop = this.localProfile[propertyName as keyof ProfileDefinitions] as StringVisibility;
                if (prop) {
                    Vue.set(prop, 'publiclyVisible', !prop.publiclyVisible);
                }
            },

            multiSelectVisibilityChanged(propertyName: string | undefined) {
                if (!this.localProfile) {
                    logInvalidParams(this.$options.name, 'visibilityChanged');
                    return '';
                }

                const prop = this.localProfile[propertyName as keyof ProfileDefinitions] as StringArrayVisibility;
                if (prop) {
                    Vue.set(prop, 'publiclyVisible', !prop.publiclyVisible);
                }
            },

            //
            // This is called to set the default state of a multi-select checkbox.
            // The initial state depends on what the server gives us.
            //
            isCheckboxChecked(propertyName: string | undefined, keyToSave: string | undefined): boolean {
                if (!this.localProfile || !keyToSave || !this.profileDefinitions) {
                    logInvalidParams(this.$options.name, 'isCheckboxChecked');
                    return false;
                }

                const defs = this.profileDefinitions[propertyName as keyof ProfileDefinitions] as ProfileListDefinition;
                if (!defs || !defs.optionList) {
                    logInvalidParams(this.$options.name, 'isCheckboxChecked');
                    return false;
                }

                const prop = this.localProfile[propertyName as keyof ProfileDefinitions] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    if (!currentList) {
                        return false;
                    }
                    return currentList.includes(keyToSave as string);
                }

                return false;
            },

            maxSelected(propertyName: string | undefined, keyToSave: string | undefined): boolean {
                if (!this.localProfile || !keyToSave || !this.profileDefinitions) {
                    logInvalidParams(this.$options.name, 'maxSelected');
                    return false;
                }

                const defs = this.profileDefinitions[propertyName as keyof ProfileDefinitions] as ProfileListDefinition;
                if (!defs || !defs.optionList) {
                    logInvalidParams(this.$options.name, 'maxSelected');
                    return false;
                }

                // If no max, return false
                if (!defs.maxLength) {
                    return false;
                }

                const prop = this.localProfile[propertyName as keyof ProfileDefinitions] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    if (currentList && currentList.length >= defs.maxLength) {
                        return true;
                    }
                }

                return false;
            },

            isSingleSelectVisibilityCheckboxChecked(propertyName: string): boolean {
                if (!this.localProfile) {
                    logInvalidParams(this.$options.name, 'isSingleSelectVisibilityCheckboxChecked');
                    return false;
                }
                const prop = this.localProfile[propertyName as keyof ProfileDefinitions] as StringVisibility;
                if (prop) {
                    return prop.publiclyVisible as boolean;
                }

                return false;
            },

            isMultiSelectVisibilityCheckboxChecked(propertyName: string): boolean {
                if (!this.localProfile) {
                    logInvalidParams(this.$options.name, 'isMultiSelectVisibilityCheckboxChecked');
                    return false;
                }
                const prop = this.localProfile[propertyName as keyof ProfileDefinitions] as StringArrayVisibility;
                if (prop) {
                    return prop.publiclyVisible as boolean;
                }

                return false;
            },

            //
            // Rebuild the associated array anytime something is selected.
            // Not efficient, but just as messy as creating an array of checkbox values
            // and trying to map back to localUser values.
            //
            multiSelection(propertyName: string | undefined, keySetOrUnset: string | undefined) {
                if (!this.localProfile || !keySetOrUnset || !this.profileDefinitions) {
                    logInvalidParams(this.$options.name, 'multiSelection');
                    return;
                }

                const defs = this.profileDefinitions[propertyName as keyof ProfileDefinitions] as ProfileListDefinition;
                if (!defs || !defs.optionList) {
                    logInvalidParams(this.$options.name, 'multiSelection');
                    return;
                }

                const checkedList = [] as string[];
                let currentList = [] as string[];
                const prop = this.localProfile[propertyName as keyof ProfileDefinitions] as StringArrayVisibility;
                if (prop && prop.list) {
                    currentList = prop.list;
                }

                for (const field of defs.optionList) {
                    if (field.key === keySetOrUnset) {
                        // If already selected ignore (not added to new list).
                        if (currentList && currentList.includes(field.key as string)) {
                            continue; // unchecked
                        } else if (field.key === keySetOrUnset) {
                            checkedList.push(keySetOrUnset);
                        }
                    } else if (currentList && currentList.includes(field.key as string)) {
                        checkedList.push(field.key as string);
                    }
                }

                const stringArrayVisibility = this.localProfile[propertyName as keyof Profile] as StringArrayVisibility;
                Vue.set(stringArrayVisibility, 'list', checkedList);
            },

            //
            // Get the title of the dropdown.  If there are no values, just use the definition
            // Title.  If there is just one value, show that next to the title.  If there are
            // multiple values, put the count next to the title.
            //
            getDropdownTitle(item: ProfileListDefinition): string {
                if (!this.profileDefinitions) {
                    logInvalidParams(this.$options.name, 'getDropdownTitle');
                    return '';
                }

                const prop = this.localProfile[item.propertyName as keyof ProfileDefinitions] as StringArrayVisibility;
                const publiclyVisible = prop && prop.publiclyVisible;

                const currentValue = this.getCurrentProfileValues(item.propertyName as string);

                let ret = '';
                if (!currentValue || currentValue.length === 0) {
                    ret = item.title + ' (0)';
                } else if (item.multiSelect) {
                    ret = item.title + ' (' + currentValue.length + ')';
                } else {
                    ret = item.title + ' (' + currentValue + ')';
                }

                // Add star if publiclyVisible
                if (publiclyVisible) {
                    ret += '*';
                }

                return ret;
            },

            getSocialMediaTitle(item: ProfileSocialMediaDefinition): string {
                if (item.urlRequired) {
                    return 'Your ' + item.title + ' URL';
                }

                return 'Your ' + item.title + ' Username';
            },

            mediaLinkValid(idx: number): boolean {
                if (idx < 0 || idx >= this.socialMediaData.length) {
                    logInvalidParams(this.$options.name, 'mediaLinkValid');
                    return false;
                }

                const userValue = this.socialMediaData[idx].value;
                if (userValue === undefined || userValue === '') {
                    return true; // undefined or blank is fine.
                }

                const platform = this.socialMediaData[idx].provider;
                return Utils.socialMediaInputValid(platform as string, userValue);
            },

            socialMediaInvalidText(item: ProfileSocialMediaDefinition): string {
                if (!this.profileDefinitions.socialMedia) {
                    logInvalidParams(this.$options.name, 'socialMediaInvalidText');
                    return '';
                }

                if (item.urlRequired) {
                    return 'Please enter a valid URL';
                }

                return 'Please enter your valid username';
            },
        },
    });
