
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { DateTime as LuxonDateTime } from 'luxon';
    import { PublicUser, Notification, NotificationService, GenericPageRetrieval, AdminService } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserNotifications',

        components: {},

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,
        },

        data() {
            return {
                notifications: {} as Notification[],
                totalItemsNoPaging: 0,
                totalUnread: 0,

                notificationFields: [
                    { key: 'ID', tdClass: 'align-middle' },
                    { key: 'event', tdClass: 'align-middle' },
                    { key: 'displayText', tdClass: 'align-middle' },
                    { key: 'created', tdClass: 'align-middle' },
                    { key: 'updated', tdClass: 'align-middle' },
                ],
                allItems: [] as any[],
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: PublicUser, oldVal: PublicUser) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchNotifications();
                    }
                },
            },
        },

        computed: {},

        methods: {
            async fetchNotifications() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'fetchNotifications');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.getUserNotificationsById, {
                        userId: this.user.userId,
                        page: 1,
                        pageSize: 20,
                    });

                    if (ret?.list) {
                        this.notifications = ret.list;
                        this.totalItemsNoPaging = ret.totalItemsIrregardlessOfPaging as number;
                        this.totalUnread = ret.totalUnreadItems as number;
                    } else {
                        this.notifications = [] as Notification[];
                        this.totalItemsNoPaging = 0;
                        this.totalUnread = 0;
                    }

                    this.$emit('notificationCount', this.notifications.length);

                    this.buildItemList();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            buildItemList() {
                if (this.notifications.length === 0) {
                    this.allItems = [] as any[];
                    return;
                }

                const newItems = [] as any[];
                for (const item of this.notifications) {
                    const created = item.created ? LuxonDateTime.fromISO(item.created).toLocaleString(LuxonDateTime.DATETIME_SHORT) : '';
                    const updated = item.updated ? LuxonDateTime.fromISO(item.updated).toLocaleString(LuxonDateTime.DATETIME_SHORT) : '';

                    //
                    // Push the items onto openItems, which is what will show up in the table (if
                    // there is a field for that item).
                    //
                    newItems.push({
                        ID: item.notificationId,
                        event: item.event,
                        displayText: item.displayText,
                        markedAsRead: item.markedAsRead,
                        created: created,
                        updated: updated,
                    });
                }

                this.allItems = newItems;
            },
        },
    });
