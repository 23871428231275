
    import Vue, { PropOptions } from 'vue';

    export default Vue.extend({
        name: 'FollowButton',

        components: {},

        props: {
            // Should the button be grayed out, but still clickable?
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Are we current following this entity?
            alreadyFollowing: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {};
        },

        computed: {},

        methods: {},
    });
