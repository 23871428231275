//
// Copyright 2022-2023 Lola - All rights reserved.
// File: roleutils.ts
// Project: lola
//

//
// NOTE: Role determination needs to be kept in sync with the server.  If you
// make changes here, they should be made in roleutils.go as well.  If roles
// start getting too complex, it might be hard to keep in sync.  Maybe the server
// could be the primary determination of role usage?
// Also, do not use functions in store.ts for determining if the user
// has access to the features.  Keep all that logic here.
//
// Keep api/doc/roles.adoc up to date with any changes
//

import store from '@/tsfiles/store';
import * as constants from '@/tsfiles/constants';
import { AdminRole } from '@bostonventurestudio/lola-api';

export class RoleUtils {
    /////////////////////////////////////////////////////////////////////////
    // Super admin functions, which hit the store.
    /////////////////////////////////////////////////////////////////////////

    //
    // CanSupportUsers returns true if the user is allowed to support other users
    //
    public static CanSupportUsers(): boolean {
        return store.getters.isSupportSuperAdmin;
    }

    //
    // IsServerAdmin returns true if the user is allowed to mess with the server settings
    //
    public static IsServerSuperAdmin(): boolean {
        return store.getters.isServerSuperAdmin;
    }

    //
    // IsTestAdmin returns true if the user is an internal tester
    //
    public static IsTestAdmin(): boolean {
        return store.getters.isTestAdmin;
    }

    //
    // IsAdmin returns true if the user has any of the admin roles.
    //
    public static IsAdmin(): boolean {
        return this.CanSupportUsers() || this.IsServerSuperAdmin() || this.IsTestAdmin();
    }
}

const roleutils = new RoleUtils();
export default roleutils;
