
    import mixins from 'vue-typed-mixins';
    import store from '@/tsfiles/store';
    import { mapGetters } from 'vuex';
    import { log, logInvalidResponse } from '@/tsfiles/errorlog';
    import * as analytics from '@/tsfiles/analytics';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import config from '@/config';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import EventBus from '@/eventbus';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { PageMessage } from '@/tsfiles/interfaces';
    import { profileRefresh } from '@/tsfiles/profilerefresh';
    import { PubSubClient, PubSubEvent } from '@/tsfiles/pubsub';
    import { Utils } from '@/tsfiles/utils';
    import { signout } from '@/tsfiles/firebase';
    import { SigninStatus } from '@/tsfiles/enums';
    import StickyHeader from '@/components/StickyHeader.vue';
    import PageMessageList from '@/components/page-messages/PageMessageList.vue';
    import MainMenu from '@/components/MainMenu.vue';
    import StickyFooter from '@/components/StickyFooter.vue';
    import {
        SharedConstants,
        AuthService,
        UserService,
        UserIdentity,
        AuthedUser,
        NotificationList,
        Notification,
        NotificationService,
        GenericPageRetrieval,
        ChatService,
        Post,
    } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'MainApp',

        components: {
            'sticky-header': StickyHeader,
            'page-message-list': PageMessageList,
            'main-menu': MainMenu,
            'sticky-footer': StickyFooter,
        },

        data() {
            return {
                pubSubClient: undefined as PubSubClient | undefined,
                notificationList: undefined as NotificationList | undefined,
                requireName: false,
                usersName: '',
                pubSubClientTimer: undefined as any | undefined,

                // TEST: If testing signin out of sync, set to true
                testSignin: false && config.runEnvironment === 'dev',
            };
        },

        watch: {
            //
            // We only want to fetch the data, when signed-in goes from false to true.
            //
            // Whenever we refresh (reload MainApp), we will start out as not signed in,
            // and then flip to signed in if identity is retrieved.
            //
            signinState: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal && !oldVal) {
                        // fresh signin, or identity retrieval complete.
                        this.retrieveAndProcessNotifications(true);
                        this.setupSubscriber();

                        this.requireName = this.$store.getters.isSignedIn && this.$store.getters.getPublicUserValue('firstName') === '';

                        //
                        // Reset some store values that may have been set previously, such
                        // as from a header search result.
                        //
                        this.$store.commit('setCurrentMenu', constants.ROUTE_USER_CALENDAR);
                    }
                },
            },

            //
            // Do any cleanup needed when signed out.  Don't forget to test going from
            // signed in to out.
            //
            signoutState: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.notificationList = undefined as NotificationList | undefined;
                        this.stopSubscriber();
                        this.requireName = false;

                        this.$store.commit('clearAllPageMessages');
                    }
                },
            },

            isPhoneVerified: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.setFakeNotifications();
                    }
                },
            },

            isEmailVerified: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal !== oldVal) {
                        this.setFakeNotifications();
                    }
                },
            },
        },

        mounted() {
            // Store beep used for post submission
            this.$store.commit('setPostBeep', new Audio('beep.mp3'));

            //
            // Set the AppType cookie to 'web', which will included in error output.  This
            // helps determine if the error is from the web, ios, or android.
            // NOTE: if the UI is not refreshed within 7 days, the cookie will expire.
            // In Safari and Brave, client side cookies cannot have an expiration
            // longer than 7 days.  It's possible the user leaves the page open and never
            // does anything that will cause a refresh, but the apps won't have this
            // problem so we can still distinguish between apps and webUI.
            //
            Utils.setCookie('AppType', 'web', 365);
        },

        beforeDestroy() {
            this.stopSubscriber();
        },

        computed: {
            // Map store isSignedIn so we can watch for completion of signin.
            ...mapGetters({
                signinState: 'isSignedIn',
                signoutState: 'isSignedOut',
            }),

            isPhoneVerified(): boolean {
                return this.$store.getters.getAuthedUserValue('phoneVerified');
            },

            isEmailVerified(): boolean {
                return this.$store.getters.getAuthedUserValue('emailVerified');
            },

            nameValid(): boolean {
                return this.usersName !== '';
            },
        },

        methods: {
            // Return true if we want the user to have the views take up the entire screen;
            // otherwise it's most of the screen, but centered, and doesn't get huge
            // when they resize the browser to be super wide.
            showFullScreen(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    RoleUtils.CanSupportUsers() &&
                    (this.$router.currentRoute.name === constants.ROUTE_ADMIN_SUPPORT ||
                        this.$router.currentRoute.name === constants.ROUTE_DATING_POOL ||
                        this.$router.currentRoute.name === constants.ROUTE_USER_DATING_SCORECARDS)
                );
            },

            //
            // If the nginx oos.html page is up (site down or in maintenance), no one can
            // get into the site. It might be running, but having trouble when we put
            // up the OOS page.  Devs should be able to get in to help diagnose.  The
            // cookie can be set, which will let the dev in, and make the UI background
            // a specific, nasty looking color.  This way they know they have the cookie
            // set, and the site might actually be down.  If we didn't do this the dev
            // could think the site is fine, when it's actually down for real users.
            // It should be ok to list the cookie name here, since a hacker would not
            // bet able to set the cookie to the needed value.
            //
            hasDevSiteDownAccess(): boolean {
                return document.cookie.match(new RegExp('LetMeIn' + '=([^;]+)')) !== null;
            },

            //
            // Set up pubsub (nginx nchan), so we can get async messages from server.  This is
            // only done once we know there's a valid signin.
            // To test, which must be done inside a container (use api):
            // curl --request POST --data '{"message": "Hello"}' http://nginx:10081/pub/<userId>
            //
            setupSubscriber() {
                this.pubSubClient = new PubSubClient(this.messageNotification, this.subscriberError);
                if (!this.pubSubClient) {
                    log('Error creating pubSubClient');
                }
            },

            //
            // If there's an error with the pubsub connection, try every once in a while
            // to reconnection.  The easiest way to test is to bring down nginx in dev.
            //
            subscriberError() {
                // If there's already a timer running, ignore
                if (this.pubSubClientTimer) {
                    return;
                }

                this.stopSubscriber();

                this.pubSubClientTimer = setTimeout(async () => {
                    this.pubSubClientTimer = undefined as any | undefined;
                    this.setupSubscriber();
                }, 10000);
            },

            //
            // If the user just signed out, or when destroying the vue, stop listening
            // for subscriber messages.
            //
            stopSubscriber() {
                if (this.pubSubClient) {
                    this.pubSubClient.stop();
                    this.pubSubClient = undefined;
                }
            },

            //
            // Handle published messages from the server (via nginx nchan).  Use the EventBus to
            // publish to components that need to handle in a special way.  Only some are
            // handled here.
            //
            messageNotification(message: PubSubEvent) {
                console.log('PubSub Message: ', message.event);
                if (!message || message.event === '') {
                    log('Empty PubSub Message');
                    return;
                }

                // retrieveAndProcessNotifications will push the notification.  Don't
                // send another one.  That function is an async, so we cannot return a value.
                let publishMessage = true;

                // The UI will figure out what to do based on the message
                switch (message.event) {
                    case SharedConstants.PUBSUB_REFRESH_NOTIFICATIONS:
                    case SharedConstants.PUBSUB_REFRESH_FRIENDS:
                        publishMessage = false;
                        this.retrieveAndProcessNotifications(true);
                        break;
                    case SharedConstants.NOTIFICATION_LIKE_RECEIVED:
                    case SharedConstants.NOTIFICATION_NEW_DATE_MATCH:
                    case SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH:
                    case SharedConstants.NOTIFICATION_AVAILABILITY_RECEIVED:
                    case SharedConstants.NOTIFICATION_DATE_UNMATCHED:
                    case SharedConstants.NOTIFICATION_DATE_REMINDER:
                    case SharedConstants.NOTIFICATION_DATE_RESCHEDULED:
                    case SharedConstants.NOTIFICATION_DATE_DELETED_ACCOUNT:
                    case SharedConstants.NOTIFICATION_POST_DATE_SURVEY:
                    case SharedConstants.NOTIFICATION_NEW_SCORECARD:
                        publishMessage = true;
                        this.retrieveAndProcessNotifications(true);
                        break;
                    case SharedConstants.NOTIFICATION_NEW_CHAT_POST:
                    case SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED:
                        publishMessage = true;
                        this.retrieveAndProcessNotifications(false);

                        // If not on the chat page, mark as delivered
                        this.AckChatPostReceiptIfNotOnChat(message);
                        break;
                    case SharedConstants.PUBSUB_REFRESH_USER_PROFILE:
                        profileRefresh();

                        // Reprocess notifications as part of the refresh.
                        publishMessage = false;
                        this.retrieveAndProcessNotifications(true);
                        break;
                    case SharedConstants.PUBSUB_TURN_ON_USER_ANALYTICS:
                        analytics.turnOnUserAnalytics();
                        break;
                    case SharedConstants.PUBSUB_TURN_OFF_USER_ANALYTICS:
                        analytics.turnOffUserAnalytics();
                        break;
                    case SharedConstants.NOTIFICATION_USER_SUSPENDED:
                    case SharedConstants.NOTIFICATION_USER_BANNED:
                    case SharedConstants.NOTIFICATION_USER_REACTIVATED:
                        this.activeStateChanged(message);
                        break;
                    default:
                        // Event might be handled by a component accessing via EventBus.
                        break;
                }

                if (publishMessage) {
                    EventBus.$emit(message.event, message);
                }
            },

            async retrieveAndProcessNotifications(publishEvent: boolean) {
                try {
                    //
                    // For the header notification list, just retrieve the most
                    // recent 10 notifications.
                    //
                    const ret = await ApiUtils.apiWrapper(NotificationService.getUserNotifications, {
                        pageNumber: 1,
                        numberOfItems: constants.HEADER_NUMBER_OF_NOTIFICATIONS,
                        filterBy: [{ type: SharedConstants.FILTER_NOTIFICATIONS_UNREAD_ONLY }],
                    } as GenericPageRetrieval);

                    if (ret && ret.list) {
                        //
                        // Find out which ones are new, so we can retrieve any info that's needed, or
                        // let other components know about the new notification.
                        //
                        if (this.notificationList && this.notificationList.list) {
                            const idHash: { [key: string]: number } = {};
                            for (const n of this.notificationList.list) {
                                const id = n.notificationId as number;
                                idHash[id] = !n.count ? 0 : n.count;
                            }

                            for (const n of ret.list) {
                                if (!n || n.event === undefined || n.event === '') {
                                    continue;
                                }

                                const id = n.notificationId as number;
                                // Is this a new notification we don't already have?
                                if (publishEvent && (!idHash[id] || idHash[id] !== n.count)) {
                                    // Let components decide if they need to do anything specific.
                                    const newEvent = { event: n.event, jsonData: n.jsonData } as PubSubEvent;
                                    EventBus.$emit(n.event, newEvent);
                                }
                            }
                        } else if (publishEvent) {
                            // A new notification came in, and there are no current notifications
                            // in our list (this.notificationList).  We still need to emit the
                            // event.

                            const n = ret.list[0];
                            const newEvent = { event: n.event, jsonData: n.jsonData } as PubSubEvent;
                            EventBus.$emit(n.event as string, newEvent);
                        }

                        this.notificationList = ret; // Contains total unread, which Notifications.vue needs.
                    } else {
                        this.notificationList = undefined as NotificationList | undefined;
                    }

                    this.checkPageMessages();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // PageMessages are tied to notifications coming from the server.  This
            // function will see if any existing page messages need to be deleted.
            //
            // At the end, see if we need to add the fake message about phone/email verification.
            //
            checkPageMessages() {
                for (const msg of this.$store.getters.getPageMessages) {
                    let foundMatch = false;
                    if (this.notificationList && this.notificationList.list) {
                        for (const notification of this.notificationList.list) {
                            if (notification.notificationId === msg.notification.notificationId) {
                                foundMatch = true;
                                break;
                            }
                        }
                    }

                    if (!foundMatch) {
                        this.$store.commit('clearPageMessage', msg.notification.event);
                    }
                }

                this.setFakeNotifications();
            },

            //
            // The user must have their email or phone verified in order to add content.
            // Put in a PageMessage if needed.  Add other fake notifications here if needed.
            //
            setFakeNotifications() {
                if (!this.$store.getters.isSignedIn || this.$store.getters.isAuthedUserId(0)) {
                    return;
                }

                if (this.$store.getters.isSignedIn && !this.isPhoneVerified && !this.isEmailVerified) {
                    this.commitFakeNotification(constants.FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED);
                } else {
                    this.$store.commit('clearPageMessage', constants.FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED);
                }

                if (!this.$store.getters.getAuthedUserValue('onboardingComplete')) {
                    this.commitFakeNotification(constants.FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE);
                } else {
                    this.$store.commit('clearPageMessage', constants.FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE);
                }

                if (this.$store.getters.getAuthedUserValue('datingPaused')) {
                    this.commitFakeNotification(constants.FAKE_NOTIFICATION_DATING_PAUSED);
                } else {
                    this.$store.commit('clearPageMessage', constants.FAKE_NOTIFICATION_DATING_PAUSED);
                }
            },

            commitFakeNotification(fakeEvent: string) {
                if (fakeEvent !== '') {
                    this.$store.commit('setPageMessage', {
                        notification: {
                            notificationId: 0,
                            event: fakeEvent,
                        } as Notification,
                        permanent: true,
                    } as PageMessage);
                } else {
                    // Make sure the fake notification is cleared
                    this.$store.commit('clearPageMessage', fakeEvent);
                }
            },

            //
            // Mark as read any notifications that match the given data.  This comes from a child,
            // most likely the header notification component.
            //
            async clearSingleNotification(data: Notification) {
                if (!data || !data.notificationId) {
                    return; // ERROR
                }

                // Find the notification in our list, remove it and tell the server.
                try {
                    let foundIt = false;
                    if (this.notificationList && this.notificationList.list) {
                        for (let i = 0; i < this.notificationList.list.length; i++) {
                            if (this.notificationList.list[i].notificationId === data.notificationId) {
                                await ApiUtils.apiWrapper(
                                    NotificationService.markAsReadNotification,
                                    this.notificationList.list[i].notificationId as number,
                                );

                                // Must come after server notification, since we are indexing array directly
                                this.notificationList.list.splice(i, 1);
                                foundIt = true;
                                break;
                            }
                        }
                    }

                    if (foundIt) {
                        this.retrieveAndProcessNotifications(false);
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Clear out all notifications.  This comes from a child, most likely the header
            // notification component.
            //
            async clearNotifications(data: any) {
                this.notificationList = undefined as NotificationList | undefined;

                try {
                    await ApiUtils.apiWrapper(NotificationService.markAsReadAllNotifications);
                    this.retrieveAndProcessNotifications(false);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async impersonateUser(userId: number) {
                this.stopSubscriber();

                try {
                    let identity = null as UserIdentity | null;
                    let revertedToAdmin = false;

                    // Revert to admin if userId is the same as adminUserId
                    if (userId === this.$store.getters.getAdminUserId) {
                        identity = await ApiUtils.apiWrapper(AuthService.revertToAdmin, userId);
                        revertedToAdmin = true;
                    } else {
                        identity = await ApiUtils.apiWrapper(AuthService.adminSigninAs, userId);
                    }

                    if (identity) {
                        this.$store.commit('setAuthedUser', identity.user);
                        this.$store.commit('setUserRoles', identity.roles);
                        this.$store.commit('setAdminUserId', identity.adminUserId);
                        this.setupSubscriber();
                        this.retrieveAndProcessNotifications(true);

                        //
                        // If reverting to admin, make sure they are on their home page.  We
                        // don't want to be sitting on the previously impersonated user's pages.
                        //
                        if (revertedToAdmin && identity.user && identity.user.publicUser && identity.user.publicUser.userId) {
                            const adminUserIdAsString = identity.user.publicUser.userId.toString();
                            if (this.$router.currentRoute.params.userId !== adminUserIdAsString) {
                                this.$router.replace({
                                    name: constants.ROUTE_USER_HOME,
                                    params: { userId: adminUserIdAsString },
                                });
                            }
                        }
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            incrementSigninState(whichOne: string) {
                let curState = this.$store.getters.getFirebaseSigninState;
                if (whichOne !== 'firebase') {
                    curState = this.$store.getters.getServerSigninState;
                }

                let newState = SigninStatus.UNKNOWN;
                switch (curState) {
                    case SigninStatus.UNKNOWN:
                        newState = SigninStatus.SIGNEDIN;
                        break;
                    case SigninStatus.SIGNEDIN:
                        newState = SigninStatus.SIGNEDOUT;
                        break;
                }

                if (whichOne === 'firebase') {
                    this.$store.commit('setFirebaseSigninState', newState);
                } else {
                    this.$store.commit('setServerSigninState', newState);
                }
            },

            //
            // Set the given required user name
            //
            async handleNameSubmit() {
                try {
                    const user = await ApiUtils.apiWrapper(UserService.saveUser, {
                        userId: this.$store.getters.getAuthedUserId,
                        publicUser: {
                            userId: this.$store.getters.getAuthedUserId,
                            firstName: this.usersName,
                        },
                        updateMask: {
                            paths: ['firstName'] as string[],
                        },
                    } as AuthedUser);

                    this.$store.commit('setAuthedUser', user);

                    this.requireName = this.$store.getters.isSignedIn && this.$store.getters.getPublicUserValue('firstName') === '';

                    // Go to home page if not already there
                    if (this.$router.currentRoute.name !== constants.ROUTE_USER_HOME) {
                        this.$router.push({
                            name: constants.ROUTE_USER_HOME,
                            params: { userId: this.$store.getters.getAuthedUserId },
                        });
                    }
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            AckChatPostReceiptIfNotOnChat(message: PubSubEvent) {
                const post = JSON.parse(message.jsonData) as Post;

                //
                // If not on the chat page mark as delivered.  If on the chat page, it
                // will get the message and decide if a delivered or read receipt is required.
                // Why the timeout?  That's a hack to allow the originator of the post
                // to refetch the page again.  If posts were inserted into the array on the
                // fly, this would not be needed.
                //
                const pageNav = this.$store.getters.getPageNavigation(constants.ROUTE_USER_DATE);
                if (this.$router.currentRoute.name !== constants.ROUTE_USER_DATE || !pageNav || pageNav.tab !== 0) {
                    setTimeout(async () => {
                        try {
                            await ApiUtils.apiWrapper(ChatService.chatPostDelivered, post);
                        } catch (error) {
                            Utils.CommonErrorHandler(error);
                        }
                    }, 1000);
                }
            },

            activeStateChanged(message: PubSubEvent) {
                switch (message.event) {
                    case SharedConstants.NOTIFICATION_USER_REACTIVATED:
                        alert('Your account has been reactivated.');
                        break;
                    case SharedConstants.NOTIFICATION_USER_SUSPENDED:
                        alert('Your account has been temporarily suspended.');
                        break;
                    case SharedConstants.NOTIFICATION_USER_BANNED:
                        alert('Your account has been banned.  You can no longer use our service.');
                        signout(true, true);
                        break;
                }
            },
        },
    });
