
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import { log, logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import * as analytics from '@/tsfiles/analytics';
    import { DateTime as LuxonDateTime } from 'luxon';
    import validator from 'validator';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import Avatar from '@/components/Avatar.vue';
    import ImageUploader from 'vue-image-upload-resize';
    import UserActivationMenu from '@/components/user/UserActivationMenu.vue';
    import CountryCodeMenu from '@/components/uiutils/CountryCodeMenu.vue';
    import {
        SharedConstants,
        UserService,
        AuthedUser,
        PublicUser,
        MediaService,
        StringVisibility,
        Profile,
        TargetingPreferences,
    } from '@bostonventurestudio/lola-api';

    Vue.use(ImageUploader);

    export default mixins(VueConstants).extend({
        name: 'UserSettings',

        components: {
            'url-avatar': Avatar,
            'country-code-menu': CountryCodeMenu,
            'user-activation-menu': UserActivationMenu,
        },

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<AuthedUser>,
        },

        data() {
            return {
                localUser: {} as AuthedUser,
                submitting: false,
                birthDateValue: '2000-01-01',
                origBirthDateValue: '2000-01-01',
                countryCodes: ['au', 'ca', 'gb', 'in', 'nz', 'us'] as string[],

                //
                // The currentImage can be the profile imageUrl or a base64 string
                // containing the image the user wants to upload and save as their
                /// new profile avatar.
                //
                currentImage: '',
                currentImageName: '',
                currentImageType: '',
                saveNewAvatar: false,

                alertMessage: null as string | null,
                dismissCountDown: 0,
                permanent: false,
                alertType: 'success',
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: AuthedUser, oldVal: AuthedUser) {
                    if (newVal) {
                        this.localUser = Utils.deepCopy(newVal);
                        this.initData();
                    }
                },
            },
        },

        computed: {
            //
            // Is anything in user dirty?  We cannot use deepEqual, since it's not great
            // at distinguishing between an empty value and undefined, plus if you don't
            // check individual fields, isDirty won't even get called.
            //
            isDirty(): boolean {
                const hasChanges =
                    this.saveNewAvatar ||
                    !Utils.deepEqual(this.localUser.publicUser?.firstName, this.user.publicUser?.firstName) ||
                    !Utils.deepEqual(this.localUser.publicUser?.lastName, this.user.publicUser?.lastName) ||
                    this.birthDateValue != this.origBirthDateValue ||
                    (this.latitudeValid && this.latitudeIsDifferent) ||
                    (this.longitudeValid && this.longitudeIsDifferent) ||
                    !Utils.deepEqual(this.localUser.location?.displayName, this.user.location?.displayName) ||
                    this.localUser.countryCode !== this.user.countryCode ||
                    this.allowAnalyticsIsDifferent;

                this.$emit('set-settings-dirty', hasChanges);
                return hasChanges;
            },

            // orig value would be undefined if not set, so we cannot just compare the two values
            allowAnalyticsIsDifferent(): boolean {
                if (this.localUser.allowAnalytics === undefined) {
                    return this.user.allowAnalytics === true;
                } else if (this.user.allowAnalytics === undefined) {
                    return this.localUser.allowAnalytics === true;
                }

                return this.localUser.allowAnalytics !== this.user.allowAnalytics;
            },

            firstNameValid(): boolean {
                return this.localUser.publicUser?.firstName !== undefined && !validator.isEmpty(this.localUser.publicUser?.firstName);
            },

            latitudeValid(): boolean {
                if (this.localUser.location && this.localUser.location.latitude === 0) {
                    return true; // 0 is legal
                } else if (!this.localUser.location || !this.localUser.location.latitude) {
                    return false;
                }

                return this.localUser.location.latitude >= -90.0 && this.localUser.location.latitude <= 90.0;
            },

            longitudeValid(): boolean {
                if (this.localUser.location && this.localUser.location.longitude === 0) {
                    return true; // 0 is legal
                } else if (!this.localUser.location || !this.localUser.location.longitude) {
                    return false;
                }

                return this.localUser.location.longitude >= -180.0 && this.localUser.location.longitude <= 180.0;
            },

            // Return true if the latitude values are different.  Compare as strings to avoid
            // float comparisons issues.
            latitudeIsDifferent(): boolean {
                if (!this.localUser.location || !this.localUser.location.latitude) {
                    return false;
                }

                if (this.localUser.location.latitude === 0.0 && !this.user.location?.latitude) {
                    return false;
                }

                return !Utils.caselessSameString(this.localUser.location.latitude.toString(), this.user.location?.latitude?.toString());
            },

            // Return true if the longitude values are different.  Compare as strings to avoid
            // float comparisons issues.
            longitudeIsDifferent(): boolean {
                if (!this.localUser.location || !this.localUser.location.longitude) {
                    return false;
                }

                if (this.localUser.location.longitude === 0.0 && !this.user.location?.longitude) {
                    return false;
                }

                return !Utils.caselessSameString(this.localUser.location.longitude.toString(), this.user.location?.longitude?.toString());
            },
        },

        methods: {
            initData() {
                if (!this.localUser.disabledReason) {
                    Vue.set(this.localUser, 'disabledReason', '');
                }

                //
                // We don't need profile nor targeting structs in our localUser copy.  There's
                // no reason to send it all back to the server.
                //
                if (!this.localUser.publicUser) {
                    this.localUser.publicUser = {} as PublicUser;
                }

                this.localUser.publicUser.profile = {} as Profile;
                this.localUser.targetingPreferences = {} as TargetingPreferences;

                if (this.localUser.dateOfBirth) {
                    // The date from the server is UTC.  It's really just stored as a DATE, but
                    // going through grpc converts it to a google timestamp, which has all the
                    // info.  We need to make sure it's UTC; otherwise the date will be off
                    // in the calendar control.  No need to update localUser value.
                    this.birthDateValue = LuxonDateTime.fromISO(this.localUser.dateOfBirth).toUTC().toFormat('yyyy-MM-dd');
                    this.origBirthDateValue = this.birthDateValue;
                }

                // If no location from the server, it means the default of 0/0 is not going
                // to come through grpc.  Set to 0/0
                if (this.localUser.location) {
                    if (!this.localUser.location?.longitude) {
                        Vue.set(this.localUser.location, 'longitude', 0.0);
                    }
                    if (!this.localUser.location?.latitude) {
                        Vue.set(this.localUser.location, 'latitude', 0.0);
                    }
                }

                //
                // Set the currentImage to the profile imageUrl just retrieved.
                // The currentImage can be the profile URL or a base64 string
                // containing the image the user wants to upload and save as their
                /// new profile avatar.
                //
                if (this.localUser.publicUser?.profile && this.localUser.publicUser?.avatar?.cachedImageUrl) {
                    this.currentImage = this.localUser.publicUser?.avatar.cachedImageUrl;
                } else if (this.localUser.publicUser?.profile && this.localUser.publicUser?.avatar?.imageUrl) {
                    this.currentImage = this.localUser.publicUser?.avatar.imageUrl;
                }

                //
                // Some visibility fields cannot be undefined
                //
                if (!this.localUser.publicUser?.lastName) {
                    Vue.set(this.localUser, 'lastName', {} as StringVisibility);
                }
            },

            inputsValid(): boolean {
                return this.isDirty && this.firstNameValid;
            },

            //
            // Called when the image loader is done loading, resizing, and reorienting
            // a new image from the user.  We only need to set currentImage, which will
            // be the base64 string, in its entirety.
            //
            // We also store the the image name and type from fileData, since we need some
            // of that info for uploading the image to the server.
            //
            setImage(fileData: any) {
                if (
                    !fileData ||
                    !fileData.dataUrl ||
                    fileData.dataUrl === '' ||
                    !fileData.info ||
                    !fileData.info.name ||
                    fileData.info.name === '' ||
                    fileData.info.type === ''
                ) {
                    log('Invalid data from image uploader, in setImage()');
                    return;
                }

                this.currentImage = fileData.dataUrl; // Keep 'data:' at the front, so img can display
                this.currentImageName = fileData.info.name;
                this.currentImageType = fileData.info.type;
                this.saveNewAvatar = true;
            },

            getFileExtension(filePath: string): string {
                return filePath.slice((Math.max(0, filePath.lastIndexOf('.')) || Infinity) + 1);
            },

            alertCountDownChanged(dismissCountDown: number) {
                this.dismissCountDown = dismissCountDown;
                if (dismissCountDown === 0) {
                    this.submitting = false;
                }
            },

            showAlert(message: string, alertType: string, perm: boolean) {
                this.permanent = perm;
                if (!perm) {
                    this.dismissCountDown = 5;
                }
                this.alertMessage = message;
                this.alertType = alertType;
            },

            async handleSubmit(event: Event) {
                if (this.submitting) {
                    return;
                }
                this.submitting = true;

                try {
                    //
                    // If there's a new avatar image, upload it to the server to get the mediaId.
                    // The mediaId will be used with the actual saveUser.
                    //
                    if (this.saveNewAvatar) {
                        const res = await ApiUtils.apiWrapper(MediaService.uploadMedia, {
                            fileName: this.currentImageName,
                            collection: SharedConstants.MEDIA_COLLECTION_AVATAR,
                            contents: this.currentImage.split(',')[1],
                        });

                        if (res.warningCode === SharedConstants.MEDIA_INVALID_EXTENSION) {
                            const ext = this.getFileExtension(this.currentImageName);
                            this.showAlert('Invalid file extension (' + ext + ')', 'danger', false);
                            return;
                        } else if (res.media && this.localUser.publicUser?.avatar) {
                            // Requires setting so Save below actually save the new media Id, etc.
                            this.localUser.publicUser.avatar.mediaId = res.media.mediaId;
                            this.localUser.publicUser.avatar.imageUrl = res.media.url;
                        }
                    }

                    // Dynamically set mask based on what's different
                    this.localUser.updateMask = {
                        paths: this.createUpdateMask(),
                    };

                    // Convert DOB string to time, AFTER mask creation
                    this.localUser.dateOfBirth = new Date(this.birthDateValue);

                    // Tell the server.
                    let updatedUser = this.user;
                    if (this.localUser.updateMask && this.localUser.updateMask.paths && this.localUser.updateMask.paths.length > 0) {
                        updatedUser = await ApiUtils.apiWrapper(UserService.saveUser, this.localUser);
                    }

                    //
                    // If the allowAnalytics changed, tell analytics
                    //
                    if (this.localUser.allowAnalytics !== this.user.allowAnalytics) {
                        if (this.user.allowAnalytics) {
                            analytics.turnOnUserAnalytics();
                        } else {
                            analytics.turnOffUserAnalytics();
                        }
                    }

                    if (this.user.userId) {
                        analytics.logAppInteraction(analytics.ANALYTICS_ACTION_UPDATE_USER, this.user.userId.toString());
                    }

                    // Tell the parent about the updates, since we need the new values to
                    // compare against, store updates, etc.
                    this.$emit('user-updated', updatedUser);
                    this.submitting = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Create the updateMask for the user object that will be sent to the server.  These
            // are the fields that will get updated in the database.
            //
            createUpdateMask(): string[] {
                const mask = [] as string[];

                // Go through publicUser fields
                ['firstName', 'lastName'].map((item) => {
                    if (
                        this.user.publicUser &&
                        this.localUser.publicUser &&
                        !Utils.deepEqual(this.user.publicUser[item as keyof PublicUser], this.localUser.publicUser[item as keyof PublicUser])
                    ) {
                        mask.push(item);
                    }
                });

                // Go through the authedUser fields
                ['allowAnalytics', 'countryCode'].map((item) => {
                    if (!Utils.deepEqual(this.user[item as keyof AuthedUser], this.localUser[item as keyof AuthedUser])) {
                        mask.push(item);
                    }
                });

                //
                // Add in non-profileDefinitions, or those that are not compared using deepEqual.
                //
                if (!Utils.deepEqual(this.birthDateValue, this.origBirthDateValue)) {
                    mask.push('dateOfBirth');
                }

                // avatar is different than the prop 'imageId'
                if (!Utils.deepEqual(this.user.publicUser?.avatar, this.localUser.publicUser?.avatar)) {
                    mask.push('imageId');
                }

                // Checking for no latitude means location is empty.
                if (
                    (this.user.location?.latitude && !Utils.deepEqual(this.user.location, this.localUser.location)) ||
                    (!this.user.location?.latitude && this.localUser.location?.latitude !== 0 && this.localUser.location?.longitude !== 0)
                ) {
                    mask.push('location');
                }

                return mask;
            },
        },
    });
