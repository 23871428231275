
    import Vue, { PropOptions } from 'vue';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { TimeUtils } from '@/tsfiles/timeutils';
    import { SharedConstants, AdminService, Ticket } from '@bostonventurestudio/lola-api';
    import { Utils } from '@/tsfiles/utils';

    export default mixins(VueConstants).extend({
        name: 'OpenTickets',

        components: {},

        props: {},

        data() {
            return {
                ticketFields: [
                    { key: 'id', tdClass: 'align-middle' },
                    { key: 'name', tdClass: 'align-middle' },
                    { key: 'userId', tdClass: 'align-middle' },
                    { key: 'status', tdClass: 'align-middle' },
                    { key: 'assignedTo', tdClass: 'align-middle' },
                    { key: 'source', tdClass: 'align-middle' },
                    { key: 'userNote', tdClass: 'align-middle' },
                    { key: 'age', tdClass: 'align-middle' },
                    { key: 'received', tdClass: 'align-middle' },
                    { key: 'reported', tdClass: 'align-middle' },
                ],
                openItems: [] as any[],
                filterOpenItemsType: '',
                showFlagChannelModal: false,
                tickets: [] as Ticket[],
                selectedMarket: 'Boston',
                selectedSource: 'all',
                ticketSources: [
                    { value: 'all', text: 'All' },
                    { value: SharedConstants.TICKET_SOURCE_APICOUNT, text: 'APICount' },
                    { value: SharedConstants.TICKET_SOURCE_PROFILE, text: 'Profile' },
                    { value: SharedConstants.TICKET_SOURCE_CHAT, text: 'Chat' },
                    { value: SharedConstants.TICKET_SOURCE_POSTDATESURVEY, text: 'PostDateSurvey' },
                    { value: SharedConstants.TICKET_SOURCE_MEDIA, text: 'Media' },
                    { value: SharedConstants.TICKET_SOURCE_LIVENESS, text: 'Liveness' },
                ],
                markets: [
                    { value: 'all', text: 'All' },
                    { value: 'Boston', text: 'Boston' },
                ],
            };
        },

        watch: {
            openTickets: {
                immediate: true,
                deep: true,
                handler(newVal: Ticket[], oldVal: Ticket[]) {
                    if (newVal) {
                        this.rebuildOpenItems(newVal);
                    }
                },
            },
        },

        computed: {
            hasAccess(): boolean {
                return this.$store.getters.isSignedIn && RoleUtils.CanSupportUsers();
            },

            getUserID(): string {
                return this.$store.getters.getAuthedUserId;
            },
        },

        async mounted() {
            this.setOpenItemsFilterType(SharedConstants.TICKET_STATUS_UNRESOLVED);
        },

        methods: {
            async setOpenItemsFilterType(type: string) {
                this.filterOpenItemsType = type;
                await this.fetchTickets();
                this.rebuildOpenItems(this.tickets);
            },

            async fetchTicketsAndBuildOpenItems() {
                await this.fetchTickets();
                this.rebuildOpenItems(this.tickets);
            },

            async fetchTickets() {
                try {
                    const ret = await AdminService.getTickets({
                        ticketType: this.filterOpenItemsType,
                        market: this.selectedMarket,
                        source: this.selectedSource,
                    });

                    if (ret && ret.list && ret.list.length > 0) {
                        this.tickets = Utils.deepCopy(ret.list);
                    } else {
                        this.tickets = [] as Ticket[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async rebuildOpenItems(tickets: Ticket[]) {
                if (tickets.length === 0) {
                    this.openItems = [] as any[];
                    return;
                }

                const newItems = [] as any[];
                for (const item of tickets) {
                    // Get the full name for display
                    let fullName = item.recipientUser?.firstName;
                    if (item.recipientUser?.lastName && item.recipientUser?.lastName !== '') {
                        fullName += ' ' + item.recipientUser?.lastName;
                    }

                    // Get the full name of the reviewer (agent)
                    let reviewerName = item.reviewer?.firstName;
                    if (item.reviewer?.lastName && item.reviewer?.lastName !== '') {
                        reviewerName += ' ' + item.reviewer?.lastName;
                    }

                    //
                    // Push the items onto openItems, which is what will show up in the table (if
                    // there is a field for that item).  Hidden fields, such as ticketId,
                    // are used during openItemClicked().
                    //
                    newItems.push({
                        ticketId: item.ticketId,
                        name: fullName,
                        userId: item.recipientUser?.userId,
                        status: item.status,
                        assignedTo: reviewerName,
                        source: item.source,
                        userNote: item.userNote,
                        age: TimeUtils.pastTimeDistanceStringWithoutHelperWords(item.created),
                        received: item.ticketsReceived,
                        reported: item.ticketsReported,
                    });
                }

                this.openItems = newItems;
            },

            openItemClicked(item: Ticket) {
                const ticketId = item.ticketId;
                const userId = this.$store.getters.getAuthedUserId;
                const route = `/${userId}/admin/ticket?ticketId=${ticketId}`;

                this.$router.push({ path: route });
            },
        },
    });
