var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-center align-items-center"},[(!_vm.horizontal)?_c('div',{staticClass:"d-flex flex-column dark-background menu-vertical-border"},[_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('My Calendar'),expression:"'My Calendar'",modifiers:{"hover":true,"right":true}}],staticClass:"p-2 pt-4 pb-3 fa-light fa-calendar fa-2x force-fontawesome-pro-family show-pointer",class:{
                'brand-color': _vm.selection == _vm.ROUTE_USER_CALENDAR && _vm.isSignedIn,
                'text-white': _vm.selection != _vm.ROUTE_USER_CALENDAR && _vm.isSignedIn,
                'text-white text-muted': !_vm.isSignedIn,
            },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_CALENDAR)}}}),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Ask Out'),expression:"'Ask Out'",modifiers:{"hover":true,"right":true}}],staticClass:"p-2 py-3 fa-light fa-heart fa-2x show-pointer",class:{
                'brand-color': _vm.selection == _vm.ROUTE_USER_PROSPECTS && _vm.isSignedIn,
                'text-white': _vm.selection != _vm.ROUTE_USER_PROSPECTS && _vm.isSignedIn,
                'text-white text-muted': !_vm.isSignedIn,
            },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_PROSPECTS)}}}),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Respond'),expression:"'Respond'",modifiers:{"hover":true,"right":true}}],staticClass:"p-2 py-3 fa-light fa-question-circle fa-2x show-pointer",class:{
                'brand-color': _vm.selection == _vm.ROUTE_USER_PENDING_PROPOSALS && _vm.isSignedIn,
                'text-white': _vm.selection != _vm.ROUTE_USER_PENDING_PROPOSALS && _vm.isSignedIn,
                'text-white text-muted': !_vm.isSignedIn,
            },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_PENDING_PROPOSALS)}}}),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('Dates'),expression:"'Dates'",modifiers:{"hover":true,"right":true}}],staticClass:"p-2 py-3 fa-light fa-comment fa-2x show-pointer",class:{
                'brand-color': _vm.selection == _vm.ROUTE_USER_DATES && _vm.isSignedIn,
                'text-white': _vm.selection != _vm.ROUTE_USER_DATES && _vm.isSignedIn,
                'text-white text-muted': !_vm.isSignedIn,
            },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_DATES)}}}),_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right",value:('My Profile'),expression:"'My Profile'",modifiers:{"hover":true,"right":true}}],staticClass:"p-2 pt-3 pb-4 fal fa-user fa-2x show-pointer",class:{
                'brand-color': _vm.selection == _vm.ROUTE_USER_PROFILE && _vm.isSignedIn,
                'text-white': _vm.selection != _vm.ROUTE_USER_PROFILE && _vm.isSignedIn,
                'text-white text-muted': !_vm.isSignedIn,
            },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_PROFILE)}}})]):_c('div',{staticClass:"dark-background menu-horizontal-border py-2 m-0"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',{staticClass:"align-items-center",attrs:{"no-gutters":""}},[_c('b-col',[_c('span',{staticClass:"fa-light fa-calendar fa-2x show-pointer",class:{
                            'brand-color': _vm.selection == _vm.ROUTE_USER_CALENDAR && _vm.isSignedIn,
                            'text-white': _vm.selection != _vm.ROUTE_USER_CALENDAR && _vm.isSignedIn,
                            'text-white text-muted': !_vm.isSignedIn,
                        },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_CALENDAR)}}})]),_c('b-col',[_c('span',{staticClass:"fa-light fa-heart fa-2x show-pointer",class:{ 'brand-color': _vm.selection == _vm.ROUTE_USER_PROSPECTS, 'text-white': _vm.selection != _vm.ROUTE_USER_PROSPECTS },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_PROSPECTS)}}})]),_c('b-col',[_c('span',{staticClass:"fal fa-user fa-2x show-pointer",class:{
                            'brand-color': _vm.selection == _vm.ROUTE_USER_PROFILE && _vm.isSignedIn,
                            'text-white': _vm.selection != _vm.ROUTE_USER_PROFILE && _vm.isSignedIn,
                            'text-white text-muted': !_vm.isSignedIn,
                        },on:{"click":function($event){$event.preventDefault();return _vm.menuSelect(_vm.ROUTE_USER_PROFILE)}}})])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }