
    import Vue from 'vue';
    import Scorecard from '@/components/dating/Scorecard.vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import {
        DatingScorecard,
        DatingService,
        GetDatingScorecardsResponse,
        GetDatingScorecardStatsResponse,
        SharedConstants,
    } from '@bostonventurestudio/lola-api';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';

    export default Vue.extend({
        name: 'UserProspects',

        components: {
            'dating-scorecard': Scorecard,
        },

        props: {},

        data() {
            return {
                scorecards: [] as DatingScorecard[],
                page: 1,
                pageSize: SharedConstants.DATING_SCORECARDS_PAGE_SIZE,
                fetchComplete: true,
                lastSizeOfScorecards: 0,
                datesCompleted: 0,
            };
        },
        mounted() {
            this.getScorecardDatesStats();
            this.getScorecards();
        },

        computed: {
            hasAccess(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    (!this.gettingRecordForAnotherUser || (this.gettingRecordForAnotherUser && RoleUtils.CanSupportUsers()))
                );
            },
            gettingRecordForAnotherUser(): boolean {
                return this.$route.params.userId != this.$store.getters.getAuthedUserId;
            },
            userId(): number {
                return parseInt(this.$route.params.userId == this.$store.getters.getAuthedUserId ? '' : this.$route.params.userId);
            },
            datesLeftMessage(): string {
                const datesLeft = SharedConstants.DATING_SCORECARDS_MAX_DATES - this.datesCompleted;
                return datesLeft > 1 ? `${datesLeft} dates until next report` : `${datesLeft} date until next report`;
            },
        },
        methods: {
            async getScorecardDatesStats() {
                try {
                    let query = {};
                    if (this.userId > 0) query = { userId: this.userId };

                    const ret = (await ApiUtils.apiWrapper(DatingService.getDatingScorecardStats, query)) as GetDatingScorecardStatsResponse;
                    this.datesCompleted = ret.datesCompleted ?? 0;
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },
            async getScorecards() {
                try {
                    this.fetchComplete = false;

                    let query: { [key: string]: number | string } = {
                        page: this.page,
                        pageSize: this.pageSize,
                    };
                    if (this.userId > 0) query.userId = this.userId;

                    const ret = (await ApiUtils.apiWrapper(DatingService.getDatingScorecards, query)) as GetDatingScorecardsResponse;
                    if (ret && ret.scorecards) {
                        // append scorecards to the list for pagination
                        if (this.scorecards.length > 0) {
                            this.scorecards = this.scorecards.concat(ret.scorecards);
                        } else {
                            this.scorecards = ret.scorecards;
                        }
                        this.lastSizeOfScorecards = ret.scorecards.length;
                    } else {
                        this.scorecards = [] as DatingScorecard[];
                    }

                    this.fetchComplete = true;
                } catch (error: any) {
                    this.fetchComplete = true;
                    Utils.CommonErrorHandler(error);
                }
            },

            showMore() {
                this.page += 1;
                this.getScorecards();
            },
            getUserId(): string {
                return this.$route.params.userId == this.$store.getters.getAuthedUserId ? '' : this.$route.params.userId;
            },
        },
    });
