/*
 * Copyright 2022-2023 Lola - All rights reserved.
 * File: firebase.ts
 * Project: lola
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import store from './store';
import * as analytics from '@/tsfiles/analytics';
import * as constants from '@/tsfiles/constants';
import { log } from '@/tsfiles/errorlog';
import { ApiUtils } from '@/tsfiles/apiutils';
import config from '../config';
import router from './router';
import { SigninStatus } from './enums';
import { AuthService } from '@bostonventurestudio/lola-api';
import { afterIdentityKnown } from '@/tsfiles/router';

export function initFirebase() {
    // Do nothing if we've already been initialized
    if (!firebase.apps.length) {
        firebase.initializeApp(config.firebase);
        firebase.auth().onAuthStateChanged(firebaseStateChange);

        analytics.initAnalytics();
    }
}

//
// Signout of both firebase and server.  We want to try to signout of both, even
// if one of the two fails.  The signinState will be out of sync if one fails, which
// we'll detect and deal with during routing, etc.
// In almost every case 'signoutFromServer' should be true, except when deleting a
// user.  After deleting a user we want to make sure firebase is signed out, and
// analytics are reset; however, we don't want to try and signout with the server since
// there's no user anymore and the server api will return an error.
//
const letsWait = (ms: number) => new Promise((r, j) => setTimeout(r, ms));

export async function signout(signoutFromServer: boolean, goHome: boolean) {
    store.commit('clearAuthedUser');
    firebaseSignout();
    if (signoutFromServer) {
        await serverSignout();
    }

    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_SIGNOUT);
    analytics.logSignout();

    //
    // Now that we are an anonymous user, we need to see if the server is
    // allowing analytics.  GetIdentity will tell us that.  GetIdentity inside
    // router won't be called on signout, but would on a browser refresh.
    // We need to get the identity again and call afterIdentityKnown() to
    // reset the store properly, as well as analytics.
    //
    const ident = await ApiUtils.apiWrapper(AuthService.getIdentity);
    afterIdentityKnown(ident);

    if (goHome && router.currentRoute.name !== constants.ROUTE_MARKETING) {
        router.push({ name: constants.ROUTE_MARKETING });
    }
}

//
// firebaseSignout will sign out the user from firebase, even
// if we think they are already signed out.  This used to
// do 'await firebase.auth().signOut()', but this would hang
// every once in a while.  Removing async/await, but that
// might cause problems with our signout() function above,
// especially if we try to router to marketing.  The
// store firebase signout state should get set before
// the 'await serverSignout()' finished above.  Ugh...
//
function firebaseSignout() {
    try {
        firebase.auth().signOut();
        store.commit('setFirebaseSigninState', SigninStatus.SIGNEDOUT);
    } catch (error: any) {
        store.commit('setFirebaseSigninState', SigninStatus.UNKNOWN);
        log(error);
    }
}

//
// serverSignout will sign out the user from the server, even
// if we think they are already signed out.
//
async function serverSignout() {
    try {
        await ApiUtils.apiWrapper(AuthService.signout, { value: '' });
        store.commit('setServerSigninState', SigninStatus.SIGNEDOUT);
    } catch (error: any) {
        store.commit('setServerSigninState', SigninStatus.UNKNOWN);
        log(error);
    }
}

//
// This function is called by firebase when there's any type
// of authentication state change, or an explicit call during our route changes.
//
async function firebaseStateChange(user: any | null) {
    if (user) {
        // We have a user, so firebase is signed in.
        store.commit('setFirebaseSigninState', SigninStatus.SIGNEDIN);
    } else {
        //
        // There's no user, so reset the states correctly.
        //

        store.commit('setFirebaseSigninState', SigninStatus.SIGNEDOUT);
        store.commit('clearAuthedUser');

        // If we think the server is signed in, do signout
        if (store.getters.getServerSigninState === SigninStatus.SIGNEDIN) {
            try {
                await ApiUtils.apiWrapper(AuthService.signout, { value: '' });
                store.commit('setServerSigninState', SigninStatus.SIGNEDOUT);
            } catch (error: any) {
                store.commit('setServerSigninState', SigninStatus.UNKNOWN);
                log(error);
            }
        }
    }
}
