
    import Vue from 'vue';
    import validator from 'validator';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { SharedConstants } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'OffensiveReportModal',

        props: {
            title: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                reasonInput: '',
            };
        },

        computed: {},
        methods: {},
    });
