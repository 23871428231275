
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';

    export default mixins(VueConstants).extend({
        name: 'Search',

        components: {},

        props: {},

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
