
    import Vue from 'vue';
    import config from '@/config';

    export default Vue.extend({
        name: 'DeleteUserModal',

        components: {},

        props: {},

        data() {
            return {
                deleteInputStr: '',
                hardDelete: false,
            };
        },

        computed: {
            showHardDelete(): boolean {
                return config.runEnvironment !== 'prod' && this.$store.getters.isTestAdmin;
            },
        },

        methods: {},
    });
