
    import Vue, { PropOptions } from 'vue';
    import * as analytics from '@/tsfiles/analytics';
    import * as constants from '@/tsfiles/constants';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { SocialMediaItem, SharedConstants } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'SocialMediaLinks',

        components: {},

        props: {
            // User's social media info
            socialMedia: {
                type: Array,
                required: true,
            } as PropOptions<SocialMediaItem[]>,
        },

        data() {
            return {};
        },

        computed: {},

        methods: {
            //
            // When a user clicks on an external link, we log the analytics event.  The
            // click is usually an "@click" without ".prevent", so the href will be triggered.
            //
            analyticsExternalClick(type: string) {
                analytics.logAppInteraction(analytics.ANALYTICS_ACTION_EXTERNAL_CONTENT_CLICK, type.toLowerCase());
            },

            //
            // Get the social media list into the correct order, defined by
            // constants.SOCIAL_MEDIA_TYPES.
            //
            // PERF: Create arrays on every page hit seems crazy.  Need
            // better way.
            //
            getSocialMediaList(): SocialMediaItem[] {
                const ret = [] as SocialMediaItem[];
                for (const item of constants.SOCIAL_MEDIA_TYPES) {
                    for (const userItem of this.socialMedia) {
                        if (item.platform === userItem.provider) {
                            ret.push(userItem);
                        }
                    }
                }

                return ret;
            },

            socialUrl(item: SocialMediaItem): string {
                if (!item.value || item.value === '') {
                    logInvalidParams(this.$options.name, 'socialUrl');
                    return '';
                }

                const arrayIdx = constants.SOCIAL_MEDIA_HASH.get(item.provider);
                const platform = constants.SOCIAL_MEDIA_TYPES[arrayIdx].platform;
                if (platform === SharedConstants.SOCIAL_LINKEDIN || platform === SharedConstants.SOCIAL_PERSONAL) {
                    const value = item.value;
                    if (!value.startsWith('http')) {
                        return 'https://' + value;
                    }

                    return value;
                }

                const domain = constants.SOCIAL_MEDIA_TYPES[arrayIdx].domain;
                return 'https://' + domain + '/' + item.value;
            },

            getSocialMediaTitle(platform: string | undefined): string {
                if (platform && platform !== '') {
                    const idx = constants.SOCIAL_MEDIA_HASH.get(platform);
                    if (idx) {
                        return constants.SOCIAL_MEDIA_TYPES[idx].name;
                    }
                }

                return '';
            },

            getSocialMediaIcon(platform: string, getFont: boolean): string {
                const idx = constants.SOCIAL_MEDIA_HASH.get(platform);
                if (idx) {
                    if (getFont) {
                        return constants.SOCIAL_MEDIA_TYPES[idx].fontIcon;
                    }

                    return constants.SOCIAL_MEDIA_TYPES[idx].imageUrl;
                }

                return '';
            },
        },
    });
