
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import * as constants from '@/tsfiles/constants';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import * as analytics from '@/tsfiles/analytics';
    import { PageMessage } from '@/tsfiles/interfaces';
    import { AdminService, AuthedUser, Notification, UserService } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserActivationMenu',

        components: {},

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<AuthedUser>,
        },

        data() {
            return {};
        },

        computed: {},

        methods: {
            async finishOnboarding() {
                try {
                    await ApiUtils.apiWrapper(UserService.onboardingCompleteV2, {
                        signupLocation: this.user.location,
                    });

                    this.$emit('reload-user');
                    this.$store.commit('clearPageMessage', constants.FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async restartOnboarding() {
                try {
                    await ApiUtils.apiWrapper(UserService.resetOnboardingComplete);
                    this.$emit('reload-user');

                    this.$store.commit('setPageMessage', {
                        notification: {
                            notificationId: 0,
                            event: constants.FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE,
                        } as Notification,
                        permanent: true,
                    } as PageMessage);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async removeFromWaitlist() {
                try {
                    await ApiUtils.apiWrapper(UserService.getOffWaitlist);
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async pauseDating() {
                if (!this.$store.getters.getAuthedUserValue('active')) {
                    logInvalidParams(this.$options.name, 'pauseDating');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.pauseAccount);
                    this.$emit('reload-user');

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_PAUSE_DATING);

                    this.$store.commit('setPageMessage', {
                        notification: {
                            notificationId: 0,
                            event: constants.FAKE_NOTIFICATION_DATING_PAUSED,
                        } as Notification,
                        permanent: true,
                    } as PageMessage);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async restartDating() {
                if (this.$store.getters.getAuthedUserValue('active')) {
                    logInvalidParams(this.$options.name, 'restartDating');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.reactivateAccount);
                    this.$emit('reload-user');

                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_RESTART_DATING);
                    this.$store.commit('clearPageMessage', constants.FAKE_NOTIFICATION_DATING_PAUSED);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async reactivateAccount() {
                try {
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async suspendAccount() {
                try {
                    await ApiUtils.apiWrapper(AdminService.suspendUser);
                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_SUSPEND_ACCOUNT);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
