
    import Vue from 'vue';
    import validator from 'validator';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { SharedConstants } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'CollectTextInput',

        props: {
            title: {
                type: String,
                required: true,
            },

            minLength: {
                type: Number,
                required: false,
                default: 1,
            },

            maxLength: {
                type: Number,
                required: false,
                default: 1000,
            },
        },

        data() {
            return {
                inputData: '',
            };
        },

        computed: {
            invalidInput(): boolean {
                return this.inputData.trim().length < this.minLength;
            },
        },

        methods: {},
    });
