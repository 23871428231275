
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import UserProfile from '@/components/user/UserProfile.vue';
    import UserChat from '@/components/user/UserChat.vue';
    import {
        SharedConstants,
        UserService,
        PublicUser,
        DatingService,
        Match,
        GetMatchRequest,
        GetMatchResponse,
        WipeMatchRequest,
    } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserPastDate',

        components: {
            'user-profile': UserProfile,
            'user-chat': UserChat,
        },

        props: {},

        data() {
            return {
                match: undefined as Match | undefined,
                user: undefined as PublicUser | undefined,

                currentTab: 0,
                currentNavigation: { page: constants.ROUTE_USER_PAST_DATE } as PageNavigationData,
            };
        },

        watch: {
            '$route.query.matchId': {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal && newVal !== '') {
                        this.fetchMatch(Number(newVal));
                    }
                },
            },
        },

        mounted() {
            //
            // If page navigation contains data, process.
            //
            const nav = this.$store.getters.getPageNavigation(constants.ROUTE_USER_PAST_DATE);
            if (nav && nav.tab) {
                this.$nextTick(() => {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                });
            }
        },

        beforeDestroy() {
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {
            // Return the user's name.  Combined with lastname if given.
            getDisplayName(): string {
                return Utils.FormatUserNameForDisplay(this.user?.firstName, this.user?.lastName);
            },
        },

        methods: {
            async fetchMatch(matchId: number) {
                try {
                    const res = (await ApiUtils.apiWrapper(DatingService.getMatch, {
                        matchId,
                    } as GetMatchRequest)) as GetMatchResponse;

                    if (!res || !res.match || res.match.matchId === 0) {
                        logInvalidResponse(this.$options.name, 'fetchMatch', matchId);
                        return;
                    }

                    this.match = res.match;
                    this.fetchUser(this.match.partnerUserId as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchUser(partnerUserId: number) {
                try {
                    this.user = await ApiUtils.apiWrapper(UserService.getPublicUserById, partnerUserId);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async deleteMatch() {
                if (!this.match || !this.match.matchId) {
                    logInvalidParams(this.$options.name, 'deleteMatch', this.match);
                    return;
                }

                // We need to set our match to undefined; otherwise there could
                // be issues in the chat child doing read receipts inside a timer.
                const matchId = this.match.matchId;
                this.match = undefined as Match | undefined;

                try {
                    await ApiUtils.apiWrapper(DatingService.wipeMatch, {
                        matchId,
                    } as WipeMatchRequest);
                    this.$router.replace({ name: constants.ROUTE_USER_PAST_DATES });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
