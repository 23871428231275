/*
 * Copyright 2022-2023 Lola - All rights reserved.
 * File: store.ts
 * Project: lola
 *
 * NOTE: Sometimes store changes require a restart
 */
import Vue from 'vue';
import Vuex from 'vuex';
import { Utils } from '@/tsfiles/utils';
import * as constants from '@/tsfiles/constants';
import { SigninStatus } from '@/tsfiles/enums';
import { SharedConstants, AdminRole, PublicUser, AuthedUser, Media } from '@bostonventurestudio/lola-api';
import { PageMessage, PageNavigationData, AdChannel } from '@/tsfiles/interfaces';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        segmentAnalytics: undefined as any | undefined,
        postBeep: undefined as any | undefined,

        firebaseSigninState: SigninStatus.UNKNOWN as SigninStatus,
        serverSigninState: SigninStatus.UNKNOWN as SigninStatus,

        authedUser: {
            userId: 0,
            email: '',
            emailVerified: false,
            phone: '',
            phoneVerified: false,
            active: false,
            onboardingComplete: false,
            onWaitlist: false,
            datingPaused: false,
            allowAnalytics: false,
            profileCompleteness: 0,
            visibilityCompleteness: 0,
            publicUser: {
                firstName: '',
                lastName: '',
                avatar: {
                    imageUrl: '',
                    cachedImageUrl: '',
                } as Media,
                qrcode: {
                    imageUrl: '',
                    cachedImageUrl: '',
                } as Media,
            } as PublicUser,
        } as AuthedUser,

        adminUserId: 0, // For impersonation
        roles: [] as AdminRole[],

        currentMenu: constants.ROUTE_USER_CALENDAR,

        //
        // Instead of keeping 'tab', what page of content, filtering, etc. data as query
        // parameters, for making the browser back button work, use the store.
        // Some views and top-level components will use this information to
        // restore the page the user was on (when user is hitting browser
        // navigation buttons).  The various types of pages that need
        // this information are initialized in initializeStore() below.
        //
        pageNavigation: new Map(),

        //
        // Someone wants to show a message on the page, usually the one
        // we are routing to.  router.push/replace with a 'path' doesn't
        // allow params to be set.  If you want to go to the same page
        // but with one param change, you can't do that either (you'll get
        // a NavigationDuplicated error).  For easily setting a notification message
        // to display, use this store value.
        //
        // The id is specifically set here, so Vue's reactivity rules will apply;
        // otherwise we need to Vue.set during setPageMessage.  The user might
        // have multiple in-app notifications that go to the same page.  We want
        // to make sure the message on the page changes.
        //
        pageMessages: [] as PageMessage[],

        //
        // AdChannel data, which goes to analytics and is part of a branch link.
        //
        adChannel: {} as AdChannel,
    },
    mutations: {
        //
        // initializeStore is called by main.ts, when our app is
        // recreated. It's used to initialize pageNavigation and sync items with localStorage.
        //
        // tslint:disable:no-empty
        initializeStore(state) {
            state.pageNavigation.set(constants.ROUTE_USER_SETTINGS, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_USER_HOME, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_USER_DATE, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_USER_PAST_DATE, [] as PageNavigationData[]);
            state.pageNavigation.set(constants.ROUTE_SEARCH, [] as PageNavigationData[]);

            // Get current AdChannel data from localStorage
            const str = localStorage.getItem(constants.LOCAL_STORAGE_KEY_AD_CHANNEL);
            if (str) {
                state.adChannel = JSON.parse(str);
            }
        },

        setFirebaseSigninState(state, val: SigninStatus) {
            state.firebaseSigninState = val;
        },

        setServerSigninState(state, val: SigninStatus) {
            state.serverSigninState = val;
        },

        // The values need to be defined in order to make changes be reactive within components.
        // Defining them explicity keeps them type-safe.
        // Not all AuthedUser or PublicUser values are defined here.  We only defined what's needed
        // for reactivity.
        clearAuthedUser(state) {
            state.authedUser = {
                userId: 0,
                email: '',
                emailVerified: false,
                phone: '',
                phoneVerified: false,
                active: false,
                onboardingComplete: false,
                onWaitlist: false,
                datingPaused: false,
                allowAnalytics: false,
                profileCompleteness: 0,
                visibilityCompleteness: 0,
                publicUser: {
                    firstName: '',
                    lastName: '',
                    avatar: {
                        imageUrl: '',
                        cachedImageUrl: '',
                    } as Media,
                    qrcode: {
                        imageUrl: '',
                        cachedImageUrl: '',
                    } as Media,
                } as PublicUser,
            } as AuthedUser;

            state.roles = [] as AdminRole[];
            state.adminUserId = 0;
        },

        // Utils.deepCopy won't work for the store since it will remove fields that
        // are not specifically set in the given AuthedUser.  We could try lodash's
        // merge, but it's not worth it.  Just set the fields we need.
        setAuthedUser(state, authedUser: AuthedUser) {
            state.authedUser.userId = authedUser.userId || 0;
            state.authedUser.email = authedUser.email || '';
            state.authedUser.emailVerified = authedUser.emailVerified || false;
            state.authedUser.phone = authedUser.phone || '';
            state.authedUser.phoneVerified = authedUser.phoneVerified || false;
            state.authedUser.active = authedUser.active || false;
            state.authedUser.onboardingComplete = authedUser.onboardingComplete || false;
            state.authedUser.disabledReason = authedUser.disabledReason || '';
            state.authedUser.allowAnalytics = authedUser.allowAnalytics || false;
            state.authedUser.profileCompleteness = authedUser.profileCompleteness || 0;
            state.authedUser.visibilityCompleteness = authedUser.visibilityCompleteness || 0;

            if (state.authedUser.publicUser && authedUser.publicUser) {
                state.authedUser.publicUser.firstName = authedUser.publicUser.firstName || '';
                state.authedUser.publicUser.lastName = authedUser.publicUser.lastName || '';

                if (state.authedUser.publicUser.avatar && authedUser.publicUser.avatar) {
                    state.authedUser.publicUser.avatar.imageUrl = authedUser.publicUser.avatar.imageUrl || '';
                    state.authedUser.publicUser.avatar.cachedImageUrl = authedUser.publicUser.avatar.cachedImageUrl || '';
                }

                if (state.authedUser.publicUser.qrcode && authedUser.publicUser.qrcode) {
                    state.authedUser.publicUser.qrcode.imageUrl = authedUser.publicUser.qrcode.imageUrl || '';
                    state.authedUser.publicUser.qrcode.cachedImageUrl = authedUser.publicUser.qrcode.cachedImageUrl || '';
                }
            }
        },

        // Used to set a specific value within AuthedUser
        setAuthedUserValue(state, payload: { field: keyof AuthedUser; value: any }) {
            if (state.authedUser) {
                state.authedUser[payload.field] = payload.value;
            }
        },

        // Used to set a specific value within PublicUser
        setPublicUserValue(state, payload: { field: keyof PublicUser; value: any }) {
            if (state.authedUser.publicUser) {
                state.authedUser.publicUser[payload.field] = payload.value;
            }
        },

        // For impersonation
        setAdminUserId(state, val: number) {
            state.adminUserId = val;
        },

        //
        // Set the user's admin roles, which can be empty.
        //
        setUserRoles(state, givenRoles: AdminRole[]) {
            // Can be undefined (coming from grpc)
            if (givenRoles) {
                state.roles = [...givenRoles];
            } else {
                state.roles = [] as AdminRole[];
            }
        },

        setCurrentMenu(state, newVal: string) {
            state.currentMenu = newVal;
        },

        //
        // Fake Notification Id of 0 can be used for a single, client-created
        // Page Message.  For example, if the user's private page notices the user
        // has no verified phone or email, put in a message that has no notification
        // db coorelation.
        //
        setPageMessage(state, givenMessage: PageMessage) {
            let variant = 'danger';
            if (givenMessage.variant && givenMessage.variant !== '') {
                variant = givenMessage.variant as string;
            }

            let targetPages = [] as string[];
            if (givenMessage.targetPages && givenMessage.targetPages.length > 0) {
                targetPages = [...givenMessage.targetPages];
            }

            //
            // If existing match is found, remove and re-add to end.
            //
            let idx = 0;
            for (const msg of state.pageMessages) {
                if (msg.notification.event === givenMessage.notification.event) {
                    state.pageMessages.splice(idx, 1);
                    break;
                }

                idx++;
            }

            state.pageMessages.push({
                notification: Utils.deepCopy(givenMessage.notification),
                targetPages: [...targetPages],
                secondsToDisplay: givenMessage.secondsToDisplay,
                variant,
            } as PageMessage);
        },

        clearPageMessage(state, notificationEvent: string) {
            // Find the pageMessage to delete, based on notification event string
            for (let i = 0; i < state.pageMessages.length; i++) {
                if (state.pageMessages[i].notification.event === notificationEvent) {
                    state.pageMessages.splice(i, 1);
                    return;
                }
            }
        },

        clearAllPageMessages(state) {
            state.pageMessages = [] as PageMessage[];
        },

        // Turns dating on or off.  The active flag is the opposite of the
        // datingPaused flag.
        setDatingPaused(state, val) {
            state.authedUser.active = !val;
            if (val) {
                state.authedUser.disabledReason = SharedConstants.INACTIVE_REASON_DATING_PAUSED;
            } else {
                state.authedUser.disabledReason = '';
            }
        },

        setPageNavigation(state, data) {
            if (data && data.page) {
                const currentNavigation = state.pageNavigation.get(data.page) as PageNavigationData[];
                currentNavigation.unshift(data);
            }
        },

        setAdChannel(state, data: AdChannel) {
            state.adChannel.advertisingId = data.advertisingId;
            state.adChannel.downloadSource = data.downloadSource;
            state.adChannel.currentSource = data.currentSource;

            // Put into localStorage
            localStorage.setItem(constants.LOCAL_STORAGE_KEY_AD_CHANNEL, JSON.stringify(data));
        },

        setSegmentAnalytics(state, val) {
            state.segmentAnalytics = val;
        },

        setPostBeep(state, val) {
            state.postBeep = val;
        },
    },

    getters: {
        getFirebaseSigninState: (state) => {
            return state.firebaseSigninState;
        },

        getServerSigninState: (state) => {
            return state.serverSigninState;
        },

        isSignedIn: (state) => {
            return state.firebaseSigninState === SigninStatus.SIGNEDIN && state.serverSigninState === SigninStatus.SIGNEDIN;
        },

        isSignedOut: (state) => {
            return state.firebaseSigninState === SigninStatus.SIGNEDOUT && state.serverSigninState === SigninStatus.SIGNEDOUT;
        },

        isSigninStateKnown: (state) => {
            return state.firebaseSigninState !== SigninStatus.UNKNOWN && state.serverSigninState !== SigninStatus.UNKNOWN;
        },

        getAuthedUser: (state) => {
            return state.authedUser;
        },

        isAuthedUserId: (state, getters) => (userId: number) => {
            return getters.isSignedIn && state.authedUser.userId === userId;
        },

        getAuthedUserId: (state) => {
            return state.authedUser.userId;
        },

        //
        // Handles the less common field gets that are not worthy of their own getter
        //
        getAuthedUserValue: (state) => (field: keyof AuthedUser) => {
            return state.authedUser[field];
        },

        getAvatarUrl: (state) => {
            return state.authedUser.publicUser?.avatar?.imageUrl;
        },

        getCachedAvatarUrl: (state) => {
            return state.authedUser.publicUser?.avatar?.cachedImageUrl;
        },

        getPublicUserValue: (state) => (field: keyof PublicUser) => {
            if (!state.authedUser.publicUser) {
                return undefined;
            }

            return state.authedUser.publicUser[field];
        },

        getAdminUserId: (state) => {
            return state.adminUserId;
        },

        getCurrentMenu: (state) => {
            return state.currentMenu;
        },

        getPageMessages: (state) => {
            return state.pageMessages;
        },

        getPageNavigation:
            (state) =>
            (page: string): PageNavigationData | undefined => {
                const currentPageNavigationList = state.pageNavigation.get(page) as PageNavigationData[];
                if (currentPageNavigationList) {
                    return currentPageNavigationList.shift();
                }

                return undefined;
            },

        getSegmentAnalytics: (state) => {
            return state.segmentAnalytics;
        },

        getAdChannel: (state) => {
            return state.adChannel;
        },

        /////////////////////////////////////////////////////////////////////
        // User's roles, stored in session (not related to company roles)
        //
        // WARNING: In most cases you should never use these directly.  Please
        // see roleutils.ts for functions to use for access verification.  This
        // way we only have one place where it is decided what role has access to
        // what feature.
        /////////////////////////////////////////////////////////////////////
        isServerSuperAdmin: (_, getters) => {
            return getters.isSignedIn && getters.userHasRole(SharedConstants.USER_SERVER_ADMIN);
        },

        isSupportSuperAdmin: (_, getters) => {
            return getters.isSignedIn && getters.userHasRole(SharedConstants.USER_SUPPORT_ADMIN);
        },

        isTestAdmin: (_, getters) => {
            return getters.isSignedIn && getters.userHasRole(SharedConstants.USER_TEST_ADMIN);
        },

        userHasRole: (state) => {
            return (keyword: string) => {
                if (!state.roles || state.roles.length === 0) {
                    return false;
                }

                for (const role of state.roles) {
                    if (role.role === keyword) {
                        return true;
                    }
                }

                return false;
            };
        },

        getPostBeep: (state) => {
            return state.postBeep;
        },

        //
        // NOTE: If there's an webUI emergency, change this to 'true' and quickly redeploy if you
        // want the public part of the site, but don't allow any new users or signins.  Leave
        // in the cookie checking, so devs can investigate issues.
        //
        hideWebsiteSignin: (state) => {
            return true && document.cookie.match(new RegExp('DebugAllowSignin' + '=([^;]+)')) === null;
        },

        //
        // The app doesn't have Email signin yet, so only show if the debug cookie is there.
        //
        showWebsiteEmailSignin: (state) => {
            return document.cookie.match(new RegExp('DebugAllowEmailSignin' + '=([^;]+)')) !== null;
        },
    },
    actions: {},
});
