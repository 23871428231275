
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import CollectTextInput from '@/generic-modals/CollectTextInput.vue';
    import CollectDateInput from '@/generic-modals/CollectDateInput.vue';
    import AddAdminModal from '@/generic-modals/AddAdminModal.vue';
    import {
        PublicUserForAdmin,
        AdminService,
        OverrideLivenessRequest,
        AdminRoleList,
        SharedConstants,
        AdminRole,
        CalendarEntry,
        DatingService,
        GetCalendarResponse,
        Location,
    } from '@bostonventurestudio/lola-api';
    import UserTickets from '@/components/admin/UserTickets.vue';
    import UserMatches from '@/components/admin/UserMatches.vue';
    import UserPriority from '@/components/admin/UserPriority.vue';
    import DatingCalendarRow from '@/components/dating/DatingCalendarRow.vue';
    import UserSwipes from '@/components/admin/UserSwipes.vue';
    import UserNotifications from '@/components/admin/UserNotifications.vue';

    export default mixins(VueConstants).extend({
        name: 'SupportProfileHeader',

        components: {
            'collect-text-input': CollectTextInput,
            'collect-date-input': CollectDateInput,
            'add-admin-modal': AddAdminModal,
            'user-tickets': UserTickets,
            'user-priority': UserPriority,
            'dating-row': DatingCalendarRow,
            'user-matches': UserMatches,
            'user-swipes': UserSwipes,
            'user-notifications': UserNotifications,
        },

        props: {
            viaSupportPage: {
                type: Boolean,
                required: false,
                default: false,
            },

            userTitle: {
                type: String,
                required: false,
                default: '',
            },

            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUserForAdmin>,
        },

        data() {
            return {
                historyItems: [] as any[],
                suspendUserModal: false,
                reactivateSuspendedUserModal: false,
                banUserModal: false,
                reactivateBannedUserModal: false,
                changeBirthdayModal: false,
                birthday: '2002-01-01',
                showPreferences: false,
                showUserCalendar: false,
                showTooltip: false,
                changeUserRoleModal: false,
                adminsFetched: false,
                submitting: false,
                currentAdmins: [] as AdminRoleList[],
                selectedCheckboxes: [] as string[][],
                matchesEnabled: false,
                ticketsReportedEnabled: !!this.user?.ticketsReported,
                ticketsReceivedEnabled: !!this.user?.ticketsReceived,
                email: '',
                phoneNumber: '',
                contactInfoShown: false,
                swipeCount: 0,
                swipesEnabled: false,
                notificationCount: 0,
                notificationsEnabled: false,
                matchesCount: 0,
                options: [
                    { text: 'Server Admin', value: SharedConstants.USER_SERVER_ADMIN },
                    { text: 'Support Admin', value: SharedConstants.USER_SUPPORT_ADMIN },
                    { text: 'Test Admin', value: SharedConstants.USER_TEST_ADMIN },
                ],
                dates: [] as CalendarEntry[],
                waitlistReasons: {
                    [SharedConstants.USER_WAITLIST_REASON_REVIEW_PENDING]: 'Pending Review',
                    [SharedConstants.USER_WAITLIST_REASON_OPEN_TICKETS]: 'Open Tickets',
                    [SharedConstants.USER_WAITLIST_REASON_NON_COMPLIANCE]: 'Non Compliance / Low Priority',
                    [SharedConstants.USER_WAITLIST_REASON_LOW_PROSPECT_POOL]: 'Insufficient Prospect Pool',
                    [SharedConstants.USER_WAITLIST_REASON_NO_ACTIVE_MARKET]: 'No Active Market',
                },
            };
        },

        computed: {
            livenessCompleteText(): string {
                if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'livenessCompleteText');
                    return '';
                }

                if (this.user.livenessComplete) {
                    return 'Complete';
                }

                return 'Incomplete';
            },

            showServerAdminTool(): boolean {
                return RoleUtils.IsServerSuperAdmin();
            },

            hasReportedTickets(): boolean {
                return !!this.user?.ticketsReported;
            },
            hasReceivedTickets(): boolean {
                return !!this.user?.ticketsReceived;
            },
            hasMatches(): boolean {
                return !!this.matchesCount;
            },
            remianingSwipes(): number {
                return SharedConstants.DATING_SWIPE_MAX_LIMIT - (this.user?.swipeCount ?? 0);
            },

            hasNotifications(): boolean {
                return this.notificationCount > 0;
            },
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: PublicUserForAdmin, oldVal: PublicUserForAdmin) {
                    if (newVal && newVal !== oldVal) {
                        this.resetContactInfo();
                        this.fetchCalender();
                    }
                },
            },
        },

        methods: {
            zeroCount(count: number | undefined): number {
                if (!count) {
                    return 0;
                }

                return count;
            },
            getLastLogin(): string {
                if (!this.user?.lastLogin) return '';

                const utcDate = new Date(this.user.lastLogin);

                // Calculate the offset in milliseconds for your timezone
                const timezoneOffset = utcDate.getTimezoneOffset() * 60000;
                const localDate = new Date(utcDate.getTime() + timezoneOffset);
                const options: Intl.DateTimeFormatOptions = {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false, // Use true for AM/PM format, false for 24-hour format
                };
                return localDate.toLocaleDateString('en-US', options) + ' local time';
            },

            getRoles(): string {
                return this.user?.roles ? this.user?.roles.map((r) => r.role).join(', ') : 'None';
            },
            redirectToMap(location: Location | undefined) {
                if (!this.user || !this.user.publicUser || !location || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'redirectToMap');
                    return;
                }
                if (!location?.latitude || !location?.longitude) {
                    console.error('Missing location coordinates');
                    return;
                }

                const latitude = location.latitude;
                const longitude = location.longitude;

                const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${latitude},${-Math.abs(longitude)}`;

                window.open(googleMapsUrl, '_blank');
            },

            async suspendUser(note: string) {
                if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'suspendUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(AdminService.suspendUser, { userId: this.user.publicUser.userId, adminNote: note });
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async reactivateSuspendedUser(note: string) {
                if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'reactivateSuspendedUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(AdminService.reactivateSuspendedUser, { userId: this.user.publicUser.userId, adminNote: note });
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async banUser(note: string) {
                if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'banUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(AdminService.banUser, { userId: this.user.publicUser.userId, adminNote: note });
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async reactivateBannedUser(note: string) {
                if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'reactivateBannedUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(AdminService.reactivateBannedUser, { userId: this.user.publicUser.userId, adminNote: note });
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async setLivenessComplete(complete: boolean) {
                if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'setLivenessComplete');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(AdminService.overrideLiveness, {
                        userId: this.user.publicUser.userId,
                        verified: complete,
                    } as OverrideLivenessRequest);
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async finishOnboarding() {
                try {
                    await ApiUtils.apiWrapper(AdminService.adminSetUserOnboardingComplete, this.user.publicUser?.userId);

                    this.$emit('reload-user');
                    this.$store.commit('clearPageMessage', constants.FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async removeFromWaitlist() {
                try {
                    await ApiUtils.apiWrapper(AdminService.adminLetUserOffWaitlist, this.user.publicUser?.userId);
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async changeBirthday(date: string) {
                if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                    logInvalidParams(this.$options.name, 'changeBirthday');
                    return;
                }
                try {
                    const selectedDate = new Date(date);
                    const offsetMinutes = selectedDate.getTimezoneOffset();
                    const offsetMs = offsetMinutes * 60 * 1000;
                    const adjustedDate = new Date(selectedDate.getTime() - offsetMs);
                    const formattedDate = adjustedDate.toISOString().split('T')[0] + 'T00:00:00Z';
                    await ApiUtils.apiWrapper(AdminService.updateUserDateOfBirth, {
                        userId: this.user.publicUser.userId,
                        dateOfBirth: formattedDate,
                    });
                    this.$emit('reload-user');
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchCalender() {
                try {
                    if (!this.user || !this.user.publicUser || !this.user.publicUser.userId) {
                        logInvalidParams(this.$options.name, 'fetchCalender');
                        return;
                    }
                    const ret = (await ApiUtils.apiWrapper(DatingService.getCalendar, {
                        userId: this.user.publicUser.userId,
                    })) as GetCalendarResponse;
                    if (ret && ret.entries && ret.entries.length > 0) {
                        Vue.set(this, 'dates', ret.entries);
                    } else {
                        this.dates = [] as CalendarEntry[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Below is code for changing user roles option in the user actions dropdown
            //

            // Cancel button clicked, so reset checkboxes.
            cancel() {
                this.setCheckboxes();
            },

            //
            // Set the selectedCheckboxes array for each admin.  This string array
            // is used by b-form-checkbox-group as the v-model.
            //
            setCheckboxes() {
                for (let idx = 0; idx < this.currentAdmins.length; idx++) {
                    const roles = this.currentAdmins[idx].roles as AdminRole[];

                    Vue.set(this.selectedCheckboxes, idx, [] as string[]);

                    let num = 0;
                    for (const role of roles) {
                        this.selectedCheckboxes[idx][num] = role.role as string;
                        num++;
                    }
                }
            },

            //
            // Poor name, should be updateAdmin but this is the function that is called in the AddAdminModal which
            // calls it this and does so in multiple spot so it is kept as is.
            //
            adminAdded(roles: AdminRole[] = [] as AdminRole[]) {
                this.changeUserRoleModal = false;

                // Update the user roles like this because the user is passed in as a prop so it has to be updated here
                this.user.roles = roles;
            },
            toggleTickets(reportedOrReceived: string) {
                if (reportedOrReceived === 'reported') {
                    this.ticketsReportedEnabled = !this.ticketsReportedEnabled;
                } else {
                    this.ticketsReceivedEnabled = !this.ticketsReceivedEnabled;
                }
            },
            toggleMatches() {
                this.matchesEnabled = !this.matchesEnabled;
            },
            toggleSwipes() {
                this.swipesEnabled = !this.swipesEnabled;
            },
            handleSwipes(count: number) {
                this.swipeCount = count;
            },
            togglePreferences() {
                this.showPreferences = !this.showPreferences;
            },
            toggleUserCalendar() {
                this.showUserCalendar = !this.showUserCalendar;
            },

            handleMatchesCount(count: number) {
                this.matchesCount = count;
            },
            toggleNotifications() {
                this.notificationsEnabled = !this.notificationsEnabled;
            },

            async getContactInfo() {
                // popup confirmation
                if (!this.contactInfoShown) {
                    const ret = await this.$bvModal.msgBoxConfirm('Are you sure you want to access user contact info? This action will be logged.', {
                        title: 'Warning: Sensitive Information',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: 'Yes',
                        cancelTitle: 'No',
                        cancelVariant: 'secondary',
                    });
                    if (!ret) {
                        return;
                    }
                }

                // if already fetched, don't fetch again
                if (this.email || this.phoneNumber) {
                    this.contactInfoShown = !this.contactInfoShown;
                    return;
                }

                const ret = await AdminService.getUserContactInfo({ userId: this.user.publicUser?.userId });

                this.email = ret.email || '';
                this.phoneNumber = ret.phoneNumber || '';
                this.contactInfoShown = true;
            },

            resetContactInfo() {
                this.contactInfoShown = false;
                this.email = '';
                this.phoneNumber = '';
            },

            setNotificationCount(count: number) {
                this.notificationCount = count;
            },
            getWaitlistReasonText(): string {
                if (!this.user || !this.user.waitlistReason) {
                    return '';
                }
                return this.waitlistReasons[this.user?.waitlistReason as keyof typeof this.waitlistReasons];
            },
        },
    });
