
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { mapState } from 'vuex';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import { Notification } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'NotificationPhoneFlagged',

        components: {},

        props: {
            notification: {
                type: Object,
                required: true,
            } as PropOptions<Notification>,
        },

        data() {
            return {};
        },

        computed: {
            onSettingsPage(): boolean {
                return this.$router.currentRoute.name === constants.ROUTE_USER_SETTINGS;
            },
        },

        methods: {
            // If not on the setting page, go there.
            gotoSettings() {
                if (this.$router.currentRoute.name !== constants.ROUTE_USER_SETTINGS) {
                    this.$router.push({
                        name: constants.ROUTE_USER_SETTINGS,
                        params: { userId: this.$store.getters.getAuthedUserId },
                    });
                }
            },
        },
    });
