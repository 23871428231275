
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as analytics from '@/tsfiles/analytics';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import MultiRangeSlider from 'multi-range-slider-vue';
    import RangeSlider from 'vue-range-slider';
    import 'vue-range-slider/dist/vue-range-slider.css';
    import { UserService, AuthedUser, TargetingPreferences, TargetingPreferenceDefinitions } from '@bostonventurestudio/lola-api';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    Vue.use(MultiRangeSlider);
    Vue.use(RangeSlider);

    export default mixins(VueConstants).extend({
        name: 'UserTargetingSettings',

        components: {
            MultiRangeSlider,
            RangeSlider,
        },

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<AuthedUser>,
        },

        data() {
            return {
                localTargeting: {} as TargetingPreferences,
                targetingDefinitions: undefined as TargetingPreferenceDefinitions | undefined,
                submitting: false,
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: AuthedUser, oldVal: AuthedUser) {
                    if (newVal && newVal !== oldVal) {
                        this.localTargeting = Utils.deepCopy(newVal.targetingPreferences);

                        if (!this.localTargeting.genders) {
                            Vue.set(this.localTargeting, 'genders', [] as string[]);
                        }

                        //
                        // We need to set the default age min/max range to start, from the targeting
                        // definitions, only if nothing is available already in localTargeting.
                        //
                        if (!this.localTargeting.minAge || !this.localTargeting.maxAge) {
                            // Default the age values if the user has no range set or doesn't have an age set.
                            let minAge = this.targetingDefinitions?.age?.defaultStartingMin ?? 28;
                            let maxAge = this.targetingDefinitions?.age?.defaultStartingMax ?? 35;

                            // Base minAge and maxAge on current user's age, if available.
                            // This will override the default values above.
                            if (newVal.publicUser?.profile?.age) {
                                const rangeBuffer = this.targetingDefinitions?.age?.rangeBuffer ?? 5;
                                minAge = newVal.publicUser.profile.age - rangeBuffer / 2;
                                maxAge = newVal.publicUser.profile.age + rangeBuffer / 2;

                                if (minAge < 18) {
                                    minAge = 18;
                                }
                                if (maxAge > 65) {
                                    maxAge = 65;
                                }
                            }

                            Vue.set(this.localTargeting, 'minAge', minAge);
                            Vue.set(this.localTargeting, 'maxAge', maxAge);

                            // WTF? Using Vue.set doesn't seem to always work, so we just set directly
                            // via nextTick, which seems to help.
                            Vue.nextTick(() => {
                                this.localTargeting.maxAge = maxAge;
                            });
                        }

                        // If either minAge or Max age is not set in localTargeting, set both
                        if (!this.localTargeting.minHeightInInches || !this.localTargeting.maxHeightInInches) {
                            let minHeightInInches = this.targetingDefinitions?.height?.defaultStartingMin ?? 60;
                            let maxHeightInInches = this.targetingDefinitions?.height?.defaultStartingMax ?? 72;

                            Vue.set(this.localTargeting, 'minHeightInInches', minHeightInInches);
                            Vue.set(this.localTargeting, 'maxHeightInInches', maxHeightInInches);

                            // WTF? Using Vue.set doesn't seem to always work, so we just set directly
                            // via nextTick, which seems to help.
                            Vue.nextTick(() => {
                                this.localTargeting.maxHeightInInches = maxHeightInInches;
                            });
                        }

                        if (!this.localTargeting.radiusInMiles) {
                            Vue.set(this.localTargeting, 'radiusInMiles', this.targetingDefinitions?.distance?.defaultStartingValue ?? 4);
                        }
                    }
                },
            },
        },

        mounted() {
            this.fetchTargetingDefinitions();
        },

        computed: {
            isDirty(): boolean {
                const hasChanges =
                    !Utils.deepEqual(this.user.targetingPreferences?.genders, this.localTargeting.genders) ||
                    !Utils.deepEqual(this.user.targetingPreferences?.minAge, this.localTargeting.minAge) ||
                    !Utils.deepEqual(this.user.targetingPreferences?.maxAge, this.localTargeting.maxAge) ||
                    !Utils.deepEqual(this.user.targetingPreferences?.minHeightInInches, this.localTargeting.minHeightInInches) ||
                    !Utils.deepEqual(this.user.targetingPreferences?.maxHeightInInches, this.localTargeting.maxHeightInInches) ||
                    !Utils.deepEqual(this.user.targetingPreferences?.radiusInMiles, this.localTargeting.radiusInMiles);

                this.$emit('set-settings-dirty', hasChanges);
                return hasChanges;
            },

            minimumGenderSelected(): boolean {
                if (
                    !this.localTargeting.genders ||
                    !this.targetingDefinitions ||
                    !this.targetingDefinitions.gender ||
                    !this.targetingDefinitions.gender.optionList ||
                    !this.targetingDefinitions.gender.minLength
                ) {
                    logInvalidParams(this.$options.name, 'minimumGenderSelected');
                    return false;
                }

                let count = 0;
                for (const val of this.targetingDefinitions.gender.optionList) {
                    if (this.localTargeting.genders.includes(val.key as string)) {
                        count++;
                    }
                }

                // If no max length give, use the list length
                let maxLength = this.targetingDefinitions.gender.optionList.length;
                if (this.targetingDefinitions.gender.maxLength) {
                    maxLength = this.targetingDefinitions.gender.maxLength;
                }

                return count >= this.targetingDefinitions.gender.minLength && count <= maxLength;
            },
        },

        methods: {
            inputsValid(): boolean {
                return this.isDirty && this.minimumGenderSelected;
            },

            updateAge(e: any) {
                this.localTargeting.minAge = e.minValue;
                this.localTargeting.maxAge = e.maxValue;
            },

            updateHeight(e: any) {
                this.localTargeting.minHeightInInches = e.minValue;
                this.localTargeting.maxHeightInInches = e.maxValue;
            },

            heightDisplayText(): string {
                const min = this.localTargeting.minHeightInInches as number;
                const minFeet = Math.floor(min / 12);
                const minInches = min % 12;

                const max = this.localTargeting.maxHeightInInches as number;
                const maxFeet = Math.floor(max / 12);
                const maxInches = max % 12;

                return minFeet + '&#39; ' + minInches + '&#34; &mdash; ' + maxFeet + '&#39; ' + maxInches + '&#34;';
            },

            isGenderChecked(key: string | undefined): boolean {
                if (!key || key === '') {
                    logInvalidParams(this.$options.name, 'isGenderChecked');
                    return false;
                }

                if (!this.localTargeting || !this.localTargeting.genders) {
                    return false; // Nothing selected by the user yet
                }

                return this.localTargeting.genders.includes(key);
            },

            genderSelectionChanged(key: string | undefined) {
                if (
                    !key ||
                    !this.localTargeting.genders ||
                    !this.targetingDefinitions ||
                    !this.targetingDefinitions.gender ||
                    !this.targetingDefinitions.gender.optionList
                ) {
                    logInvalidParams(this.$options.name, 'genderSelectionChanged');
                    return;
                }

                // Recreate the array of gender keys every time.
                const newList = [] as string[];
                for (const val of this.targetingDefinitions.gender.optionList) {
                    if (this.localTargeting.genders.includes(val.key as string) && val.key !== key) {
                        newList.push(val.key as string);
                    } else if (val.key === key && !this.localTargeting.genders.includes(val.key as string)) {
                        newList.push(key as string);
                    }
                }

                Vue.set(this.localTargeting, 'genders', newList);
            },

            //
            // Fetch the targeting definition which help the client determine titles
            // and acceptable value ranges.
            //
            async fetchTargetingDefinitions() {
                try {
                    this.targetingDefinitions = await ApiUtils.apiWrapper(UserService.getTargetingPreferenceDefinitions);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async handleSubmit(event: Event) {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    // Dynamically set mask based on what's different
                    this.localTargeting.updateMask = {
                        paths: this.createUpdateMask(),
                    };

                    const updatedTargeting = await ApiUtils.apiWrapper(UserService.saveTargetingPreferences, this.localTargeting);

                    if (this.user.userId) {
                        analytics.logAppInteraction(analytics.ANALYTICS_ACTION_UPDATE_TARGETING, this.user.userId.toString());
                    }

                    // Tell the parent about the updates, since we need the new values to
                    // compare against, store updates, etc.
                    this.$emit('targeting-updated', updatedTargeting);

                    this.submitting = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Create the updateMask for the user object that will be sent to the server.  These
            // are the fields that will get updated in the database.
            //
            createUpdateMask(): string[] {
                const mask = [] as string[];
                if (!this.user.targetingPreferences || !this.localTargeting) {
                    logInvalidParams(this.$options.name, 'createUpdateMask');
                    return mask;
                }

                ['genders', 'minAge', 'maxAge', 'minHeightInInches', 'maxHeightInInches', 'radiusInMiles'].map((item) => {
                    if (
                        this.user.targetingPreferences &&
                        !Utils.deepEqual(
                            this.user.targetingPreferences[item as keyof TargetingPreferences],
                            this.localTargeting[item as keyof TargetingPreferences],
                        )
                    ) {
                        mask.push(item);
                    }
                });

                return mask;
            },
        },
    });
