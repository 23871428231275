import { render, staticRenderFns } from "./UserTickets.vue?vue&type=template&id=9db6ad4c&scoped=true&"
import script from "./UserTickets.vue?vue&type=script&lang=ts&"
export * from "./UserTickets.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9db6ad4c",
  null
  
)

export default component.exports