
    import mixins from 'vue-typed-mixins';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import { Utils } from '@/tsfiles/utils';
    import VerifyPhone from '@/generic-modals/VerifyPhone.vue';
    import { UserService, SharedConstants } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'NotificationPhoneFlagged',

        components: {
            'verify-phone': VerifyPhone,
        },

        props: {},

        data() {
            return {
                showPhoneVerifyModal: false,
            };
        },

        watch: {},

        computed: {
            getFormattedPhoneForDisplay(): string {
                return Utils.FormatPhoneNumberForDisplay(this.$store.getters.getAuthedUserValue('phone'), undefined);
            },
        },

        methods: {},
    });
