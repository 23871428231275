
    import Vue from 'vue';
    import ServerAdmin from '@/components/admin/ServerAdmin.vue';

    export default Vue.extend({
        name: 'ServerAdmin',

        components: {
            'server-admin': ServerAdmin,
        },

        props: {},

        data() {
            return {};
        },

        computed: {},

        methods: {},
    });
