<template>
    <div
        id="carouselExampleIndicators"
        class="carousel slide"
        data-ride="carousel">
        <ol class="carousel-indicators mb-0">
            <li
                v-for="(image, index) in images"
                :data-target="'#carouselExampleIndicators'"
                :data-slide-to="index"
                :class="{ active: currentIndex === index, dot: true }"
                @click="goToSlide(index)"
                :key="`indicator-${index}`"></li>
        </ol>
        <div class="carousel-inner">
            <div
                v-for="(image, index) in images"
                :class="['carousel-item', { active: currentIndex === index }, 'image-container']"
                :key="`slide-${index}`">
                <img
                    :src="image"
                    class="d-block"
                    :alt="`Slide ${index + 1}`" />
            </div>
        </div>
        <a
            href="#carouselExampleIndicators"
            role="button"
            @click.prevent="goToPrevious">
            <div class="carousel-control-prev my-auto ml-2 p-3">
                <i class="fa-regular fa-chevron-left"></i>
                <span class="sr-only">Previous</span>
            </div>
        </a>
        <a
            href="#carouselExampleIndicators"
            role="button"
            @click.prevent="goToNext">
            <div class="carousel-control-next my-auto mr-2 p-3">
                <i class="fa-regular fa-chevron-right"></i>
                <span class="sr-only">Next</span>
            </div>
        </a>
    </div>
</template>

<script>
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';

    export default mixins(VueConstants).extend({
        name: 'ImageSlideshow',
        props: {
            images: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                currentIndex: 0,
            };
        },
        methods: {
            goToPrevious() {
                const isFirstSlide = this.currentIndex === 0;
                const newIndex = isFirstSlide ? this.images.length - 1 : this.currentIndex - 1;
                this.currentIndex = newIndex;
            },
            goToNext() {
                const isLastSlide = this.currentIndex === this.images.length - 1;
                const newIndex = isLastSlide ? 0 : this.currentIndex + 1;
                this.currentIndex = newIndex;
            },
            goToSlide(slideIndex) {
                this.currentIndex = slideIndex;
            },
        },
    });
</script>

<style scoped>
    .carousel-control-prev,
    .carousel-control-next {
        height: 24px;
        width: 24px;
        border-radius: 100px;
        background: var(--Accent-50, rgba(147, 150, 199, 0.5));
    }

    .image-container {
        width: 100%;
        position: relative;
        overflow: hidden;
    }

    .image-container::before {
        content: '';
        display: block;
        padding-top: 100%; /* This creates a 1:1 aspect ratio */
    }

    .image-container img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    .dot {
        width: 10px; /* Adjust size as needed */
        height: 10px; /* Adjust size as needed */
        border-radius: 50%;
        display: inline-block;
        margin: 0 5px; /* Adds some space between dots */
    }
    .carousel-indicators li {
        background-color: rgb(147, 150, 199, 0.5);
    }
    .carousel-indicators .active {
        background-color: rgb(147, 150, 199);
    }
</style>
