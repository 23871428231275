
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import UserProfile from '@/components/user/UserProfile.vue';
    import UserProspectContainer from '@/components/user/UserProspectContainer.vue';
    import { DatingService, Prospect, GetProspectsResponse, SwipeRequest, SharedConstants } from '@bostonventurestudio/lola-api';
    import { RoleUtils } from '@/tsfiles/roleutils';

    export default Vue.extend({
        name: 'UserProspects',

        components: {
            'user-profile': UserProfile,
            'user-prospect-container': UserProspectContainer,
        },

        props: {},

        data() {
            return {
                fetchComplete: false,
                currentUserIdIdx: 0,
                userIdList: [] as Prospect[],
                userIdToDisplay: undefined as number | undefined,
                allowUndo: false,
                relaxPreferences: false,
                showRelaxPreferencesMessage: false,
                showSwipeLimitExhaustedMessage: false,
                numUndo: 0,
                reviewMode: false,
                noSwipesExchanged: true,
            };
        },

        watch: {},

        mounted() {
            this.fetchUserIdList(false);
        },

        computed: {
            hasAccess(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    (!this.gettingProspectsForAnotherUser || (this.gettingProspectsForAnotherUser && RoleUtils.CanSupportUsers()))
                );
            },
            gettingProspectsForAnotherUser(): boolean {
                return this.$route.params.userId != this.$store.getters.getAuthedUserId;
            },
        },

        methods: {
            async fetchUserIdList(relaxed: boolean) {
                const userId = this.getUserId();
                let query: { [key: string]: any } = {};
                if (userId != '') query.userId = userId;
                this.relaxPreferences = !!relaxed;
                if (relaxed) query.relaxPreferences = relaxed;
                this.showRelaxPreferencesMessage = false;
                if (this.noSwipesExchanged) query.noSwipesExchanged = this.noSwipesExchanged;

                try {
                    const ret = (await ApiUtils.apiWrapper(DatingService.getProspects, query)) as GetProspectsResponse;
                    if (ret && ret.prospects) {
                        this.userIdList = ret.prospects;
                        this.userIdToDisplay = this.userIdList[this.currentUserIdIdx].userId as number;
                    } else {
                        this.userIdList = [] as Prospect[];
                        this.userIdToDisplay = 0;
                        this.showRelaxPreferencesMessage = true;
                    }

                    this.fetchComplete = true;
                } catch (error: any) {
                    this.fetchComplete = true;
                    if (error?.response?.data?.error == SharedConstants.WARNING_USER_SWIPE_LIMIT_EXHAUSTED) {
                        this.userIdList = [] as Prospect[];
                        this.userIdToDisplay = 0;
                        this.showSwipeLimitExhaustedMessage = true;
                        return;
                    }
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchReviewableUserIdList() {
                try {
                    const ret = (await ApiUtils.apiWrapper(DatingService.getProspectsForReview)) as GetProspectsResponse;
                    if (ret && ret.prospects) {
                        this.currentUserIdIdx = 0; // Needed to reset this
                        this.userIdList = ret.prospects;
                        this.userIdToDisplay = this.userIdList[this.currentUserIdIdx].userId as number;
                    } else {
                        this.userIdList = [] as Prospect[];
                        this.userIdToDisplay = 0;
                    }

                    this.fetchComplete = true;
                    this.reviewMode = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async swipe(userId: number, affinity: boolean, note: string) {
                if (userId === 0) {
                    logInvalidParams(this.$options.name, 'swipe');
                    return;
                }

                try {
                    const res = await ApiUtils.apiWrapper(DatingService.swipe, {
                        affinity,
                        userId,
                        note,
                    } as SwipeRequest);

                    // If the swipe results in a match, go to the date page.  This
                    // should only happen on likes.
                    if (res && res.match && res.match.matchId) {
                        if (!affinity) {
                            logInvalidResponse(this.$options.name, 'swipe', res);
                            return;
                        }

                        this.$router.push({
                            name: constants.ROUTE_USER_DATE,
                            query: { matchId: res.match.matchId.toString() },
                        });
                    }

                    if (this.currentUserIdIdx >= this.userIdList.length) {
                        this.fetchUserIdList(this.relaxPreferences);
                    }

                    this.allowUndo = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async updateSwipe(userId: number, affinity: boolean, note: string) {
                if (userId === 0) {
                    logInvalidParams(this.$options.name, 'redo swipe');
                    return;
                }

                try {
                    const res = await ApiUtils.apiWrapper(DatingService.updateSwipe, {
                        affinity,
                        userId,
                        note,
                    } as SwipeRequest);

                    // If the swipe results in a match, go to the date page.  This
                    // should only happen on likes.
                    if (res && res.match && res.match.matchId) {
                        if (!affinity) {
                            logInvalidResponse(this.$options.name, 'updateSwipe', res);
                            return;
                        }

                        this.$router.push({
                            name: constants.ROUTE_USER_DATE,
                            query: { matchId: res.match.matchId.toString() },
                        });
                    }

                    if (this.currentUserIdIdx >= this.userIdList.length) {
                        this.fetchReviewableUserIdList();
                    }

                    this.allowUndo = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            likeUser(note: string) {
                if (this.userIdToDisplay === undefined || this.userIdToDisplay === 0) {
                    logInvalidParams(this.$options.name, 'likeUser');
                    return;
                }

                this.swipe(this.userIdToDisplay, true, note || '');
                this.showNext();
            },

            unlikeUser(note: string) {
                if (this.userIdToDisplay === undefined || this.userIdToDisplay === 0) {
                    logInvalidParams(this.$options.name, 'likeUser');
                    return;
                }

                this.swipe(this.userIdToDisplay, false, note || '');
                this.showNext();
            },

            redoLikeUser(note: string) {
                if (this.userIdToDisplay === undefined || this.userIdToDisplay === 0) {
                    logInvalidParams(this.$options.name, 'redoLikeUser');
                    return;
                }

                if (this.numUndo > 0) {
                    this.swipe(this.userIdToDisplay, true, note || '');
                    this.numUndo -= 1;
                } else {
                    this.updateSwipe(this.userIdToDisplay, true, note || '');
                }
                this.showNext();
            },

            redoUnlikeUser(note: string) {
                if (this.userIdToDisplay === undefined || this.userIdToDisplay === 0) {
                    logInvalidParams(this.$options.name, 'redoUnlikeUser');
                    return;
                }

                if (this.numUndo > 0) {
                    this.swipe(this.userIdToDisplay, false, note || '');
                    this.numUndo -= 1;
                } else {
                    this.updateSwipe(this.userIdToDisplay, false, note || '');
                }
                this.showNext();
            },

            async gotoPreviousUser() {
                try {
                    await ApiUtils.apiWrapper(DatingService.undoSwipe);
                    this.showPrevious();
                    this.allowUndo = false;
                    this.numUndo += 1;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            // Force a match with this user.
            async forceMatch() {
                if (this.currentUserIdIdx === -1) {
                    logInvalidParams(this.$options.name, 'forceMatch');
                    return;
                }

                const user = this.userIdList[this.currentUserIdIdx];

                try {
                    const ret = await ApiUtils.apiWrapper(DatingService.forceMatch, {
                        prospectUserId: user.userId,
                    });

                    if (ret && ret.match) {
                        const matchId = ret.match.matchId ? ret.match.matchId.toString() : '0';
                        this.$router.push({
                            name: constants.ROUTE_USER_DATE,
                            query: { matchId },
                        });
                    } else {
                        alert('Unable to match.  The user might already be matched, you have no availability, or the force match failed.');
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            getUserId(): string {
                return this.$route.params.userId == this.$store.getters.getAuthedUserId ? '' : this.$route.params.userId;
            },
            showNext() {
                this.currentUserIdIdx += 1;
                if (this.currentUserIdIdx >= this.userIdList.length) {
                    this.userIdToDisplay = 0;
                } else {
                    this.userIdToDisplay = this.userIdList[this.currentUserIdIdx].userId as number;
                }
            },
            showPrevious() {
                this.currentUserIdIdx -= 1;
                if (this.currentUserIdIdx < 0) {
                    this.currentUserIdIdx = 0;
                    this.userIdToDisplay = 0;
                } else {
                    this.userIdToDisplay = this.userIdList[this.currentUserIdIdx].userId as number;
                }
            },
            isFirst(): boolean {
                return this.currentUserIdIdx == 0;
            },
            isLast(): boolean {
                return this.currentUserIdIdx == this.userIdList.length - 1;
            },
            exitReviewMode() {
                this.reviewMode = false;
                this.userIdToDisplay = 0;
            },
        },
    });
