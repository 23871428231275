
    import Vue from 'vue';
    import * as constants from '@/tsfiles/constants';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import Marketing from '@/components/Marketing.vue';

    export default Vue.extend({
        name: 'MarketingHome',

        components: {
            'marketing-ui': Marketing,
        },

        props: {},

        data() {
            return {};
        },

        //
        // If signed in go to the default page.  If support admin, go to the admin console.
        //
        mounted() {
            if (this.$store.getters.isSignedIn) {
                this.$router.replace({
                    name: constants.ROUTE_USER_CALENDAR,
                    params: { userId: this.$store.getters.getAuthedUserId },
                });
            }
        },

        computed: {},

        methods: {},
    });
