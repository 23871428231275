
    import { Vue } from 'vue-property-decorator';
    import * as constants from '@/tsfiles/constants';
    import { logAccessDenied, logInvalidParams } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import Smarty from '@/components/Smarty.vue';
    import { SharedConstants, PublicUser, AdminService, AdminRoleList, AdminRole, PublicUserForAdmin } from '@bostonventurestudio/lola-api';
    import { PropType } from 'vue';

    export default Vue.extend({
        name: 'AddAdminModal',

        components: {
            'ui-smarty': Smarty,
        },

        props: {
            incomingUser: {
                type: Object as PropType<PublicUserForAdmin>,
                required: false,
                default: {} as PublicUserForAdmin | undefined,
            },
        },

        data() {
            return {
                selectedUser: this.incomingUser.publicUser as PublicUser | undefined,
                submitting: false,
                selectedCheckboxes: [] as string[],
                smartyHack: '', // Resets input once user makes selection'
                currentAdmins: [] as AdminRoleList[],
                options: [
                    { text: 'Server Admin', value: SharedConstants.USER_SERVER_ADMIN },
                    { text: 'Support Admin', value: SharedConstants.USER_SUPPORT_ADMIN },
                    { text: 'Test Admin', value: SharedConstants.USER_TEST_ADMIN },
                ],
            };
        },

        mounted() {
            // Just double-check that the current user has access
            if (!RoleUtils.IsServerSuperAdmin()) {
                logAccessDenied(this.$options.name, 'mounted');
                this.$router.replace({ name: constants.ROUTE_MARKETING });
            }

            this.showSelectedCheckboxes();
        },

        computed: {
            //
            // Should the submit button be disabled?  It should if all required fields are
            // not valid.
            //
            disableSubmit(): boolean {
                return !this.selectedUser || this.selectedCheckboxes.length === 0;
            },

            getUserDisplayText(): string {
                if (!this.selectedUser) {
                    logInvalidParams(this.$options.name, 'getUserDisplayText');
                    return '';
                }

                let ret = this.selectedUser.firstName;
                if (this.selectedUser.lastName) {
                    ret += ' ' + this.selectedUser.lastName;
                }

                return ret + ' (@' + this.selectedUser.userId + ')';
            },
        },

        methods: {
            smartySelection(data: any) {
                const user = data.newVal;

                // Only want to set to undefined if the user is not valid / has not been passed in yet
                if (!this.selectedUser?.userId) {
                    if (!user || user.userId == '') {
                        this.selectedUser = undefined as PublicUser | undefined;
                    } else {
                        this.selectedUser = user as PublicUser;
                    }

                    // Trick smarty into erasing the input
                    this.smartyHack = ' ';
                    this.$nextTick(() => {
                        this.smartyHack = '';
                    });
                }
            },

            async submit() {
                if (this.disableSubmit || !this.selectedUser) {
                    logInvalidParams(this.$options.name, 'submit');
                    return;
                }

                try {
                    const updatedRoles = await ApiUtils.apiWrapper(AdminService.updateAdminRoles, {
                        userId: this.selectedUser.userId,
                        selectedRoles: this.selectedCheckboxes,
                    });

                    //
                    // Should be update-admin but is also used as add-admin in SuperAdminRoles so I am keeping it as is.
                    // Am now passing in optional roles to the parent component so that it can update the roles from there.
                    // This way the function can be used for both adding and updating roles.
                    //
                    this.$emit('admin-added', updatedRoles.roles);
                } catch (error: any) {
                    Utils.CommonErrorHandler(error);
                }
            },

            // function for initializing the selected check boxes to be filled if user coming in is not undefined
            showSelectedCheckboxes() {
                if (this.incomingUser.roles) {
                    for (var i = 0; i < this.incomingUser.roles.length; i++) {
                        if (this.incomingUser.roles[i].role) {
                            this.selectedCheckboxes.push(this.incomingUser.roles[i].role as string);
                        }
                    }
                }
            },
        },
    });
