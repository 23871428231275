
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { DateTime as LuxonDateTime } from 'luxon';
    import UserProfile from '@/components/user/UserProfile.vue';
    import UserHistory from '@/components/admin/UserHistory.vue';
    import UserTickets from '@/components/admin/UserTickets.vue';
    import SupportProfileHeader from '@/components/admin/SupportProfileHeader.vue';
    import RichText from '@/components/RichText.vue';
    import LivenessSnapshots from '@/components/admin/LivenessSnapshots.vue';
    import OffensiveMediaModal from '@/components/admin/OffensiveMediaModal.vue';
    import {
        AdminService,
        MediaService,
        Media,
        Ticket,
        TicketNote,
        PublicUserForAdmin,
        PublicUser,
        SharedConstants,
    } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'Ticket',

        components: {
            'user-profile': UserProfile,
            'user-history': UserHistory,
            'user-tickets': UserTickets,
            'support-profile-header': SupportProfileHeader,
            'rich-text': RichText,
            'liveness-snapshots': LivenessSnapshots,
            'offensive-media': OffensiveMediaModal,
        },

        props: {},

        data() {
            return {
                ticket: undefined as Ticket | undefined,
                recipientUser: undefined as PublicUserForAdmin | undefined,
                reportingUser: undefined as PublicUserForAdmin | undefined,
                auditHistoryUser: undefined as PublicUser | undefined,
                ticketUser: undefined as PublicUser | undefined,
                showNotes: false,
                allNotes: '',
                newResolutionNote: '',
                showOffensiveMedia: undefined as Media | undefined,
                showLiveness: false,
            };
        },

        watch: {
            '$route.query.ticketId': {
                immediate: true,
                handler(newVal: number, oldVal: number) {
                    if (newVal && newVal > 0 && newVal !== oldVal) {
                        this.fetchTicket(newVal);
                    }
                },
            },
        },

        computed: {
            canTakeOwnership(): boolean {
                return this.ticket !== undefined && (!this.ticket.reviewer || !this.$store.getters.isAuthedUserId(this.ticket.reviewer.userId));
            },

            isTicketOwner(): boolean {
                return this.ticket !== undefined && this.ticket.reviewer && this.$store.getters.isAuthedUserId(this.ticket.reviewer.userId);
            },

            ticketResolved(): boolean {
                return this.ticket !== undefined && this.ticket.status === SharedConstants.TICKET_STATUS_RESOLVED;
            },

            numberOfNotes(): String {
                let ret = 'Show Notes';
                if (
                    this.ticket !== undefined &&
                    ((this.ticket.userNote && this.ticket.userNote.length > 0) || (this.ticket.notes && this.ticket.notes.length > 0))
                ) {
                    ret += ' (';
                    if (this.ticket.userNote && this.ticket.userNote.length > 0) {
                        ret += 'user';
                    }

                    if (this.ticket.notes && this.ticket.notes.length > 0) {
                        if (this.ticket.userNote && this.ticket.userNote.length > 0) {
                            ret += ', ';
                        }

                        ret += this.ticket.notes.length + ' agent';
                    }

                    ret += ')';
                }

                return ret;
            },
        },

        methods: {
            async fetchTicket(id: number) {
                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.getTicketById, id);
                    if (!ret) {
                        logInvalidResponse(this.$options.name, 'fetchTicket');
                        return;
                    }

                    console.log(ret);

                    this.ticket = ret;

                    //
                    // Get the PublicUserForAdmin user objects for the recipient and reporting users,
                    // which contain higher-level data about the user (e.g., disabledReason, ticket stats, etc.).
                    //
                    this.PublicUserForAdmin(this.ticket.recipientUser?.userId as number, 'recipientUser');

                    if (this.ticket.reportingUser) {
                        this.PublicUserForAdmin(this.ticket.reportingUser.userId as number, 'reportingUser');
                    } else {
                        this.reportingUser = undefined as PublicUserForAdmin | undefined;
                    }

                    this.resetNotes(this.ticket);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            resetNotes(ticket: Ticket) {
                let allNotes = '';
                if (ticket && ticket.notes) {
                    for (const note of ticket.notes) {
                        allNotes += '<b>' + note.user?.firstName + ' ' + note.user?.lastName + ':</b> ' + note.note;
                    }
                }

                this.allNotes = allNotes;
            },

            getNoteHeader(item: TicketNote): string {
                if (!item.user) {
                    return '';
                }

                return (
                    item.user.firstName +
                    ' ' +
                    item.user.lastName +
                    ' (' +
                    LuxonDateTime.fromISO(item.created).toLocaleString(LuxonDateTime.DATETIME_SHORT) +
                    ')'
                );
            },

            // Fetch the PublicUserForAdmin user object for the given storage name
            async PublicUserForAdmin(userId: number, localStorageName: string) {
                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.getPublicUser, userId);

                    Vue.set(this, localStorageName, ret);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            zeroCount(count: number | undefined): number {
                if (!count) {
                    return 0;
                }

                return count;
            },

            reloadUser(userId: number | undefined, localStorageName: string) {
                if (!userId) {
                    logInvalidParams(this.$options.name, 'reloadUser');
                    return;
                }

                this.PublicUserForAdmin(userId, localStorageName);
            },

            async investigateReport() {
                if (!this.ticket) {
                    logInvalidParams(this.$options.name, 'investigateReport');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.takeOwnershipOfTicket, this.ticket.ticketId);
                    if (!ret || !ret.ticketId) {
                        logInvalidResponse(this.$options.name, 'investigateReport');
                        return;
                    }

                    this.fetchTicket(ret.ticketId as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async saveNewNote() {
                if (!this.ticket) {
                    logInvalidParams(this.$options.name, 'saveNewNote');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.addNoteToTicket, {
                        ticketId: this.ticket.ticketId,
                        note: this.newResolutionNote,
                    });

                    if (!ret || !ret.ticketId) {
                        logInvalidResponse(this.$options.name, 'saveNewNote');
                        return;
                    }

                    this.newResolutionNote = '';
                    this.fetchTicket(ret.ticketId as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async viewOffensiveMedia() {
                if (!this.ticket || !this.ticket.media) {
                    logInvalidParams(this.$options.name, 'viewOffensiveMedia');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(MediaService.getMediaById, {
                        mediaId: this.ticket.media?.mediaId,
                    });

                    if (ret && ret.media) {
                        this.showOffensiveMedia = ret.media;
                    } else {
                        logInvalidResponse(this.$options.name, 'viewOffensiveMedia', ret);
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async resolved(type: string) {
                if (!this.ticket) {
                    logInvalidParams(this.$options.name, 'resolved');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.resolveTicket, {
                        ticketId: this.ticket.ticketId,
                        note: this.newResolutionNote,
                        resolution: type,
                    });

                    if (!ret || !ret.ticketId) {
                        logInvalidResponse(this.$options.name, 'resolved');
                        return;
                    }

                    this.fetchTicket(ret.ticketId as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            // Return the user's name.  Combined with lastname if given.
            getDisplayName(user: PublicUser | undefined): string {
                return Utils.FormatUserNameForDisplay(user?.firstName, user?.lastName);
            },
            getReportingUserType(): string {
                if (!this.reportingUser || !this.reportingUser?.publicUser) return 'System';

                var isSupportAdmin = this.reportingUser?.roles?.filter((role) => role.role == SharedConstants.USER_SUPPORT_ADMIN);
                return isSupportAdmin ? 'Support Admin' : 'User';
            },
            getUserProfileLink(userId: number | undefined) {
                return userId ? '/' + String(userId) : '#';
            },
        },
    });
