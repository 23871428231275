
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import MultiRangeSlider from 'multi-range-slider-vue';
    import RangeSlider from 'vue-range-slider';
    import 'vue-range-slider/dist/vue-range-slider.css';
    import { UserService, TargetingPreferenceDefinitions, GetDatingPoolRequest, SharedConstants } from '@bostonventurestudio/lola-api';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    Vue.use(MultiRangeSlider);
    Vue.use(RangeSlider);

    export default mixins(VueConstants).extend({
        name: 'DatingPoolSearchFilters',
        components: {
            MultiRangeSlider,
            RangeSlider,
        },
        data() {
            return {
                filters: {} as GetDatingPoolRequest,
                targetingDefinitions: undefined as TargetingPreferenceDefinitions | undefined,
                localFilters: {} as GetDatingPoolRequest,
                submitting: false,
                location_title: 'Location',
                createdOptions: [
                    { value: SharedConstants.USER_FILTER_CREATED_ANY_TIME, text: 'Any time' },
                    { value: SharedConstants.USER_FILTER_CREATED_IN_PAST_24_HOURS, text: 'Last 24 hours' },
                    { value: SharedConstants.USER_FILTER_CREATED_IN_YESTERDAY, text: 'Yesterday' },
                    { value: SharedConstants.USER_FILTER_CREATED_IN_PAST_7_DAYS, text: 'Last 7 days' },
                    { value: SharedConstants.USER_FILTER_CREATED_IN_PAST_30_DAYS, text: 'Last 30 days' },
                ],
                priorityOptions: [
                    { key: SharedConstants.USER_PROFILE_PRIORITY_NONE, text: 'Not Set' },
                    { key: SharedConstants.USER_PROFILE_PRIORITY_P0, text: 'P0 - Low' },
                    { key: SharedConstants.USER_PROFILE_PRIORITY_P1, text: 'P1' },
                    { key: SharedConstants.USER_PROFILE_PRIORITY_P2, text: 'P2' },
                    { key: SharedConstants.USER_PROFILE_PRIORITY_P3, text: 'P3' },
                ],
                ticketStatusOptions: [
                    { value: SharedConstants.USER_FILTER_TICKET_STATUS_NO_UNRESOLVED, text: 'No Unresolved Tickets' },
                    { value: SharedConstants.USER_FILTER_TICKET_STATUS_UNRESOLVED, text: 'Unresolved Tickets' },
                    { value: SharedConstants.USER_FILTER_TICKET_STATUS_RESOLVED, text: 'Only Resolved Tickets' },
                    { value: SharedConstants.USER_FILTER_TICKET_STATUS_NO_TICKETS, text: 'No Tickets' },
                    { value: SharedConstants.USER_FILTER_TICKET_STATUS_ANY, text: 'Any Ticket Status' },
                ],
                marketOptions: [
                    { value: '', text: 'Default' }, // No market option
                    { value: 'Boston', text: 'Boston' },
                ],
                userStatusOptions: [
                    { value: '', text: 'Any' },
                    { value: 'dating', text: 'Dating' },
                    { value: SharedConstants.INACTIVE_REASON_ON_WAITLIST, text: 'On Waitlist' },
                    { value: SharedConstants.INACTIVE_REASON_ONBOARDING_IN_PROGRESS, text: 'Onboarding In Progress' },
                    { value: SharedConstants.INACTIVE_REASON_DATING_PAUSED, text: 'Dating Paused' },
                    { value: SharedConstants.INACTIVE_REASON_ACCOUNT_SUSPENDED, text: 'Account Suspended' },
                    { value: SharedConstants.INACTIVE_REASON_ACCOUNT_BANNED, text: 'Account Banned' },
                    { value: SharedConstants.INACTIVE_REASON_ACCOUNT_DELETED, text: 'Account Deleted' },
                    { value: SharedConstants.INACTIVE_REASON_API_LIMIT_REACHED, text: 'Api Limit Reached' },
                ],
            };
        },
        watch: {
            filters: {
                immediate: true,
                deep: true,
                handler(newVal: GetDatingPoolRequest, oldVal: GetDatingPoolRequest) {
                    if (newVal && newVal !== oldVal) {
                        this.localFilters = Utils.deepCopy(newVal);

                        if (!this.localFilters.genders) {
                            var genders = [] as (string | undefined)[];
                            if (this.targetingDefinitions?.gender?.optionList) {
                                var genders = this.targetingDefinitions?.gender?.optionList.map((obj) => obj.key);
                            }
                            Vue.set(this.localFilters, 'genders', genders);
                        }

                        //
                        // We need to set the default age min/max range to start, from the targeting
                        // definitions, only if nothing is available already in localFilters.
                        //
                        if (!this.localFilters.minAge || !this.localFilters.maxAge) {
                            // Default the age values if the user has no range set or doesn't have an age set.
                            let minAge = this.targetingDefinitions?.age?.minValue ?? 28;
                            let maxAge = this.targetingDefinitions?.age?.maxValue ?? 35;

                            Vue.set(this.localFilters, 'minAge', minAge);
                            Vue.set(this.localFilters, 'maxAge', maxAge);

                            // WTF? Using Vue.set doesn't seem to always work, so we just set directly
                            // via nextTick, which seems to help.
                            Vue.nextTick(() => {
                                this.localFilters.maxAge = maxAge;
                            });
                        }

                        // If either minAge or Max age is not set in localFilters, set both
                        if (!this.localFilters.minHeightInInches || !this.localFilters.maxHeightInInches) {
                            let minHeightInInches = this.targetingDefinitions?.height?.minValue ?? 60;
                            let maxHeightInInches = this.targetingDefinitions?.height?.maxValue ?? 72;

                            Vue.set(this.localFilters, 'minHeightInInches', minHeightInInches);
                            Vue.set(this.localFilters, 'maxHeightInInches', maxHeightInInches);

                            // Using Vue.set doesn't seem to always work, so we just set directly
                            // via nextTick, which seems to help.
                            Vue.nextTick(() => {
                                this.localFilters.maxHeightInInches = maxHeightInInches;
                            });
                        }

                        if (!this.localFilters.radiusInMiles) {
                            Vue.set(this.localFilters, 'radiusInMiles', this.targetingDefinitions?.distance?.maxValue ?? 4);
                        }
                        if (this.localFilters.market === undefined) {
                            Vue.set(this.localFilters, 'market', 'Boston');
                        }

                        if (this.localFilters.onWaitlist === undefined) {
                            Vue.set(this.localFilters, 'onWaitlist', true);
                        }

                        if (this.localFilters.userStatus === undefined) {
                            Vue.set(this.localFilters, SharedConstants.INACTIVE_REASON_ON_WAITLIST, true);
                        }
                    }
                },
            },
        },
        mounted() {
            this.fetchTargetingDefinitions();
        },
        computed: {
            minimumGenderSelected(): boolean {
                if (
                    !this.localFilters.genders ||
                    !this.targetingDefinitions ||
                    !this.targetingDefinitions.gender ||
                    !this.targetingDefinitions.gender.optionList ||
                    !this.targetingDefinitions.gender.minLength
                ) {
                    logInvalidParams(this.$options.name, 'minimumGenderSelected');
                    return false;
                }

                let count = 0;
                for (const val of this.targetingDefinitions.gender.optionList) {
                    if (this.localFilters.genders.includes(val.key as string)) {
                        count++;
                    }
                }

                // If no max length give, use the list length
                let maxLength = this.targetingDefinitions.gender.optionList.length;
                if (this.targetingDefinitions.gender.maxLength) {
                    maxLength = this.targetingDefinitions.gender.maxLength;
                }

                return count >= this.targetingDefinitions.gender.minLength && count <= maxLength;
            },
            minimumPrioritySelected(): boolean {
                if (!this.localFilters.priorityStatuses) {
                    logInvalidParams(this.$options.name, 'minimumPrioritySelected');
                    return false;
                }

                let count = 0;
                for (const val of this.priorityOptions) {
                    if (this.localFilters.priorityStatuses.includes(val.key as string)) {
                        count++;
                    }
                }
                return count >= 1 && count <= this.priorityOptions.length;
            },

            latitudeValid(): boolean {
                if (this.localFilters.location && this.localFilters.location.latitude === 0) {
                    return true; // 0 is legal
                } else if (!this.localFilters.location || !this.localFilters.location.latitude) {
                    return false;
                }

                return this.localFilters.location.latitude >= -90.0 && this.localFilters.location.latitude <= 90.0;
            },

            longitudeValid(): boolean {
                if (this.localFilters.location && this.localFilters.location.longitude === 0) {
                    return true; // 0 is legal
                } else if (!this.localFilters.location || !this.localFilters.location.longitude) {
                    return false;
                }

                return this.localFilters.location.longitude >= -180.0 && this.localFilters.location.longitude <= 180.0;
            },
        },
        methods: {
            inputsValid(): boolean {
                return this.minimumGenderSelected;
            },
            updateAge(e: any) {
                this.localFilters.minAge = e.minValue;
                this.localFilters.maxAge = e.maxValue;
            },

            updateHeight(e: any) {
                this.localFilters.minHeightInInches = e.minValue;
                this.localFilters.maxHeightInInches = e.maxValue;
            },

            heightDisplayText(): string {
                const min = this.localFilters.minHeightInInches as number;
                const minFeet = Math.floor(min / 12);
                const minInches = min % 12;

                const max = this.localFilters.maxHeightInInches as number;
                const maxFeet = Math.floor(max / 12);
                const maxInches = max % 12;

                return minFeet + '&#39; ' + minInches + '&#34; &mdash; ' + maxFeet + '&#39; ' + maxInches + '&#34;';
            },

            selectionChanged(type: 'gender' | 'priority', key: string | undefined) {
                if (!key) {
                    logInvalidParams(this.$options.name, 'selectionChanged');
                    return;
                }

                let optionList;
                let filterKey;
                let localList: string[] | undefined;

                if (type === 'gender') {
                    if (
                        !this.localFilters.genders ||
                        !this.targetingDefinitions ||
                        !this.targetingDefinitions.gender ||
                        !this.targetingDefinitions.gender.optionList
                    ) {
                        logInvalidParams(this.$options.name, 'selectionChanged');
                        return;
                    }
                    optionList = this.targetingDefinitions.gender.optionList;
                    filterKey = 'genders';
                    localList = this.localFilters.genders;
                } else if (type === 'priority') {
                    optionList = this.priorityOptions;
                    filterKey = 'priorityStatuses';
                    localList = this.localFilters.priorityStatuses;
                } else {
                    logInvalidParams(this.$options.name, 'selectionChanged');
                    return;
                }

                // Recreate the array of keys every time.
                const newList = [] as string[];
                for (const val of optionList) {
                    if (localList?.includes(val.key as string) && val.key !== key) {
                        newList.push(val.key as string);
                    } else if (val.key === key && !localList?.includes(val.key as string)) {
                        newList.push(key as string);
                    }
                }

                Vue.set(this.localFilters, filterKey, newList);
            },

            isChecked(type: 'gender' | 'priority', key: string | undefined): boolean {
                if (!key || key === '') {
                    logInvalidParams(this.$options.name, 'isChecked');
                    return false;
                }

                let localList: string[] | undefined;

                if (type === 'gender') {
                    localList = this.localFilters.genders;
                } else if (type === 'priority') {
                    localList = this.localFilters.priorityStatuses;
                } else {
                    logInvalidParams(this.$options.name, 'isChecked');
                    return false;
                }

                if (!this.localFilters || !localList) {
                    return false; // Nothing selected by the user yet
                }

                return localList.includes(key);
            },
            resetFilters() {
                this.filters = {
                    location: { latitude: 42.3601, longitude: -71.0589 }, // set to central boston
                    created: SharedConstants.USER_FILTER_CREATED_ANY_TIME,
                    isBetaTester: false,
                    priorityStatuses: [
                        SharedConstants.USER_PROFILE_PRIORITY_NONE,
                        SharedConstants.USER_PROFILE_PRIORITY_P0,
                        SharedConstants.USER_PROFILE_PRIORITY_P1,
                        SharedConstants.USER_PROFILE_PRIORITY_P2,
                        SharedConstants.USER_PROFILE_PRIORITY_P3,
                    ],
                    ticketStatus: SharedConstants.USER_FILTER_TICKET_STATUS_NO_UNRESOLVED,
                    market: 'Boston', // Set default market to Boston
                    onWaitlist: true, // Default waitlist status
                    userStatus: SharedConstants.INACTIVE_REASON_ON_WAITLIST, // Default user status
                } as GetDatingPoolRequest;
            },
            /**
             * Handles the search event.
             * @param {Event} event - The search event.
             * @returns {Promise<void>}
             */
            async handleSearch(event: Event) {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    // Tell parent to search on target preferences
                    this.$emit('search', this.localFilters);
                    this.submitting = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            //
            // Fetch the targeting definition which help the client determine titles
            // and acceptable value ranges.
            //
            async fetchTargetingDefinitions() {
                try {
                    this.targetingDefinitions = await ApiUtils.apiWrapper(UserService.getTargetingPreferenceDefinitions);
                    // reset filters so that target definitions can be applied
                    this.resetFilters();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
