//
// Copyright 2022-2023 Lola - All rights reserved.
// File: VueConstants.ts
// Project: vue
//
import Vue from 'vue';

// @ts-ignore
import * as constants from '@/tsfiles/constants';
import { SharedConstants } from '@bostonventurestudio/lola-api';

//
// Mixin of constants (SharedConstants and UI specific).  The SharedConstants map
// to the server API values, generated via servicedef (similar to protobuf/grpc apis).
//
// This is done as a mixin to make access inside templates easy. Theoretically a plugin
// could work as well.
//
// There's no reason to put any values in here if they will never used be used
// inside Vue templates.
//
// The convention at the moment is to use 'shareconstants.' inside the view code,
// even though they are accessible from 'this.'.
//
const VueConstants = Vue.extend({
    data() {
        return {
            //////////////////////////////////////////////////////////////////////////
            // General mixin constants NOT synced with server API.
            //////////////////////////////////////////////////////////////////////////
            COMPANY_NAME: constants.COMPANY_NAME,
            COMPANY_DOMAIN: constants.COMPANY_DOMAIN,
            FULL_MARKETING_DOMAIN: constants.FULL_MARKETING_DOMAIN,

            FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED: constants.FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED,
            FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE: constants.FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE,
            FAKE_NOTIFICATION_DATING_PAUSED: constants.FAKE_NOTIFICATION_DATING_PAUSED,

            // Most common routing names, needed by various components.
            ROUTE_MARKETING: constants.ROUTE_MARKETING,
            ROUTE_FIREBASE_SIGNIN: constants.ROUTE_FIREBASE_SIGNIN,
            ROUTE_USER_HOME: constants.ROUTE_USER_HOME,
            ROUTE_USER_NOTIFICATIONS: constants.ROUTE_USER_NOTIFICATIONS,
            ROUTE_USER_BLOCKED: constants.ROUTE_USER_BLOCKED,
            ROUTE_SEARCH_RESULTS: constants.ROUTE_SEARCH_RESULTS,
            ROUTE_USER_CALENDAR: constants.ROUTE_USER_CALENDAR,
            ROUTE_USER_PROSPECTS: constants.ROUTE_USER_PROSPECTS,
            ROUTE_USER_PENDING_PROPOSALS: constants.ROUTE_USER_PENDING_PROPOSALS,
            ROUTE_USER_DATES: constants.ROUTE_USER_DATES,
            ROUTE_USER_DATE: constants.ROUTE_USER_DATE,
            ROUTE_USER_PAST_DATE: constants.ROUTE_USER_PAST_DATE,
            ROUTE_USER_PAST_DATES: constants.ROUTE_USER_PAST_DATES,
            ROUTE_USER_SETTINGS: constants.ROUTE_USER_SETTINGS,
            ROUTE_USER_PROFILE: constants.ROUTE_USER_PROFILE,
            ROUTE_USER_POST_DATE_SURVEYS: constants.ROUTE_USER_POST_DATE_SURVEYS,
            ROUTE_USER_DATING_SCORECARDS: constants.ROUTE_USER_DATING_SCORECARDS,
            ROUTE_SEARCH: constants.ROUTE_SEARCH,
            ROUTE_DATING_POOL: constants.ROUTE_DATING_POOL,

            ROUTE_ADMIN_SERVER: constants.ROUTE_ADMIN_SERVER,
            ROUTE_ADMIN_SUPPORT: constants.ROUTE_ADMIN_SUPPORT,
            ROUTE_ADMIN_TICKET: constants.ROUTE_ADMIN_TICKET,
            ROUTE_ADMIN_STATS: constants.ROUTE_ADMIN_STATS,

            //////////////////////////////////////////////////////////////////////////
            // UI/Server synced constants
            //////////////////////////////////////////////////////////////////////////

            DEVICE_TYPE_IOS: SharedConstants.DEVICE_TYPE_IOS,
            DEVICE_TYPE_ANDROID: SharedConstants.DEVICE_TYPE_ANDROID,

            CHANNEL_EMAIL: SharedConstants.CHANNEL_EMAIL,
            CHANNEL_PHONE: SharedConstants.CHANNEL_PHONE,

            // General warnings
            WARNING_RESOURCE_USED: SharedConstants.WARNING_RESOURCE_USED,
            WARNING_RESOURCE_EXPIRED: SharedConstants.WARNING_RESOURCE_EXPIRED,
            WARNING_RESOURCE_NOT_FOUND: SharedConstants.WARNING_RESOURCE_NOT_FOUND,

            // Admin roles
            USER_SERVER_ADMIN: SharedConstants.USER_SERVER_ADMIN,
            USER_SUPPORT_ADMIN: SharedConstants.USER_SUPPORT_ADMIN,
            USER_TEST_ADMIN: SharedConstants.USER_TEST_ADMIN,

            // User disabledReasons
            INACTIVE_REASON_ONBOARDING_IN_PROGRESS: SharedConstants.INACTIVE_REASON_ONBOARDING_IN_PROGRESS,
            INACTIVE_REASON_ON_WAITLIST: SharedConstants.INACTIVE_REASON_ON_WAITLIST,
            INACTIVE_REASON_DATING_PAUSED: SharedConstants.INACTIVE_REASON_DATING_PAUSED,
            INACTIVE_REASON_ACCOUNT_DELETED: SharedConstants.INACTIVE_REASON_ACCOUNT_DELETED,
            INACTIVE_REASON_ACCOUNT_SUSPENDED: SharedConstants.INACTIVE_REASON_ACCOUNT_SUSPENDED,
            INACTIVE_REASON_ACCOUNT_BANNED: SharedConstants.INACTIVE_REASON_ACCOUNT_BANNED,
            INACTIVE_REASON_API_LIMIT_REACHED: SharedConstants.INACTIVE_REASON_API_LIMIT_REACHED,

            // Notification contants (most likely from notification db table).
            NOTIFICATION_VERIFY_EMAIL: SharedConstants.NOTIFICATION_VERIFY_EMAIL,
            NOTIFICATION_VERIFY_PHONE: SharedConstants.NOTIFICATION_VERIFY_PHONE,
            NOTIFICATION_EMAIL_FLAGGED: SharedConstants.NOTIFICATION_EMAIL_FLAGGED,
            NOTIFICATION_PHONE_FLAGGED: SharedConstants.NOTIFICATION_PHONE_FLAGGED,
            NOTIFICATION_LIKE_RECEIVED: SharedConstants.NOTIFICATION_LIKE_RECEIVED,
            NOTIFICATION_NEW_DATE_MATCH: SharedConstants.NOTIFICATION_NEW_DATE_MATCH,
            NOTIFICATION_NEW_PENDING_DATE_MATCH: SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH,
            NOTIFICATION_NEW_CHAT_POST: SharedConstants.NOTIFICATION_NEW_CHAT_POST,
            NOTIFICATION_AVAILABILITY_RECEIVED: SharedConstants.NOTIFICATION_AVAILABILITY_RECEIVED,
            NOTIFICATION_PHONE_NUMBER_RECEIVED: SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED,
            NOTIFICATION_DATE_UNMATCHED: SharedConstants.NOTIFICATION_DATE_UNMATCHED,
            NOTIFICATION_DATE_REMINDER: SharedConstants.NOTIFICATION_DATE_REMINDER,
            NOTIFICATION_DATE_RESCHEDULED: SharedConstants.NOTIFICATION_DATE_RESCHEDULED,
            NOTIFICATION_DATE_DELETED_ACCOUNT: SharedConstants.NOTIFICATION_DATE_DELETED_ACCOUNT,
            NOTIFICATION_POST_DATE_SURVEY: SharedConstants.NOTIFICATION_POST_DATE_SURVEY,
            NOTIFICATION_NEW_SCORECARD: SharedConstants.NOTIFICATION_NEW_SCORECARD,

            // Chat related constants
            CHAT_MAX_POST_MESSAGE_LENGTH: SharedConstants.CHAT_MAX_POST_MESSAGE_LENGTH,
            CHAT_STATE_ACTIVE: SharedConstants.CHAT_STATE_ACTIVE,
            CHAT_STATE_CLOSED: SharedConstants.CHAT_STATE_CLOSED,
            CHAT_STATE_FLAGGED: SharedConstants.CHAT_STATE_FLAGGED,

            // Ticket constants
            TICKET_STATUS_OPEN: SharedConstants.TICKET_STATUS_OPEN,
            TICKET_STATUS_INVESTIGATING: SharedConstants.TICKET_STATUS_INVESTIGATING,
            TICKET_STATUS_RESOLVED: SharedConstants.TICKET_STATUS_RESOLVED,
            TICKET_STATUS_UNRESOLVED: SharedConstants.TICKET_STATUS_UNRESOLVED,
            TICKET_RESOLUTION_FALSE_REPORT: SharedConstants.TICKET_RESOLUTION_FALSE_REPORT,
            TICKET_RESOLUTION_MISUNDERSTANDING: SharedConstants.TICKET_RESOLUTION_MISUNDERSTANDING,
            TICKET_RESOLUTION_WARNING: SharedConstants.TICKET_RESOLUTION_WARNING,
            TICKET_RESOLUTION_SUSPENSION: SharedConstants.TICKET_RESOLUTION_SUSPENSION,
            TICKET_RESOLUTION_BAN: SharedConstants.TICKET_RESOLUTION_BAN,
            TICKET_RESOLUTION_REVIEWED: SharedConstants.TICKET_RESOLUTION_REVIEWED,

            // Sorting and Filtering constants
            SORT_BY_CREATED_DATE: SharedConstants.SORT_BY_CREATED_DATE,
            SORT_BY_UPDATED_DATE: SharedConstants.SORT_BY_UPDATED_DATE,

            // Social media platforms
            SOCIAL_PERSONAL: SharedConstants.SOCIAL_PERSONAL,
            SOCIAL_LINKEDIN: SharedConstants.SOCIAL_LINKEDIN,
            SOCIAL_TWITTER: SharedConstants.SOCIAL_TWITTER,
            SOCIAL_TIKTOK: SharedConstants.SOCIAL_TIKTOK,
            SOCIAL_FACEBOOK: SharedConstants.SOCIAL_FACEBOOK,
            SOCIAL_INSTAGRAM: SharedConstants.SOCIAL_INSTAGRAM,
            SOCIAL_SPOTIFY: SharedConstants.SOCIAL_SPOTIFY,
            SOCIAL_YOUTUBE: SharedConstants.SOCIAL_YOUTUBE,
            SOCIAL_PINTEREST: SharedConstants.SOCIAL_PINTEREST,
            SOCIAL_MEDIUM: SharedConstants.SOCIAL_MEDIUM,
            SOCIAL_GITHUB: SharedConstants.SOCIAL_GITHUB,
            SOCIAL_SOUNDCLOUD: SharedConstants.SOCIAL_SOUNDCLOUD,
        };
    },
});

export default VueConstants;
