
    import { Vue } from 'vue-property-decorator';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { AdminService, ApiStats, DeviceCounts } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'ServerStats',

        data() {
            return {
                stats: null as ApiStats | null,
                ios: {} as DeviceCounts,
                android: {} as DeviceCounts,
            };
        },

        mounted() {
            this.fetchData();
        },

        computed: {
            hasAccess(): boolean {
                return this.$store.getters.isSignedIn && RoleUtils.IsServerSuperAdmin();
            },
        },

        methods: {
            async fetchData() {
                if (this.hasAccess) {
                    try {
                        this.stats = await ApiUtils.apiWrapper(AdminService.feGetApiStats);
                        if (this.stats && this.stats.deviceCounts) {
                            for (const device of this.stats.deviceCounts) {
                                if (device.type === 'ios') {
                                    this.ios = device;
                                } else if (device.type === 'android') {
                                    this.android = device;
                                }
                            }
                        }
                    } catch (error) {
                        Utils.CommonErrorHandler(error);
                    }
                }
            },

            getNumber(val: number | undefined): number {
                if (val) {
                    return val;
                }
                return 0;
            },
        },
    });
