
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { AxiosError } from 'axios';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import 'vue-range-slider/dist/vue-range-slider.css';
    import { UserService, SharedConstants } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'NotificationSettings',

        data() {
            return {
                notificationPreferenceDefinitions: [] as Array<any>,
                notificationSettings: {
                    email: {
                        enabled: false,
                        categories: {} as { [key: string]: boolean },
                    },
                    sms: {
                        enabled: false,
                        categories: {} as { [key: string]: boolean },
                    },
                    push: {
                        enabled: false,
                        categories: {} as { [key: string]: boolean },
                    },
                } as { [key: string]: { enabled: boolean; categories: { [key: string]: boolean } } },
                submitting: false,
                errorMessage: '',
            };
        },

        created() {
            this.fetchNotificationPreferences();
        },

        computed: {
            allCategoriesChecked() {
                return (preference: any) => {
                    if (!preference || !preference.categories) {
                        return false;
                    }
                    return preference.categories.every(
                        (category: any) => this.notificationSettings[preference.propertyName]?.categories?.[category.key],
                    );
                };
            },
        },
        watch: {
            'notificationSettings.email.categories': {
                handler(newVal) {
                    this.updateEnabledStatus('email');
                },
                deep: true,
            },
            'notificationSettings.sms.categories': {
                handler(newVal) {
                    this.updateEnabledStatus('sms');
                },
                deep: true,
            },
        },

        methods: {
            //
            // Fetch the user's notification settings from the API
            //
            async fetchNotificationPreferences() {
                try {
                    const notificationPreferenceDefinitions = await ApiUtils.apiWrapper(UserService.getNotificationPreferenceDefinitions);
                    this.notificationPreferenceDefinitions = [
                        { ...notificationPreferenceDefinitions.email, type: 'email' },
                        { ...notificationPreferenceDefinitions.sms, type: 'sms' },
                        { ...notificationPreferenceDefinitions.push, type: 'push' },
                    ];

                    const notificationPreferences = await ApiUtils.apiWrapper(UserService.getNotificationPreferences);
                    if (!notificationPreferences.preferences) {
                        return;
                    }
                    if (notificationPreferences.preferences.email) {
                        this.notificationSettings.email = {
                            enabled: !!notificationPreferences.preferences.email.enabled,
                            categories: notificationPreferences.preferences.email.categories || ({} as { [key: string]: boolean }),
                        };
                    } else {
                        this.notificationSettings.email = {
                            enabled: false,
                            categories: {} as { [key: string]: boolean },
                        };
                    }

                    if (notificationPreferences.preferences.sms) {
                        this.notificationSettings.sms = {
                            enabled: !!notificationPreferences.preferences.sms.enabled,
                            categories: notificationPreferences.preferences.sms.categories || ({} as { [key: string]: boolean }),
                        };
                    } else {
                        this.notificationSettings.sms = {
                            enabled: false,
                            categories: {} as { [key: string]: boolean },
                        };
                    }

                    if (notificationPreferences.preferences.push) {
                        this.notificationSettings.push = {
                            enabled: !!notificationPreferences.preferences.push.enabled,
                            categories: notificationPreferences.preferences.push.categories || ({} as { [key: string]: boolean }),
                        };
                    } else {
                        this.notificationSettings.push = {
                            enabled: false,
                            categories: {} as { [key: string]: boolean },
                        };
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async handleSubmit(event: Event) {
                if (this.submitting) {
                    return;
                }

                this.submitting = true;
                this.errorMessage = ''; // Clear previous error message

                try {
                    const preferences: { [key: string]: any } = {
                        email: {
                            enabled: this.notificationSettings.email.enabled,
                            categories: Object.keys(this.notificationSettings.email.categories)
                                .filter((key) => this.notificationSettings.email.categories[key])
                                .reduce((acc, key) => {
                                    acc[key] = true;
                                    return acc;
                                }, {} as { [key: string]: boolean }),
                        },
                        sms: {
                            enabled: this.notificationSettings.sms.enabled,
                            categories: Object.keys(this.notificationSettings.sms.categories)
                                .filter((key) => this.notificationSettings.sms.categories[key])
                                .reduce((acc, key) => {
                                    acc[key] = true;
                                    return acc;
                                }, {} as { [key: string]: boolean }),
                        },
                        push: {
                            enabled: this.notificationSettings.push.enabled,
                            categories: Object.keys(this.notificationSettings.push.categories)
                                .filter((key) => this.notificationSettings.push.categories[key])
                                .reduce((acc, key) => {
                                    acc[key] = true;
                                    return acc;
                                }, {} as { [key: string]: boolean }),
                        },
                    };

                    try {
                        // Update SMS notification preferences
                        await ApiUtils.apiWrapper(UserService.updateSmsNotificationPreferences, {
                            dateUpdates: preferences.sms.categories[SharedConstants.USER_SMS_PREFERENCE_RECEIVE_DATING_UPDATES],
                            marketingUpdates: preferences.sms.categories[SharedConstants.USER_SMS_PREFERENCE_RECEIVE_MARKETING_UPDATES],
                        });
                    } catch (error) {
                        // Handle error and set the error message
                        if (error && (error as AxiosError).isAxiosError) {
                            const axiosError = error as AxiosError;

                            if (
                                axiosError.response?.status === 500 &&
                                axiosError.response.data?.message?.includes(SharedConstants.WARNING_INVALID_PHONE_OR_EMAIL)
                            ) {
                                this.errorMessage = 'Cannot update preferences: phone number not available for user';
                            }
                        }
                    }
                    try {
                        // Update email notification preferences
                        await ApiUtils.apiWrapper(UserService.updateEmailNotificationPreferences, {
                            receiveNewFeatureAnnouncements:
                                preferences.email.categories[SharedConstants.USER_EMAIL_PREFERENCE_RECEIVE_NEW_FEATURE_ANNOUNCEMENTS],
                            receiveSpecialOffersPromotions:
                                preferences.email.categories[SharedConstants.USER_EMAIL_PREFERENCE_RECEIVE_SPECIAL_OFFERS_PROMOTIONS],
                            receiveInvitationsToEvents:
                                preferences.email.categories[SharedConstants.USER_EMAIL_PREFERENCE_RECEIVE_INVITATIONS_TO_EVENTS],
                        });
                    } catch (error) {
                        // Handle error and set the error message
                        if (error && (error as AxiosError).isAxiosError) {
                            const axiosError = error as AxiosError;

                            if (
                                axiosError.response?.status === 500 &&
                                axiosError.response.data?.message?.includes(SharedConstants.WARNING_INVALID_PHONE_OR_EMAIL)
                            ) {
                                this.errorMessage = 'Cannot update preferences: email not available for user';
                            }
                        }
                    }

                    // update push notification preferences
                    await ApiUtils.apiWrapper(UserService.updatePushNotificationPreferences, { enabled: preferences.push.enabled });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                } finally {
                    this.submitting = false;
                }
            },

            convertArrayToObject(array: Array<{ key: string }>): { [key: string]: boolean } {
                const obj: { [key: string]: boolean } = {};
                array.forEach((item) => {
                    obj[item.key] = true;
                });
                return obj;
            },

            toggleAllCategories(propertyName: string, isEnabled: boolean): void {
                const preference = this.notificationPreferenceDefinitions.find((pref: any) => pref.propertyName === propertyName);
                if (preference && preference.categories) {
                    preference.categories.forEach((category: any) => {
                        Vue.set(this.notificationSettings[propertyName].categories, category.key, isEnabled);
                    });
                }
            },
            allCategoriesAreEnabled(propertyName: string): boolean {
                const preference = this.notificationPreferenceDefinitions.find((pref: any) => pref.propertyName === propertyName);
                if (!preference || !preference.categories) {
                    return false;
                }
                return preference.categories.every((category: any) => this.notificationSettings[propertyName]?.categories?.[category.key] === true);
            },
            updateEnabledStatus(propertyName: string): void {
                const allEnabled = this.allCategoriesAreEnabled(propertyName);
                Vue.set(this.notificationSettings[propertyName], 'enabled', allEnabled);
            },
        },
    });
