
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import * as constants from '@/tsfiles/constants';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import { SharedConstants, UserService, PublicUser, StringArrayVisibility, Profile } from '@bostonventurestudio/lola-api';
    import ImageSlideshow from '@/components/ImageSlideshow.vue';
    import EventBus from '@/eventbus';

    export default Vue.extend({
        name: 'Share',

        components: {
            'image-slideshow': ImageSlideshow,
        },

        props: {
            embeddedUserId: {
                type: Number,
                required: false,
                default: 0,
            },

            saveDS: {
                type: Boolean,
                required: false,
                default: false,
            },

            eventBus: {
                type: Vue,
                required: false,
                default: null,
            },
        },

        computed: {
            isSupportUser(): boolean {
                return this.$store.getters.isSignedIn && RoleUtils.CanSupportUsers();
            },
            // Return the user's name.  Combined with lastname if given.
            getDisplayName(): string {
                return Utils.FormatUserNameForDisplay(this.user?.firstName, this.user?.lastName);
            },

            getHeightText(): string {
                if (!this.user || !this.user.profile || !this.user.profile.heightInInches || !this.user.profile.heightInInches.value) {
                    logInvalidParams(this.$options.name, 'getHeightText');
                    return '';
                }

                const heightInInches = this.user.profile.heightInInches.value;
                const feet = Math.floor(heightInInches / 12);
                const inches = heightInInches - feet * 12;
                return feet + "' " + inches + '"';
            },

            sexualOrientationValue(): string {
                if (
                    this.user &&
                    this.user.profile &&
                    this.user.profile.sexualOrientation &&
                    this.user.profile.sexualOrientation.list &&
                    this.user.profile.sexualOrientation.list.length > 0
                ) {
                    return this.user.profile.sexualOrientation.list[0];
                }

                return '';
            },
            isSupportAdmin() {
                return RoleUtils.CanSupportUsers();
            },
        },

        watch: {
            '$route.params.token': {
                immediate: true,
                handler(newVal, oldVal) {
                    if (this.embeddedUserId === 0 && newVal && newVal !== oldVal) {
                        this.token = newVal;
                        this.fetchUser();

                        //
                        // If page navigation contains data, process.
                        //
                        const nav = this.$store.getters.getPageNavigation(constants.ROUTE_USER_HOME);
                        if (nav && nav.tab) {
                            this.$nextTick(() => {
                                this.currentTab = nav.tab;
                                this.currentNavigation.tab = nav.tab;
                            });
                        }
                    }
                },
            },

            embeddedUserId: {
                immediate: true,
                deep: true,
                handler(newVal: number, oldVal: number) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchUser();
                    }
                },
            },
        },

        methods: {
            showField(property: any): boolean {
                if (!property) return false;
                else if (this.isSupportUser) return true;

                var obj = property ?? { publiclyVisible: true };
                return obj && obj.hasOwnProperty('publiclyVisible') ? obj.publiclyVisible : true;
            },
            hasList(propertyName: string): boolean {
                if (!this.user || !this.user.profile) {
                    logInvalidParams(this.$options.name, 'hasList');
                    return false;
                }

                const profile = this.user.profile as Profile;
                const prop = profile[propertyName as keyof Profile] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    return currentList !== undefined && currentList.length > 0;
                }

                return false;
            },

            getSeparatedList(propertyName: string, separator: string): string {
                if (!this.user || !this.user.profile) {
                    logInvalidParams(this.$options.name, 'getCommaSeparatedList');
                    return '';
                }

                const profile = this.user.profile as Profile;
                const prop = profile[propertyName as keyof Profile] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    if (currentList && currentList.length > 0) {
                        return currentList.join(separator);
                    }
                }

                return '';
            },

            getFirstItemInList(propertyName: string): string {
                if (!this.user || !this.user.profile) {
                    logInvalidParams(this.$options.name, 'getFirstItemInList');
                    return '';
                }

                const profile = this.user.profile as Profile;
                const prop = profile[propertyName as keyof Profile] as StringArrayVisibility;
                if (prop) {
                    const currentList = prop.list;
                    if (currentList && currentList.length > 0) {
                        return currentList[0];
                    }
                }

                return '';
            },

            async fetchUser() {
                EventBus.$emit('fetching-user', true);
                this.fetchingUser = true;
                try {
                    this.blockerUserId = 0;

                    const ret = await ApiUtils.apiWrapper(UserService.getSharedProfile, {
                        token: this.token,
                    });
                    if (ret) {
                        if (ret.publicUser) {
                            this.user = ret.publicUser;
                            this.userId = this.user.userId as number;
                            EventBus.$emit('first-name', this.user.firstName);
                        }
                        this.viewsRemaining = ret.viewsRemaining ?? 0;
                        this.expiration = ret.expiration as Date;
                        EventBus.$emit('expired', false);

                        this.valid = true;
                    } else {
                        this.user = undefined;
                    }

                    if (this.user && this.user.userId) {
                        //
                        // Put photo urls into the local array
                        //
                        this.photoUrls = [] as string[];
                        if (this.user.profile?.mediaList && this.user.profile.mediaList.length > 0) {
                            for (const item of this.user.profile.mediaList) {
                                this.photoUrls.push(item.url as string);
                            }
                        }
                    }
                } catch (error: any) {
                    //  treat all errors as link expired for security reasons
                    EventBus.$emit('expired', true);
                    this.valid = false;
                    Utils.CommonErrorHandler(error);
                } finally {
                    EventBus.$emit('fetching-user', false);
                    this.fetchingUser = true;
                }
            },
        },

        data() {
            return {
                userId: 0,
                user: undefined as PublicUser | undefined,
                currentTab: 0,
                showFlagUserModal: false,

                token: '',
                viewsRemaining: 5,
                expiration: new Date(),

                totalLists: 0,
                totalRecommendations: 0,

                currentNavigation: { page: constants.ROUTE_USER_HOME } as PageNavigationData,
                blockerUserId: 0,

                photoUrls: [] as string[],

                fetchingUser: false,
                valid: false,
            };
        },
    });
