
    import mixins from 'vue-typed-mixins';
    import { Utils } from '@/tsfiles/utils';
    import VueConstants from '@/components/VueConstants';
    import * as analytics from '@/tsfiles/analytics';
    import * as constants from '@/tsfiles/constants';

    export default mixins(VueConstants).extend({
        name: 'StickyFooter',

        components: {},

        props: {},

        data() {
            return {};
        },

        computed: {
            showFooter(): boolean {
                return !Utils.InsideApp(navigator.userAgent);
            },

            getYear(): number {
                return new Date().getFullYear();
            },

            mailTo(): string {
                return 'mailto:contact@' + constants.COMPANY_DOMAIN;
            },
        },

        methods: {
            //
            // When a user clicks on an external link, we log the analytics event.  The
            // click is usually an "@click" without ".prevent", so the href will be triggered.
            //
            analyticsExternalClick(type: string) {
                analytics.logAppInteraction(analytics.ANALYTICS_ACTION_EXTERNAL_CONTENT_CLICK, type.toLowerCase());
            },
        },
    });
