
    import Vue, { PropOptions } from 'vue';
    import { DateTime as LuxonDateTime } from 'luxon';
    import Avatar from '@/components/Avatar.vue';
    import { Match, SharedConstants } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'DatingMatchRow',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            match: {
                type: Object,
                required: true,
            } as PropOptions<Match>,
        },

        data() {
            return {
                headerText: '',
                monthDayText: '',
                checked: false,
                isPending: false,
            };
        },

        watch: {
            match: {
                immediate: true,
                deep: true,
                handler(newVal: Match, oldVal: Match) {
                    if (newVal) {
                        this.isPending = false;
                        if (newVal.date) {
                            const lDate = LuxonDateTime.fromISO(newVal.date);
                            this.headerText = 'Date ' + lDate.toFormat('EEEE');
                            this.monthDayText = lDate.toFormat('MMMM d');
                        } else if (newVal.unmatchReason == SharedConstants.DATING_UNMATCH_REASON_RESCHEDULE) {
                            this.headerText = 'Reschedule';
                        } else {
                            this.headerText = 'Pending';
                            this.isPending = true;
                        }
                    }
                },
            },
        },

        computed: {
            lastMessageIsFromPartner(): boolean {
                return this.match.partnerUserId == this.match.chatLastMessageAuthorUserId;
            },
            lastMessageFormated(): string {
                if (!this.match.chatLastMessage) return '';
                const prefix = this.lastMessageIsFromPartner ? this.match.partnerName : 'You';
                var message = this.match?.chatLastMessage ?? '';
                return (this.isPending ? '' : prefix + ': ') + message;
            },
        },

        methods: {},
    });
