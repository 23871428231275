/*
 * Copyright 2023-2024 Lola - All rights reserved.
 * File: shareroutes.ts
 * Project: Lola
 */
import Error from '@/views/Error.vue';
import Share from '@/views/Share.vue';

export default [
    {
        path: '/share/:token',
        name: 'share',
        component: Share,
        meta: { requiresAuth: false },
    },
    {
        path: '/marketing',
        name: 'marketing',
        redirect: '/',
        meta: { requiresAuth: false },
    },
];
