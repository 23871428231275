
    import Vue, { PropOptions } from 'vue';
    import JSON5 from 'json5';
    import { DateTime as LuxonDateTime } from 'luxon';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import VueGallerySlideshow from 'vue-gallery-slideshow';
    import { AdminService, PublicUserForAdmin } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'LivenessSnapshots',

        components: {
            VueGallerySlideshow,
        },

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUserForAdmin>,
        },

        data() {
            return {
                photoIndex: null as any,
            };
        },

        computed: {},

        methods: {},
    });
