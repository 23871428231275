
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { DateTime as LuxonDateTime } from 'luxon';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import UserProfile from '@/components/user/UserProfile.vue';
    import UserChat from '@/components/user/UserChat.vue';
    import CollectDateInput from '@/generic-modals/CollectDateInput.vue';
    import DatingMatchRow from '@/components/dating/DatingMatchRow.vue';

    import {
        SharedConstants,
        UserService,
        PublicUser,
        DatingService,
        Match,
        GetMatchesResponse,
        GetMatchesStatsResponse,
        WipeMatchRequest,
        BackdateMatchRequest,
    } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserDates',

        components: {
            'user-profile': UserProfile,
            'user-chat': UserChat,
            'dating-match-row': DatingMatchRow,
            'collect-date-input': CollectDateInput,
        },

        props: {},

        data() {
            return {
                tabs: [SharedConstants.DATING_MATCH_STATE_UPCOMING, SharedConstants.DATING_MATCH_STATE_PENDING],
                activeTab: 0,
                matches: [] as Match[],
                loading: false,
                pagination: {
                    [SharedConstants.DATING_MATCH_STATE_UPCOMING]: {
                        page: 1,
                        pageSize: SharedConstants.DATING_MATCHES_PAGE_SIZE,
                        totalRecords: 0,
                        currentPage: 1,
                    },
                    [SharedConstants.DATING_MATCH_STATE_PENDING]: {
                        page: 1,
                        pageSize: SharedConstants.DATING_MATCHES_PAGE_SIZE,
                        totalRecords: 0,
                        currentPage: 1,
                    },
                },
                userMatchesStats: {
                    pendingChats: 0,
                    upcomingChats: 0,
                },
            };
        },
        mounted() {
            this.fetchMatches();
        },

        computed: {},

        methods: {
            async fetchMatches(page: number = 1) {
                this.loading = true;
                const state = this.tabs[this.activeTab] as 'upcoming' | 'pending';
                var pagination = this.pagination[state];
                try {
                    const query = {
                        state: this.activeTab === 0 ? 'upcoming' : 'pending',
                        page: pagination.page,
                        pageSize: pagination.pageSize,
                    };
                    const res = (await ApiUtils.apiWrapper(DatingService.getMatches, query)) as GetMatchesResponse;
                    if (!!res && res?.matches && res?.matches?.length > 0) {
                        this.matches = res.matches;
                    } else {
                        this.matches = [];
                    }
                    const statsResponse = (await ApiUtils.apiWrapper(DatingService.getMatchesStats, {
                        userId: this.$store.state.userId,
                    })) as GetMatchesStatsResponse;

                    if (statsResponse.pending !== undefined) {
                        this.userMatchesStats.pendingChats = statsResponse.pending;
                    }

                    if (statsResponse.upcoming !== undefined) {
                        this.userMatchesStats.upcomingChats = statsResponse.upcoming;
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                } finally {
                    this.loading = false;
                }
            },
            tabTitle(tab: string) {
                switch (tab) {
                    case SharedConstants.DATING_MATCH_STATE_UPCOMING:
                        return 'UPCOMING';
                    case SharedConstants.DATING_MATCH_STATE_PENDING:
                        return 'PENDING';
                    default:
                        return 'Unknown';
                }
            },
            showPagination() {
                return this.matches && this.matches?.length;
            },
            /**
             * Returns a string representing the pagination information.
             * @returns {string} The pagination information.
             */
            getPaginationInfo(): string {
                const pagination = this.getPagination();
                var showing = (pagination.page - 1) * pagination.pageSize + 1;
                return (
                    'Showing ' +
                    showing +
                    ' - ' +
                    Math.min(pagination.page * pagination.pageSize, pagination.totalRecords) +
                    ' of ' +
                    pagination.totalRecords +
                    ' matches.'
                );
            },
            getPagination() {
                const state = this.tabs[this.activeTab] as 'upcoming' | 'pending';
                return this.pagination[state];
            },
            async handlePageChange(page: number) {
                // Update the page number and perform the search again
                const state = this.tabs[this.activeTab] as 'upcoming' | 'pending';
                this.pagination[state].page = page;
                await this.fetchMatches();
            },
            goToChat(match: Match) {
                this.$router.push({
                    name: constants.ROUTE_USER_DATE,
                    query: { matchId: match.matchId?.toString() },
                });
            },
        },
    });
