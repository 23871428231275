import { render, staticRenderFns } from "./NotificationPhoneFlagged.vue?vue&type=template&id=025b125f&scoped=true&"
import script from "./NotificationPhoneFlagged.vue?vue&type=script&lang=ts&"
export * from "./NotificationPhoneFlagged.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "025b125f",
  null
  
)

export default component.exports