
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { log } from '@/tsfiles/errorlog';
    import config from '@/config';
    import * as constants from '@/tsfiles/constants';
    import * as analytics from '@/tsfiles/analytics';
    import { getAdChannelQueryParams } from '@/tsfiles/adchannel';
    import VueConstants from '@/components/VueConstants';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import { Utils } from '@/tsfiles/utils';
    import HeaderMenu from '@/components/HeaderMenu.vue';
    import Smarty from '@/components/Smarty.vue';
    import Avatar from '@/components/Avatar.vue';
    import Notifications from '@/components/Notifications.vue';
    import { Notification, NotificationList, SharedConstants } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'StickyHeader',

        components: {
            'header-menu': HeaderMenu,
            'ui-smarty': Smarty,
            'url-avatar': Avatar,
            'notification-list': Notifications,
        },

        props: {
            // List of notifications
            notificationList: {
                type: Object,
                required: false,
            } as PropOptions<NotificationList>,
        },

        data() {
            return {
                notifications: undefined as Notification | undefined,
                selectedBtn: undefined as number | undefined,
                notificationAnimation: false,
                smartyHack: '', // Resets input once user makes selection'

                showAppDownload: this.$store.getters.hideWebsiteSignin,

                integratedSmarty: true,
            };
        },

        watch: {
            notificationList: {
                immediate: true,
                deep: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.animateNotificationsIfNewAdded(newVal, oldVal);
                    }
                },
            },
        },

        computed: {
            // Returns true if it's our webview inside our app.  This does not happen for Lola
            showHeader(): boolean {
                return !Utils.InsideApp(navigator.userAgent);
            },

            // Returns true if running within a mobile web browser
            mobileWeb(): boolean {
                return Utils.MobileWebIos(navigator.userAgent) || Utils.MobileWebAndroid(navigator.userAgent);
            },

            isProduction(): boolean {
                return config.runEnvironment === 'prod';
            },

            runtimeEnvironment(): string {
                return config.runEnvironment;
            },

            //
            // navbarVariant sets the background color, using variant, for the header.
            //
            navbarVariant(): string {
                //
                // If an admin is impersonating another user, make the variant "danger", just to let the
                // admin know, visually.
                //
                if (this.$store.getters.isSignedIn && this.$store.getters.getAdminUserId) {
                    return 'danger';
                }

                //
                // If not running in production, change the header color so we know something's up.
                // It's easy to get messed up when everything looks the same.  This wil work in
                // dev and stage.  We could have a different color in stage, but there doesn't seem
                // a good reason to do that.  We want to keep production clean of test accounts, etc.,
                // and this seemed like it could help reduce mistakes.
                //
                if (!this.isProduction) {
                    // return 'success';
                }

                return 'page-background';
            },

            isSignedIn(): boolean {
                return this.$store.getters.isSignedIn;
            },

            showSigninAs(): boolean {
                return (
                    this.isSignedIn &&
                    (RoleUtils.IsServerSuperAdmin() || this.$store.getters.getAdminUserId !== 0) &&
                    this.$store.getters.getAuthedUserId !== this.$store.getters.getAdminUserId
                );
            },
        },

        methods: {
            btnClass(id: number): string {
                if (this.selectedBtn !== undefined && this.selectedBtn === id) {
                    return 'selected-btn';
                }
                return 'unselected-btn';
            },

            //
            // Where we send the user depends on if they are signed in and what page they are on.
            //
            logoClicked() {
                if (this.$store.getters.isSignedIn) {
                    //
                    // If on my own pages, based on userId, go to marketing page; otherwise,
                    // go to my home page.
                    //
                    const currentUserId = this.$router.currentRoute.params.userId;
                    if (this.$store.getters.isAuthedUserId(currentUserId)) {
                        this.$router.push({ name: constants.ROUTE_MARKETING });
                    } else {
                        this.$router.push({
                            name: constants.ROUTE_USER_HOME,
                            params: { userId: this.$store.getters.getAuthedUserId },
                        });
                    }
                } else if (this.$router.currentRoute.name !== constants.ROUTE_MARKETING) {
                    this.$router.push({ name: constants.ROUTE_MARKETING });
                }
            },

            // New notifications possibly came in.  If so, animate bell slider.
            // The user removing notifications will result in the 'watch' above being triggered.
            // Don't do animations if the new list contains only notifications
            // we already had.
            animateNotificationsIfNewAdded(newList: NotificationList | undefined, oldList: NotificationList | undefined) {
                let animate = newList && !oldList;

                if (newList && newList.list && oldList && oldList.list) {
                    for (const newOne of newList.list) {
                        let foundMatch = false;
                        for (const oldOne of oldList.list) {
                            if (oldOne.notificationId === newOne.notificationId && oldOne.count === newOne.count) {
                                foundMatch = true;
                                break;
                            }
                        }

                        if (!foundMatch) {
                            animate = true;
                            break;
                        }
                    }
                }

                if (animate) {
                    this.notificationAnimation = true;
                }
            },

            //
            // Called by the notification component when animation is complete.
            // We need to know this in order to reset our flag, which will
            // be used to trigger animation.
            //
            notificationAnimationComplete() {
                this.notificationAnimation = false;
            },

            smartySelection(data: any) {
                const user = data.newVal;

                if (user && user.userId) {
                    analytics.logAppInteraction(analytics.ANALYTICS_ACTION_SEARCH, user.userId);

                    this.$router.push({
                        name: constants.ROUTE_USER_HOME,
                        params: { userId: user.userId },
                    });
                }

                // Trick smarty into erasing the input
                this.smartyHack = ' ';
                this.$nextTick(() => {
                    this.smartyHack = '';
                });
            },

            userSignin() {
                if (this.$router.currentRoute.name !== constants.ROUTE_FIREBASE_SIGNIN) {
                    this.$router.replace({ name: constants.ROUTE_FIREBASE_SIGNIN });
                }
            },

            gotoSearch() {
                if (this.$router.currentRoute.name !== constants.ROUTE_SEARCH) {
                    this.$router.push({ name: constants.ROUTE_SEARCH });
                }
            },

            //
            // mobileDownloadLink returns a properly formated Branch.io link.
            // 'gen' is the Branch.io generic alias we use.
            //
            mobileDownloadLink(): string {
                return config.branchioLink + getAdChannelQueryParams(true);
            },

            //
            // Return the image source for app store or play store.
            //
            mobileDownloadSrc(): string {
                let ret = 'app-store-badge.png';
                if (Utils.MobileWebAndroid(navigator.userAgent)) {
                    ret = 'play-store-badge.png';
                }
                return ret;
            },

            //
            // When a user clicks on the download button, we log the analytics event.  The
            // click is usually an "@click" without ".prevent", so the href will be triggered.
            //
            mobileDownloadAnalyticsClick() {
                let type = SharedConstants.DEVICE_TYPE_IOS;
                if (Utils.MobileWebAndroid(navigator.userAgent)) {
                    type = SharedConstants.DEVICE_TYPE_ANDROID;
                }

                analytics.logAppInteraction(analytics.ANALYTICS_ACTION_APP_DOWNLOAD, type);
            },
        },
    });
