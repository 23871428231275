import { render, staticRenderFns } from "./StickyFooter.vue?vue&type=template&id=060eea40&scoped=true&"
import script from "./StickyFooter.vue?vue&type=script&lang=ts&"
export * from "./StickyFooter.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "060eea40",
  null
  
)

export default component.exports