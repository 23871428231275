
    import { DateTime as LuxonDateTime } from 'luxon';
    import mixins from 'vue-typed-mixins';
    import { PropOptions, defineComponent } from 'vue';

    import { DatingService, DatingScorecard } from '@bostonventurestudio/lola-api';
    import VueConstants from '@/components/VueConstants';

    export default mixins(VueConstants).extend({
        name: 'Scorecard',

        components: {},

        props: {
            scorecard: {
                type: Object,
                required: true,
            } as PropOptions<DatingScorecard>,
        },
        data() {
            return {
                maxScore: 5,
                scorecardTabs: [
                    { title: 'Positives', scores: this.scorecard.likeScores, active: true },
                    { title: 'Negatives', scores: this.scorecard.dislikeScores, active: false },
                ],
            };
        },
        computed: {
            startDate(): string {
                return this.formatDate(this.scorecard.startDate);
            },
            endDate(): string {
                return this.formatDate(this.scorecard.endDate);
            },
        },
        methods: {
            formatDate(date: string | undefined): string {
                if (!date) return '';

                const parsedDate = LuxonDateTime.fromISO(date);
                if (parsedDate.year === LuxonDateTime.local().year) {
                    return parsedDate.toFormat('MMM d');
                } else {
                    return parsedDate.toFormat('MMM d, yyyy');
                }
            },
        },
    });
