
    import Vue from 'vue';
    import config from '@/config';
    import { getAdChannelQueryParams } from '@/tsfiles/adchannel';
    import Marketing from '@/components/Marketing.vue';

    export default Vue.extend({
        name: 'QrcodeApp',

        components: {
            'marketing-ui': Marketing,
        },

        props: {},

        data() {
            return {
                qrcodeUrl: '',
            };
        },

        mounted() {
            //
            // Use adChannel info to generate qrcode url
            //
            this.qrcodeUrl = config.branchioLink + getAdChannelQueryParams(true);
        },

        watch: {},

        computed: {},

        methods: {},
    });
