//
// Copyright 2022-2023 Lola - All rights reserved.
// File: domainblock.ts
// Project: lola
//
// Right now the list is hardcoded.  If needed in the future, add
// a block map, with type, fromWhere, etc. to the database.  This
// matches the list on the server.  We may also consider a one-time
// pull from the server?
//
// WARNING: Until the list is in the DB and/or sync'ed with server,
// if you edit this, edit domainblock.go as well.
//
export const domainWildcardBlockMap = new Map([
    ['gmail', true],
    ['yahoo', true],
    ['hotmail', true],
    ['aol', true],
]);

export const domainExactBlockMap = new Map([
    // cspell:disable
    ['icloud.com', true],
    ['msn.com', true],
    ['wanadoo.fr', true],
    ['orange.fr', true],
    ['comcast.net', true],
    ['live.com', true],
    ['rediffmail.com', true],
    ['free.fr', true],
    ['gmx.de', true],
    ['web.de', true],
    ['yandex.ru', true],
    ['ymail.com', true],
    ['libero.it', true],
    ['outlook.com', true],
    ['uol.com.br', true],
    ['bol.com.br', true],
    ['mail.ru', true],
    ['cox.net', true],
    ['sbcglobal.net', true],
    ['sfr.fr', true],
    ['live.fr', true],
    ['verizon.net', true],
    ['live.co.uk', true],
    ['googlemail.com', true],
    ['ig.com.br', true],
    ['live.nl', true],
    ['bigpond.com', true],
    ['terra.com.br', true],
    ['neuf.fr', true],
    ['alice.it', true],
    ['rocketmail.com', true],
    ['att.net', true],
    ['laposte.net', true],
    ['facebook.com', true],
    ['bellsouth.net', true],
    ['charter.net', true],
    ['rambler.ru', true],
    ['tiscali.it', true],
    ['shaw.ca', true],
    ['sky.com', true],
    ['earthlink.net', true],
    ['optonline.net', true],
    ['freenet.de', true],
    ['t-online.de', true],
    ['aliceadsl.fr', true],
    ['virgilio.it', true],
    ['home.nl', true],
    ['qq.com', true],
    ['telenet.be', true],
    ['me.com', true],
    ['tiscali.co.uk', true],
    ['voila.fr', true],
    ['gmx.net', true],
    ['mail.com', true],
    ['planet.nl', true],
    ['tin.it', true],
    ['live.it', true],
    ['ntlworld.com', true],
    ['arcor.de', true],
    ['frontiernet.net', true],
    ['hetnet.nl', true],
    ['live.com.au', true],
    ['zonnet.nl', true],
    ['club-internet.fr', true],
    ['juno.com', true],
    ['optusnet.com.au', true],
    ['blueyonder.co.uk', true],
    ['bluewin.ch', true],
    ['skynet.be', true],
    ['sympatico.ca', true],
    ['windstream.net', true],
    ['mac.com', true],
    ['centurytel.net', true],
    ['chello.nl', true],
    ['live.ca', true],
    ['aim.com', true],
    ['bigpond.net.au', true],
    // cspell:enable
]);
