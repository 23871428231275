
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import UserProfile from '@/components/user/UserProfile.vue';

    export default mixins(VueConstants).extend({
        name: 'UserProspectContainer',

        components: {
            'user-profile': UserProfile,
        },

        data() {
            return {
                showNoteModal: false,
                note: '',
            };
        },
        props: {
            gettingProspectsForAnotherUser: {
                type: Boolean,
                required: true,
            },
            forceMatch: {
                type: Function,
                required: true,
            },
            gotoPreviousUser: {
                type: Function,
                required: true,
            },
            allowUndo: {
                type: Boolean,
                required: true,
            },
            isFirst: {
                type: Function,
                required: true,
            },
            isLast: {
                type: Function,
                required: true,
            },
            showPrevious: {
                type: Function,
                required: true,
            },
            showNext: {
                type: Function,
                required: true,
            },
            userIdToDisplay: {
                type: Number,
                required: true,
            },
            likeUser: {
                type: Function,
                required: false,
            },
            unlikeUser: {
                type: Function,
                required: false,
            },
            redoLikeUser: {
                type: Function,
                required: false,
            },
            redoUnlikeUser: {
                type: Function,
                required: false,
            },
            allowSwipeNote: {
                type: Boolean,
                required: false,
                default: true,
            },
            allowForceMatch: {
                type: Boolean,
                required: false,
                default: true,
            },
            scrollable: {
                type: Boolean,
                required: false,
                default: false,
            },
            message: {
                type: String,
                required: false,
            },
        },

        methods: {
            likeAction() {
                if (this.allowSwipeNote) {
                    this.showNoteModal = true;
                } else {
                    this.submitLike(false);
                }
            },
            submitLike(addNote: boolean) {
                const note = addNote ? this.note : ''; // Use the note or an empty string
                this.showNoteModal = false;
                this.note = '';

                if (this.likeUser) {
                    this.likeUser(note);
                } else if (this.redoLikeUser) {
                    this.redoLikeUser(note);
                }
            },
            unlikeAction() {
                if (this.unlikeUser) {
                    this.unlikeUser(this.note || '');
                } else {
                    if (this.redoUnlikeUser) {
                        this.redoUnlikeUser(this.note || '');
                    }
                }
            },
        },
    });
