
    import Vue, { PropOptions } from 'vue';
    import { DateTime as LuxonDateTime } from 'luxon';
    import Avatar from '@/components/Avatar.vue';
    import { CalendarEntry } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'DatingCalendarRow',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            calendarEntry: {
                type: Object,
                required: true,
            } as PropOptions<CalendarEntry>,
            appearDisabled: {
                type: Boolean,
                default: false,
                required: false,
            } as PropOptions<boolean>,
            partnerIsAvailableMessage: {
                type: String,
                default: 'Partner is available',
                required: false,
            } as PropOptions<string>,
        },

        data() {
            return {
                headerText: '',
                monthDayText: '',
                checked: false,
            };
        },

        watch: {
            calendarEntry: {
                immediate: true,
                deep: true,
                handler(newVal: CalendarEntry, oldVal: CalendarEntry) {
                    if (newVal && newVal.date) {
                        this.checked = !!newVal.available;

                        const lDate = LuxonDateTime.fromISO(newVal.date);
                        if (newVal.tonight) {
                            this.headerText = 'Tonight';
                        } else if (newVal.tomorrow) {
                            this.headerText = 'Tomorrow';
                        } else {
                            this.headerText = lDate.toFormat('EEEE');
                        }

                        this.monthDayText = lDate.toFormat('MMMM d');
                    }
                },
            },
        },

        computed: {
            getTextVariant(): string {
                if (this.calendarEntry.readOnly) {
                    return 'info';
                }

                return 'white';
            },
        },

        methods: {},
    });
