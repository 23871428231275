var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.notificationList && _vm.notifications.length > 0)?_c('div',{staticClass:"d-flex flex-row",attrs:{"id":"headerParentSpecialPopoverContainer"}},[_c('div',{ref:"slider",staticClass:"d-flex flex-row mr-1",attrs:{"id":"slider"}},[_vm._m(0),_c('div',{staticClass:"d-flex flex-row my-auto py-1 border-left text-truncate"},[(
                    _vm.notifications[0].creator &&
                    _vm.notifications[0].creator.profile &&
                    _vm.notifications[0].creator.avatar &&
                    _vm.notifications[0].creator.avatar.imageUrl
                )?_c('url-avatar',{staticClass:"ml-2 slider-avatar",attrs:{"url":_vm.getNotificationImageUrl(_vm.notifications[0])}}):_c('url-avatar',{staticClass:"ml-2 slider-avatar-no-border-no-rounding",attrs:{"url":_vm.getNotificationImageUrl(_vm.notifications[0])}}),_c('div',{staticClass:"d-flex flex-row w-100 mx-1 text-truncate"},[_c('span',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.getShortNotificationString(_vm.notifications[0]))+" ")])])],1)]),_c('b-popover',{ref:"notificationPopover",staticClass:"m-0 p-0",attrs:{"target":"notificationIcon","container":"headerParentSpecialPopoverContainer","placement":"bottomleft","triggers":"click blur"}},[_c('div',{staticClass:"w-100 p-2 border-bottom border-secondary"},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.clearNotifications.apply(null, arguments)}}},[_vm._v("Mark all as read")])],1),_vm._l((_vm.notifications),function(n,idx){return _c('div',{key:idx,staticClass:"d-flex flex-row m-0 px-1 py-1 border-bottom border-secondary align-items-center"},[(n.creator)?_c('url-avatar',{staticClass:"global-mini-avatar",attrs:{"url":_vm.getNotificationImageUrl(n)}}):_c('url-avatar',{staticClass:"global-mini-avatar-no-border-no-rounding",attrs:{"url":_vm.getNotificationImageUrl(n)}}),_c('div',{staticClass:"text-truncate"},[_c('b-link',{staticClass:"p-1",on:{"click":function($event){$event.preventDefault();return _vm.notificationClick(n)}}},[_vm._v(" "+_vm._s(_vm.getLongNotificationString(n))+" ")])],1)],1)}),(_vm.moreToShow)?_c('div',{staticClass:"p-2"},[_c('b-link',{on:{"click":function($event){$event.preventDefault();return _vm.seeAllNotifications()}}},[_vm._v("See all...")])],1):_vm._e()],2),_vm._m(1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pl-1 pr-2 pt-1"},[_c('span',{staticClass:"fas fa-bell bell-style text-black"}),_c('span',{staticClass:"fas fa-circle overlap-notification-bullet text-red"})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"animated-notification mx-1 mx-sm-2"},[_c('a',{staticClass:"fas fa-bell bell-style text-black pt-1",attrs:{"tabindex":"0","role":"button","id":"notificationIcon"}}),_c('span',{staticClass:"fas fa-circle overlap-notification-bullet text-red"})])
}]

export { render, staticRenderFns }