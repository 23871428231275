
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import VerifyEmail from '@/generic-modals/VerifyEmail.vue';
    import VerifyPhone from '@/generic-modals/VerifyPhone.vue';

    export default mixins(VueConstants).extend({
        name: 'PhoneEmailNeedVerification',

        components: {
            'verify-email': VerifyEmail,
            'verify-phone': VerifyPhone,
        },

        props: {},

        data() {
            return {
                showPhoneVerifyModal: false,
                showEmailVerifyModal: false,
            };
        },

        watch: {},

        computed: {},

        methods: {
            //
            // Once we have verification, check to see if we can remove this page message
            //
            verificationComplete() {
                this.showPhoneVerifyModal = false;
                this.showEmailVerifyModal = false;

                if (this.$store.getters.getAuthedUserValue('phoneVerified') && this.$store.getters.getAuthedUserValue('emailVerified')) {
                    this.$store.commit('clearPageMessage', constants.FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED);
                }
            },
        },
    });
