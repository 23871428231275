
    import Vue from 'vue';

    export default Vue.extend({
        name: 'ModalAlert',

        props: {
            // When to show/hide modal
            showModal: {
                type: Boolean,
                required: true,
            },

            // Header text from caller.  If not supplied, the header is hidden
            headerText: {
                type: String,
                required: false,
                default: '',
            },

            // Modal size override.  Empty string is normal.
            modalSize: {
                type: String,
                required: false,
                default: '',
            },

            // What text to show on the modal alert?
            alertText: {
                type: String,
                required: true,
            },

            // Label of ok button
            okTitle: {
                type: String,
                required: false,
                default: 'OK',
            },

            // Label of cancel button
            cancelTitle: {
                type: String,
                required: false,
                default: 'CANCEL',
            },

            // Does the caller want the cancel button?
            showCancel: {
                type: Boolean,
                required: false,
                default: false,
            },
        },
    });
