
    import Vue from 'vue';
    import ServerStats from '@/components/admin/ServerStats.vue';

    export default Vue.extend({
        name: 'StatsAdmin',

        components: {
            'stats-admin': ServerStats,
        },

        props: {},

        data() {
            return {};
        },

        computed: {},

        methods: {},
    });
