//
// Copyright 2022-2023 Lola - All rights reserved.
// File: enums.ts
// Project: lola
//

export enum SigninStatus {
    UNKNOWN,
    SIGNEDIN,
    SIGNEDOUT,
}
