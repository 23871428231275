//
// Copyright 2023-2023 Lola - All rights reserved.
// File: notificationutils.ts
// Project: lola
//

import store from './store';
import router from './router';
import * as constants from '@/tsfiles/constants';
import { PageMessage } from '@/tsfiles/interfaces';
import { SharedConstants, Notification } from '@bostonventurestudio/lola-api';
import { logInvalidParams } from './errorlog';

export interface NotificationString {
    short: string;
    long: string;
}

//
// Returns a short and long version of the text to display for a notification.
// These are used by the header notification control.  The short version is
// used within header notification slider.
// If there's no displayText in the message, send back the short version.
export function getNotificationStrings(msg: Notification): NotificationString {
    let retShort = '';
    let retLong = '';
    if (msg.displayText) {
        retLong = msg.displayText;

        // Add counter to the end of the long string if there is one.
        if (msg.count && msg.count > 1) {
            retLong += ' (' + msg.count + ')';
        }
    }

    // Get the data from the message, in case one of the types need values within it.
    let data = undefined as any | undefined;
    if (msg.jsonData) {
        data = JSON.parse(msg.jsonData);
    }

    switch (msg.event) {
        case SharedConstants.NOTIFICATION_VERIFY_EMAIL:
            retShort = 'Verify email';
            break;
        case SharedConstants.NOTIFICATION_VERIFY_PHONE:
            retShort = 'Verify phone';
            break;
        case SharedConstants.NOTIFICATION_EMAIL_FLAGGED:
            retShort = 'Invalid email';
            break;
        case SharedConstants.NOTIFICATION_PHONE_FLAGGED:
            retShort = 'Invalid phone';
            break;
        case SharedConstants.NOTIFICATION_NEW_CHAT_POST:
            retShort = 'New chat post';
            break;
        case SharedConstants.NOTIFICATION_LIKE_RECEIVED:
            retShort = 'Pending Date';
            break;
        case SharedConstants.NOTIFICATION_NEW_DATE_MATCH:
            retShort = 'Its a Date';
            break;
        case SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH:
            retShort = 'Date request accepted';
            break;
        case SharedConstants.NOTIFICATION_DATE_UNMATCHED:
        case SharedConstants.NOTIFICATION_DATE_DELETED_ACCOUNT:
            retShort = 'Date cancelled';
            break;
        case SharedConstants.NOTIFICATION_DATE_REMINDER:
            retShort = 'Date reminder';
            break;
        case SharedConstants.NOTIFICATION_DATE_RESCHEDULED:
            retShort = 'Date rescheduled';
            break;
        case SharedConstants.NOTIFICATION_POST_DATE_SURVEY:
            retShort = 'Date feedback';
            break;
        case SharedConstants.NOTIFICATION_AVAILABILITY_RECEIVED:
            retShort = 'Pick a day';
            break;
        case SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED:
            retShort = 'Digits incoming';
            break;
        case SharedConstants.NOTIFICATION_NEW_SCORECARD:
            retShort = 'New scorecard';
            break;
    }

    // If no long string, use the short string.
    if (retLong === '') {
        retLong = retShort;
    }

    return { short: retShort, long: retLong } as NotificationString;
}

//
// When a user clicks on a notification message, on the header menu or within
// the notifications page, route the user to the appropriate page.
// NOTE: this function does not mark the notification as read.  That's up to the caller.
//
export function notificationClickHandler(msg: Notification) {
    if (!store.getters.getAuthedUserId || store.getters.isAuthedUserId(0)) {
        logInvalidParams('notificationClickHandler', 'store userId is undefined');
        return;
    }

    const currentUserIdAsString = store.getters.getAuthedUserId.toString();

    // Get the json data from the message
    let data = undefined as any | undefined;
    if (msg.jsonData) {
        data = JSON.parse(msg.jsonData);
    }

    const matchId = data && data.matchId ? data.matchId.toString() : '0';

    switch (msg.event) {
        case SharedConstants.NOTIFICATION_VERIFY_EMAIL:
        case SharedConstants.NOTIFICATION_EMAIL_FLAGGED:
            //
            // Save page message in case user leaves settings page, which we
            // will send them to if they are not already there.
            //
            store.commit('setPageMessage', {
                notification: msg,
                targetPages: [constants.ROUTE_USER_HOME],
            } as PageMessage);

            router.push({
                name: constants.ROUTE_USER_SETTINGS,
                params: { userId: currentUserIdAsString },
            });
            break;
        case SharedConstants.NOTIFICATION_VERIFY_PHONE:
        case SharedConstants.NOTIFICATION_PHONE_FLAGGED:
            store.commit('setPageMessage', {
                notification: msg,
            } as PageMessage);

            router.push({
                name: constants.ROUTE_USER_SETTINGS,
                params: { userId: currentUserIdAsString },
            });
            break;
        case SharedConstants.NOTIFICATION_NEW_DATE_MATCH:
        case SharedConstants.NOTIFICATION_NEW_PENDING_DATE_MATCH:
        case SharedConstants.NOTIFICATION_NEW_CHAT_POST:
        case SharedConstants.NOTIFICATION_AVAILABILITY_RECEIVED:
        case SharedConstants.NOTIFICATION_PHONE_NUMBER_RECEIVED:
        case SharedConstants.NOTIFICATION_DATE_REMINDER:
            router.push({
                name: constants.ROUTE_USER_DATE,
                query: { matchId },
            });
            break;
        case SharedConstants.NOTIFICATION_LIKE_RECEIVED:
            router.push({
                name: constants.ROUTE_USER_PENDING_PROPOSALS,
                params: { userId: currentUserIdAsString },
            });
            break;
        case SharedConstants.NOTIFICATION_DATE_RESCHEDULED:
        case SharedConstants.NOTIFICATION_DATE_UNMATCHED:
        case SharedConstants.NOTIFICATION_DATE_DELETED_ACCOUNT:
            router.push({
                name: constants.ROUTE_USER_CALENDAR,
                params: { userId: currentUserIdAsString },
            });
            break;
        case SharedConstants.NOTIFICATION_POST_DATE_SURVEY:
            router.push({
                name: constants.ROUTE_USER_POST_DATE_SURVEYS,
            });
            break;
        case SharedConstants.NOTIFICATION_NEW_SCORECARD:
            router.push({
                name: constants.ROUTE_USER_DATING_SCORECARDS,
                params: { userId: currentUserIdAsString },
            });
            break;
    }
}
