
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { TimeUtils } from '@/tsfiles/timeutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { SharedConstants, AdminService, PublicUser, Ticket } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserTickets',

        components: {},

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,
            reporter: {
                type: Boolean,
                required: false,
                default: false,
            } as PropOptions<boolean>,
        },

        data() {
            return {
                reports: {} as Ticket[],

                ticketFields: [
                    { key: 'id', tdClass: 'align-middle' },
                    { key: 'status', tdClass: 'align-middle' },
                    { key: 'source', tdClass: 'align-middle' },
                    { key: 'userNote', tdClass: 'align-middle' },
                    { key: 'age', tdClass: 'align-middle' },
                ],
                allItems: [] as any[],
                filterItemsType: '',
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: PublicUser, oldVal: PublicUser) {
                    if (newVal && newVal !== oldVal) {
                        this.fetchTickets();
                    }
                },
            },
        },

        computed: {},

        methods: {
            async fetchTickets() {
                if (!this.user || !this.user.userId) {
                    logInvalidParams(this.$options.name, 'fetchTickets');
                    return;
                }

                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.getTicketsWithUserAsReporterOrRecipient, this.user.userId);

                    if (ret && ret.list) {
                        Vue.set(this, 'reports', ret.list);
                    } else {
                        Vue.set(this, 'reports', [] as Ticket[]);
                    }

                    this.buildItemList();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            buildItemList() {
                if (this.reports.length === 0) {
                    this.allItems = [] as any[];
                    return;
                }

                const newItems = [] as any[];
                for (const item of this.reports) {
                    const reported = item.reportingUser?.userId === this.user.userId;
                    if (reported !== this.reporter) continue;
                    switch (this.filterItemsType) {
                        case 'all':
                            break;
                        case SharedConstants.TICKET_STATUS_OPEN:
                        case SharedConstants.TICKET_STATUS_INVESTIGATING:
                        case SharedConstants.TICKET_STATUS_RESOLVED:
                            if (item.status !== this.filterItemsType) {
                                continue;
                            }
                            break;
                        default:
                            break;
                    }

                    // Get the full name for display
                    let fullName = item.recipientUser?.firstName;
                    if (item.recipientUser?.lastName && item.recipientUser?.lastName !== '') {
                        fullName += ' ' + item.recipientUser?.lastName;
                    }

                    //
                    // Push the items onto openItems, which is what will show up in the table (if
                    // there is a field for that item).  Hidden fields, such as ticketId,
                    // are used during openItemClicked().
                    //
                    newItems.push({
                        ticketId: item.ticketId,
                        status: item.status,
                        source: item.source,
                        userNote: item.userNote,
                        age: TimeUtils.pastTimeDistanceStringWithoutHelperWords(item.created),
                    });
                }

                this.allItems = newItems;
            },

            setItemsFilterType(type: string) {
                this.filterItemsType = type;
                this.buildItemList();
            },

            ticketClicked(event: Ticket) {
                // Don't go to same ticket we are currently looking at.
                if (this.$router.currentRoute.name === constants.ROUTE_ADMIN_TICKET) {
                    const queryTicketId = Number(this.$router.currentRoute.query.ticketId);
                    if (queryTicketId === event.ticketId) {
                        return; // Already on the page
                    }
                }

                this.$router.push({
                    path: '/' + this.$store.getters.getAuthedUserId + '/admin/ticket?ticketId=' + event.ticketId,
                });
            },
        },
    });
