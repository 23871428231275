/*
 * Copyright 2022-2023 Lola - All rights reserved.
 * File: eventbus.ts
 * Project: lola
 */

import Vue from 'vue';

//
// google:any was added here to make vetur understand
// window.google.maps.Map.  Google's typescript
// npm didn't help.  This doesn't really belong in the
// eventbus ts, but it solves the vetur complaint.
//
declare global {
    interface Window {
        VueEventBus: any;
        google: any;
        webkit: any;
        android: any;
    }
}

const EventBus = new Vue();
window.VueEventBus = EventBus;
export default EventBus;
