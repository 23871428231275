
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import { DateTime as LuxonDateTime } from 'luxon';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import VueConstants from '@/components/VueConstants';
    import UserProfile from '@/components/user/UserProfile.vue';
    import UserChat from '@/components/user/UserChat.vue';
    import DatingMatchCalendarModel from '@/components/dating/DatingMatchCalendarModel.vue';
    import CollectDateInput from '@/generic-modals/CollectDateInput.vue';
    import {
        SharedConstants,
        UserService,
        PublicUser,
        DatingService,
        Match,
        GetMatchRequest,
        GetMatchResponse,
        WipeMatchRequest,
        BackdateMatchRequest,
        RescheduleRequest,
        RescheduleResponse,
    } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'UserDate',

        components: {
            'user-profile': UserProfile,
            'user-chat': UserChat,
            'collect-date-input': CollectDateInput,
            'user-match-calendar-model': DatingMatchCalendarModel,
        },

        props: {},

        data() {
            return {
                match: undefined as Match | undefined,
                user: undefined as PublicUser | undefined,

                currentTab: 0,
                currentNavigation: { page: constants.ROUTE_USER_DATE } as PageNavigationData,
                lastActive: undefined as String | undefined,
                showPastDateSelectorModal: false,
                pastDate: new Date().toISOString().split('T')[0],

                showMatchCalendar: false,
            };
        },

        watch: {
            '$route.query.matchId': {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal && newVal !== '') {
                        this.fetchMatch(Number(newVal));
                    }
                },
            },
        },

        mounted() {
            //
            // If page navigation contains data, process.
            //
            const nav = this.$store.getters.getPageNavigation(constants.ROUTE_USER_DATE);
            if (nav && nav.tab) {
                this.$nextTick(() => {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                });
            }
        },

        beforeDestroy() {
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {
            isPendingMatch(): boolean {
                return this.match?.date === undefined;
            },
            // Return the user's name.  Combined with lastname if given.
            getDisplayName(): string {
                return Utils.FormatUserNameForDisplay(this.user?.firstName, this.user?.lastName);
            },
            dayOfDate(): string {
                if (this.match?.date == undefined) return 'Pending';

                const lDate = LuxonDateTime.fromISO(this.match?.date);
                return lDate.toFormat('MMMM d');
            },
        },

        methods: {
            async fetchMatch(matchId: number) {
                try {
                    const res = (await ApiUtils.apiWrapper(DatingService.getMatch, {
                        matchId,
                    } as GetMatchRequest)) as GetMatchResponse;

                    if (!res || !res.match || res.match.matchId === 0) {
                        logInvalidResponse(this.$options.name, 'fetchMatch', matchId);
                        return;
                    }

                    this.match = res.match;
                    this.fetchUser(this.match.partnerUserId as number);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchUser(partnerUserId: number) {
                try {
                    this.user = await ApiUtils.apiWrapper(UserService.getPublicUserById, partnerUserId);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async forceUnmatch(type: number) {
                if (!this.match || this.match.matchId === 0) {
                    logInvalidParams(this.$options.name, 'forceUnmatch');
                    return;
                }

                let reason = SharedConstants.DATING_UNMATCH_REASON_NOT_INTERESTED;
                if (type === 2) {
                    reason = SharedConstants.DATING_UNMATCH_REASON_RESCHEDULE;
                } else if (type === 3) {
                    reason = SharedConstants.DATING_UNMATCH_REASON_OFFENSIVE;
                }

                try {
                    await ApiUtils.apiWrapper(DatingService.unmatch, { matchId: this.match.matchId, reason });

                    this.$router.replace({
                        name: constants.ROUTE_USER_CALENDAR,
                        params: { userId: this.$store.getters.getAuthedUserId },
                    });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            async reschedule() {
                if (!this.match || this.match.matchId === 0) {
                    logInvalidParams(this.$options.name, 'reschedule');
                    return;
                }

                try {
                    const res = (await ApiUtils.apiWrapper(DatingService.reschedule, {
                        matchId: this.match.matchId,
                    } as RescheduleRequest)) as RescheduleResponse;

                    if (res && res.match && res.match.matchId) {
                        this.goToChat(res.match);
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            goToChat(match: Match) {
                this.$router.push({
                    name: constants.ROUTE_USER_DATE,
                    query: { matchId: match.matchId?.toString() },
                });
            },

            async deleteMatch() {
                if (!this.match || !this.match.matchId) {
                    logInvalidParams(this.$options.name, 'deleteMatch', this.match);
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(DatingService.wipeMatch, {
                        matchId: this.match.matchId,
                    } as WipeMatchRequest);
                    this.$router.replace({ name: constants.ROUTE_USER_CALENDAR });
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async backdateMatch(date: string | undefined) {
                if (!this.match || this.match.matchId === 0) {
                    logInvalidParams(this.$options.name, 'backdateMatch');
                    return;
                }

                try {
                    let query = { matchId: this.match.matchId } as BackdateMatchRequest;
                    if (date) query.date = date;
                    console.log('backdateMatch', date, 'query', query);

                    const ret = await ApiUtils.apiWrapper(DatingService.backdateMatch, query);

                    if (!ret || !ret.match) {
                        logInvalidResponse(this.$options.name, 'backdateMatch', this.match.matchId);
                        return;
                    }

                    // Go to past dates screen
                    this.$router.replace({
                        name: constants.ROUTE_USER_PAST_DATES,
                        params: { userId: this.$store.getters.getAuthedUserId },
                    });
                } catch (error: any) {
                    if (error?.response?.data?.error == SharedConstants.WARNING_RESOURCE_USED && date) {
                        this.handleSelectedDateNotAvailable();
                        return;
                    }
                    Utils.CommonErrorHandler(error);
                }
            },
            async handleSelectedDateNotAvailable() {
                const ret = await this.$bvModal.msgBoxConfirm(
                    'The date you selected for past date is not available. Would you like to select another date?',
                    {
                        title: 'Date Unavailable',
                        buttonSize: 'sm',
                        okVariant: 'primary',
                        okTitle: 'Yes',
                        cancelTitle: 'No',
                        cancelVariant: 'secondary',
                    },
                );
                if (ret) {
                    this.togglePastDateSelectorModal();
                }
            },
            updateLastActive(date: String) {
                if (date) this.lastActive = date;
            },
            togglePastDateSelectorModal() {
                this.showPastDateSelectorModal = !this.showPastDateSelectorModal;
            },
            sendAvailability() {
                this.showMatchCalendar = true;
            },
            onUserMatchCalendarClose() {
                this.showMatchCalendar = false;
                this.fetchMatch(this.match?.matchId as number);
            },
        },
    });
