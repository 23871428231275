
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import DotAnimation from '@/components/uiutils/DotAnimation.vue';
    import { SharedConstants, UserService } from '@bostonventurestudio/lola-api';

    const minWaitTimeForCompletionMessage = 2; // Seconds

    export default mixins(VueConstants).extend({
        name: 'VerifyEmail',

        components: {
            'dot-animation': DotAnimation,
        },

        props: {
            // Email, which should be the signed in user's email
            email: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                showEmailSending: false,
                verifyLinkSent: false,
            };
        },

        computed: {},

        methods: {
            async sendVerificationCode() {
                this.showEmailSending = true;

                //
                // We want to show the "Sending..." message for at least a few seconds;
                // otherwise the user might see the mesages flash.  We want to show something
                // in case the server takes a bit to complete.
                //
                const startTime = Math.round(new Date().getTime() / 1000);

                try {
                    //
                    // Default is to redirect back where the user is now.  The redirect is
                    // the path name, without the leading slash.
                    //
                    let redirectPath = this.$router.currentRoute.path;
                    if (redirectPath.startsWith('/')) {
                        redirectPath = redirectPath.substring(1);
                    }

                    await ApiUtils.apiWrapper(UserService.sendChannelVerification, {
                        channel: SharedConstants.CHANNEL_EMAIL,
                        redirect: redirectPath,
                    });

                    // Wait at least a few seconds, so waitingMessage isn't a quick flash
                    const nowTime = Math.round(new Date().getTime() / 1000);
                    if (nowTime - startTime < minWaitTimeForCompletionMessage) {
                        await new Promise((resolve) => setTimeout(resolve, (minWaitTimeForCompletionMessage - (nowTime - startTime)) * 1000));
                    }

                    this.verifyLinkSent = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
