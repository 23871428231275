
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import FirebaseSignin from '@/components/user/FirebaseSignin.vue';

    export default mixins(VueConstants).extend({
        name: 'SigninHome',

        components: {
            'firebase-signin': FirebaseSignin,
        },

        props: {},

        data() {
            return {};
        },

        computed: {},

        methods: {},
    });
