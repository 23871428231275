
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams, logInvalidResponse } from '@/tsfiles/errorlog';
    import PastDateRow from '@/components/dating/PastDateRow.vue';
    import PostDateSurvey from '@/components/dating/PostDateSurvey.vue';
    import { DatingService, Match, GetMatchRequest, GetMatchResponse, PostDateSurveyDefinitions } from '@bostonventurestudio/lola-api';
    import { RoleUtils } from '@/tsfiles/roleutils';

    export default Vue.extend({
        name: 'PostDateSurveys',

        components: {
            'past-date': PastDateRow,
            'post-date-survey': PostDateSurvey,
        },

        props: {},

        data() {
            return {
                matches: [] as Match[],
                definitions: undefined as PostDateSurveyDefinitions | undefined,
                fetchComplete: false,
                expandedRow: -1,
            };
        },

        watch: {},

        mounted() {
            this.loadTags();
            this.fetchSurveys();
        },

        computed: {
            hasAccess(): boolean {
                return (
                    this.$store.getters.isSignedIn &&
                    (!this.gettingRecordForAnotherUser || (this.gettingRecordForAnotherUser && RoleUtils.CanSupportUsers()))
                );
            },
            gettingRecordForAnotherUser(): boolean {
                return this.$route.params.userId != this.$store.getters.getAuthedUserId;
            },
            userId(): number {
                return parseInt(this.$route.params.userId == this.$store.getters.getAuthedUserId ? '' : this.$route.params.userId);
            },
        },

        methods: {
            async loadTags() {
                try {
                    this.definitions = await ApiUtils.apiWrapper(DatingService.getPostDateSurveyListDefinitions);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async fetchSurveys() {
                try {
                    const newList = [] as Match[];
                    let query = {};
                    if (this.userId > 0) query = { userId: this.userId };
                    const ret = await ApiUtils.apiWrapper(DatingService.getPostDateSurveysToComplete, query);
                    if (ret && ret.matchIds) {
                        for (const matchId of ret.matchIds) {
                            let matchQuery = { matchId } as GetMatchRequest;
                            if (this.userId > 0) matchQuery.userId = this.userId;

                            const res = (await ApiUtils.apiWrapper(DatingService.getMatch, matchQuery)) as GetMatchResponse;

                            if (!res || !res.match || res.match.matchId === 0) {
                                logInvalidResponse(this.$options.name, 'fetchSurveys', matchId);
                                return;
                            }

                            newList.push(res.match);
                        }
                    }

                    this.matches = newList;
                    this.fetchComplete = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            rowSelected(idx: number) {
                if (idx < 0 || idx > this.matches.length) {
                    logInvalidParams(this.$options.name, 'rowSelected');
                    return;
                }

                const item = this.matches[idx];
                if (!item || !item.matchId || !item.partnerUserId || item.partnerUserId <= 0) {
                    logInvalidParams(this.$options.name, 'rowSelected - invalid partnerUserId');
                    return;
                }

                if (this.expandedRow === idx) {
                    this.expandedRow = -1;
                } else {
                    this.expandedRow = idx;
                }
            },

            surveySubmitted() {
                this.expandedRow = -1;
                this.fetchSurveys();
            },
        },
    });
