
    import Vue from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import DatingPoolSearchFilters from '@/components/dating/DatingPoolSearchFilters.vue';
    import DatingPoolSearchResults from '@/components/dating/DatingPoolSearchResults.vue';
    import { ProspectPreview, ProspectPaginatedList, GetDatingPoolRequest, SharedConstants, DatingService } from '@bostonventurestudio/lola-api';
    import { RoleUtils } from '@/tsfiles/roleutils';

    export default Vue.extend({
        name: 'DatingPool',
        components: {
            DatingPoolSearchFilters,
            DatingPoolSearchResults,
        },
        data() {
            return {
                prospects: [] as ProspectPreview[] | undefined,
                filters: {} as GetDatingPoolRequest,
                pagination: {
                    page: 1,
                    pageSize: SharedConstants.DATING_POOL_PROSPECTS_PAGE_SIZE,
                    totalRecords: 0,
                },
                currentPage: 1,
                fetchComplete: true,
                noResult: false,
            };
        },
        computed: {
            hasAccess(): boolean {
                return this.$store.getters.isSignedIn && RoleUtils.CanSupportUsers();
            },
        },
        methods: {
            async handleSearch(filters: any) {
                filters.page = 1;
                filters.pageSize = SharedConstants.DATING_POOL_PROSPECTS_PAGE_SIZE;
                await this.fetchProspects(filters);
            },
            async handlePageChange(page: number) {
                // Update the page number and perform the search again
                var filters = this.filters;
                filters.page = page;
                await this.fetchProspects(filters);
            },
            async fetchProspects(filters: any) {
                try {
                    this.noResult = false;
                    this.filters = filters;
                    const ret = (await ApiUtils.apiWrapper(DatingService.getDatingPool, filters)) as ProspectPaginatedList;

                    if (ret && ret.list) {
                        this.prospects = ret.list;
                        this.pagination = {
                            page: ret.page ?? 1,
                            pageSize: ret.pageSize ?? SharedConstants.DATING_POOL_PROSPECTS_PAGE_SIZE,
                            totalRecords: ret.totalRecords ?? 0,
                        };
                    } else {
                        this.noResult = true;
                        this.prospects = [] as ProspectPreview[];
                        this.pagination = {
                            page: ret.page ?? 1,
                            pageSize: ret.pageSize ?? SharedConstants.DATING_POOL_PROSPECTS_PAGE_SIZE,
                            totalRecords: ret.totalRecords ?? 0,
                        };
                    }

                    this.fetchComplete = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            showPagination() {
                return this.prospects && this.prospects?.length;
            },
            /**
             * Returns a string representing the pagination information.
             * @returns {string} The pagination information.
             */
            getPaginationInfo(): string {
                var showing = (this.pagination.page - 1) * this.pagination.pageSize + 1;
                return (
                    'Showing ' +
                    showing +
                    ' - ' +
                    Math.min(this.pagination.page * this.pagination.pageSize, this.pagination.totalRecords) +
                    ' of ' +
                    this.pagination.totalRecords +
                    ' prospects.'
                );
            },
        },
    });
