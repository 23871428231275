
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import draggable from 'vuedraggable';
    import { log, logInvalidParams } from '@/tsfiles/errorlog';
    import VueConstants from '@/components/VueConstants';
    import * as analytics from '@/tsfiles/analytics';
    import { Utils } from '@/tsfiles/utils';
    import ImageUploader from 'vue-image-upload-resize';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import Avatar from '@/components/Avatar.vue';
    import { SharedConstants, AuthedUser, MediaService, Media, UserService, Profile } from '@bostonventurestudio/lola-api';

    Vue.use(ImageUploader);

    export default mixins(VueConstants).extend({
        name: 'UserPhotoSettings',

        components: {
            draggable,
            'url-avatar': Avatar,
        },

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<AuthedUser>,
        },

        data() {
            return {
                photos: [] as Media[],
                origPhotos: [] as Media[], // Used for dirty checking
                submitting: false,

                alertMessage: null as string | null,
                dismissCountDown: 0,
                permanent: false,
                alertType: 'success',
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: AuthedUser, oldVal: AuthedUser) {
                    if (newVal && newVal !== oldVal) {
                        if (newVal.publicUser?.profile && newVal.publicUser?.profile.mediaList) {
                            // Copy in photos, to both versions (used for compare)
                            this.photos = Utils.deepCopy(newVal.publicUser?.profile.mediaList);
                            this.origPhotos = Utils.deepCopy(newVal.publicUser?.profile.mediaList);
                        }
                    }
                },
            },
        },

        computed: {
            photosDirty(): boolean {
                const hasChanges = !Utils.deepEqual(this.photos, this.origPhotos);
                this.$emit('set-settings-dirty', hasChanges);
                return hasChanges;
            },
        },

        methods: {
            alertCountDownChanged(dismissCountDown: number) {
                this.dismissCountDown = dismissCountDown;
                if (dismissCountDown === 0) {
                    this.submitting = false;
                }
            },

            showAlert(message: string, alertType: string, perm: boolean) {
                this.permanent = perm;
                if (!perm) {
                    this.dismissCountDown = 5;
                }

                this.alertMessage = message;
                this.alertType = alertType;
            },

            //
            // Called when the image loader is done loading, resizing, and reorienting
            // a new image from the user.  The image is uploaded to the server, and the
            // returned Media is inserted into photos array.
            // If the media selected is a video then its not resized,
            // we'll just upload it to the server
            //
            async setImage(fileData: any) {
                // dataUrl and info are not present if image fails or if its a video
                // we will by pass logging if its a video
                if (
                    !fileData ||
                    (fileData?.type?.indexOf('video') === -1 &&
                        (!fileData.dataUrl ||
                            fileData.dataUrl === '' ||
                            !fileData.info ||
                            !fileData.info.name ||
                            fileData.info.name === '' ||
                            fileData.info.type === ''))
                ) {
                    logInvalidParams(this.$options.name, 'setImage');
                    return;
                }

                try {
                    let data;
                    if (!!fileData.dataUrl) {
                        // resized image
                        data = this.getPostDataForImage(fileData);
                    } else {
                        data = await this.getPostDataForVideo(fileData);
                    }
                    if (!data || !data.fileName || !data.contents) return; // nothing to upload

                    const res = await ApiUtils.apiWrapper(MediaService.uploadMedia, data);

                    if (res.warningCode === SharedConstants.MEDIA_INVALID_EXTENSION) {
                        const ext = fileData.info.name.slice((Math.max(0, fileData.info.name.lastIndexOf('.')) || Infinity) + 1);
                        this.showAlert('Invalid file extension (' + ext + ')', 'danger', false);
                    } else if (res.media && res.media.offensive) {
                        this.showAlert(this.getOffensiveText(res.media.offensiveReason as string), 'danger', false);
                    } else if (res.media && res.media.url) {
                        this.photos.push(res.media);
                    }

                    //
                    // Hack to get subsequent uploads of the same file to work.  This
                    // scenario can happen when uploading offensive content, then the user
                    // tries to load it again.  If we don't clear out the 'value', the
                    // input thinks it has already loaded the file.
                    //
                    const item = document.getElementsByClassName('fileinput') as HTMLCollection;
                    if (item.length > 1) {
                        const photoLoaded = item[1] as any;
                        photoLoaded.value = '';
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
            getPostDataForImage(file: any): { fileName: string; collection: string; contents: string } {
                return {
                    fileName: file.info.name,
                    collection: SharedConstants.MEDIA_COLLECTION_PROFILE,
                    contents: file.dataUrl.split(',')[1],
                };
            },
            // TODO: Add check on video max size
            async getPostDataForVideo(file: any): Promise<{ fileName: string; collection: string; contents: string }> {
                let video = document.createElement('video');
                video.preload = 'metadata';

                const durationPromise = new Promise<void>((resolve, reject) => {
                    video.onloadedmetadata = function () {
                        window.URL.revokeObjectURL(video.src);
                        const duration = video.duration;
                        if (duration > SharedConstants.MEDIA_VIDEO_MAX_DURATION_IN_SECONDS) {
                            reject('VIDEO_EXCEEDED_DURATION');
                        } else {
                            resolve();
                        }
                    };
                });
                video.src = URL.createObjectURL(file);

                try {
                    await durationPromise;

                    // read for file for content
                    const dataUrl = await this.readFileAsDataURL(file);
                    return {
                        fileName: file.name,
                        collection: SharedConstants.MEDIA_COLLECTION_PROFILE,
                        contents: dataUrl.split(',')[1],
                    };
                } catch (error) {
                    let errorMessage = 'Failed to upload video. Please check file and try again.';

                    if (error == 'VIDEO_EXCEEDED_DURATION') {
                        errorMessage =
                            'Video duration exceeds the maximum allowed limit of ' +
                            SharedConstants.MEDIA_VIDEO_MAX_DURATION_IN_SECONDS +
                            ' seconds.';
                    }
                    console.error('UserPhotoSettings.getPostDataForVideo', error, errorMessage);
                    this.showAlert(errorMessage, 'danger', false);
                }
                return {
                    fileName: '',
                    collection: '',
                    contents: '',
                };
            },

            readFileAsDataURL(file: File): Promise<string> {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = reject;
                    reader.readAsDataURL(file);
                });
            },
            isImage(item: any): boolean {
                return item && item.type === SharedConstants.MEDIA_TYPE_PHOTO;
            },
            isVideo(item: any): boolean {
                return item && item.type === SharedConstants.MEDIA_TYPE_VIDEO;
            },

            getOffensiveText(reason: string): string {
                let ret =
                    'We were not able to upload your image due to a violation of our ' +
                    '<a href="https://lola.com/photo-policy/">photo policy</a>. ' +
                    'Your photo was flagged for containing ';
                switch (reason) {
                    case SharedConstants.MEDIA_OFFENSIVE_EXPLICIT_NUDITY:
                        ret += '<b>"Explicit Nudity"</b>.';
                        break;
                    case SharedConstants.MEDIA_OFFENSIVE_VIOLENCE:
                        ret += '<b>"Violence"</b>.';
                        break;
                    case SharedConstants.MEDIA_OFFENSIVE_VISUALLY_DISTURBING:
                        ret += '<b>"Visually Disturbing Content"</b>.';
                        break;
                    case SharedConstants.MEDIA_OFFENSIVE_HATE_SYMBOLS:
                        ret += '<b>"Hate Symbols"</b>.';
                        break;
                }

                return ret;
            },

            // Delete the image from the photos array
            deleteImage(idx: number) {
                if (idx < 0 || idx >= this.photos.length) {
                    logInvalidParams(this.$options.name, 'deleteImage');
                    return;
                }

                this.photos.splice(idx, 1);
            },

            // User clicked Save, which means something changed that they wanted saved (order, new image,
            // deleted image).
            async handleSubmit() {
                if (!this.user.publicUser || !this.user.publicUser.profile) {
                    logInvalidParams(this.$options.name, 'handleSubmit');
                    return;
                }

                if (this.submitting) {
                    return;
                }

                this.submitting = true;

                try {
                    const localProfile: Profile = Utils.deepCopy(this.user.publicUser.profile);
                    localProfile.mediaList = this.photos;

                    localProfile.updateMask = {
                        paths: ['mediaList'],
                    };

                    const updatedProfile = await ApiUtils.apiWrapper(UserService.saveProfile, localProfile);
                    this.photos = Utils.deepCopy(updatedProfile.mediaList);
                    this.origPhotos = Utils.deepCopy(updatedProfile.mediaList);

                    if (this.user.userId) {
                        analytics.logAppInteraction(analytics.ANALYTICS_ACTION_UPDATE_PHOTOS, this.user.userId.toString());
                    }

                    // Tell the parent about the updates, since we need the new values to
                    // compare against, store updates, etc.
                    this.$emit('profile-updated', updatedProfile);

                    this.submitting = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
