// Copyright 2022-2023 Lola - All rights reserved.
// File: config-prod.ts
// Project: lola
//

//
// Production config overrides.  When prod it built, this file
// is copied into src/config-env.ts, via stage.Dockerfile.
//

// @ts-ignore
import config from '@/config';

// @ts-ignore
import * as constants from '@/tsfiles/constants';

export function initConfigs() {
    config.runEnvironment = 'prod';
    config.domainName = 'm.' + constants.COMPANY_DOMAIN;
    config.firebase.projectId = 'lola-production';
    config.firebase.appId = '1:266286808589:web:030a94803c129fad6a5f44'; // cspell:disable-line
    config.firebase.apiKey = 'AIzaSyBW-3FYWUGf0JVmhUfIaLbdNLLq2brFOfI'; // cspell:disable-line
    config.firebase.authDomain = 'auth.' + constants.COMPANY_DOMAIN;
    config.recaptchaSiteKey = '';
    config.segmentAnalyticsWriteKey = 'iUVYuD15rltUTZzWEap2Nhpp0tu6TebQ'; // cspell:disable-line
    config.branchioLink = 'https://mlink.lola.com/gen';
}