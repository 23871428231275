import Vue from 'vue'

import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '../../public/css/fonts-googleapis.css'
import '../../public/css/font-awesome.css'
import '../../public/css/bs-app-theme-application.css'
import '../../public/css/bs-app-theme-toolkit.min.css'

Vue.use(BootstrapVue);
