
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import VerifyEmail from '@/generic-modals/VerifyEmail.vue';

    export default mixins(VueConstants).extend({
        name: 'NotificationEmailFlagged',

        components: {
            'verify-email': VerifyEmail,
        },

        props: {},

        data() {
            return {
                showEmailVerifyModal: false,
            };
        },

        watch: {},

        computed: {},

        methods: {},
    });
