var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"autocomplete mx-0 px-0 my-0 py-0 w-100"},[_c('b-form',{attrs:{"autocomplete":"off"},on:{"submit":_vm.onSubmit}},[_c('div',{staticClass:"d-flex flex-row",class:{
                'smarty-colors': _vm.reverseColors,
                'parent-with-rounded-borders-or-search-icon':
                    _vm.roundedLeftBorders || _vm.roundedRightBorders || _vm.embeddedSearchIconRight || _vm.embeddedSearchIconLeft || _vm.embeddedMapIcon,
                'rounded-left-input-parent': _vm.roundedLeftBorders,
                'rounded-right-input-parent': _vm.roundedRightBorders,
            }},[(_vm.embeddedMapIcon)?_c('span',{staticClass:"mt-2 pt-1 ml-2 fas fa-map-marker-alt force-fontawesome-pro-family lighter-than-muted",class:{ 'text-white': _vm.reverseColors }}):(_vm.embeddedSearchIconLeft)?_c('span',{staticClass:"mt-2 pt-1 ml-2 fa-regular fa-magnifying-glass lighter-than-muted",class:{ 'text-white': _vm.reverseColors }}):_vm._e(),_c('b-form-input',{ref:"smartyInput",staticClass:"p-1",class:{
                    'smarty-colors': _vm.reverseColors,
                    'no-input-borders px-2':
                        _vm.roundedLeftBorders || _vm.roundedRightBorders || _vm.embeddedSearchIconRight || _vm.embeddedSearchIconLeft || _vm.embeddedMapIcon,
                },attrs:{"size":"sm","id":'smarty-id-' + _vm.uniqueId,"name":'smarty-name-' + _vm.uniqueId,"autocomplete":"none","type":"text","placeholder":_vm.smartyPlaceholder,"autofocus":_vm.setFocus,"state":_vm.inputValid},nativeOn:{"keydown":function($event){return _vm.keyDown.apply(null, arguments)},"input":function($event){return _vm.inputChanging.apply(null, arguments)},"focus":function($event){$event.preventDefault();return _vm.gainFocus.apply(null, arguments)},"blur":function($event){$event.preventDefault();return _vm.lostFocus.apply(null, arguments)}},model:{value:(_vm.inputData),callback:function ($$v) {_vm.inputData=$$v},expression:"inputData"}}),(_vm.embeddedSearchIconRight)?_c('span',{staticClass:"mt-3 mr-2 fa-regular fa-magnifying-glass text-muted",class:{ 'text-white': _vm.reverseColors }}):_vm._e()],1)]),(_vm.active && _vm.inputData != '')?_c('div',{staticClass:"mx-0 my-0 px-0 py-0 autocomplete-parent",class:{ 'text-black': _vm.reverseColors, 'ml-3': _vm.roundedLeftBorders, 'mr-3': _vm.roundedRightBorders }},_vm._l((_vm.boldContent),function(val,idx){return _c('div',{key:idx,staticClass:"d-flex flex-row autocomplete-v-for my-0 py-0",class:{ 'autocomplete-active': _vm.keyboardActiveIndex == idx },on:{"mousedown":function($event){return _vm.processClick(idx)}}},[(_vm.smartyType == 'people' && _vm.people[idx].avatar)?_c('url-avatar',{staticClass:"my-1 ml-1 global-mini-avatar",attrs:{"url":_vm.people[idx].avatar?.imageUrl,"cachedUrl":_vm.people[idx].avatar?.cachedImageUrl}}):_vm._e(),_c('div',{staticClass:"d-flex d-row w-100 autocomplete-item align-items-center px-1 text-truncate",domProps:{"innerHTML":_vm._s(val)}})],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }