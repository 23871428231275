
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import config from '@/config';
    import VueConstants from '@/components/VueConstants';
    import * as analytics from '@/tsfiles/analytics';
    import { getAdChannelQueryParams } from '@/tsfiles/adchannel';
    import QrcodeVue from 'qrcode.vue';

    export default mixins(VueConstants).extend({
        name: 'Marketing',

        components: {
            'qrcode-vue': QrcodeVue,
        },

        props: {
            urlForAppQrcode: {
                type: String,
                required: false,
                default: '',
            },
        },

        data() {
            return {};
        },

        computed: {},

        methods: {
            //
            // getAppLink returns a properly formated Branch.io link.
            // 'gen' is the Branch.io generic alias we use.
            //
            getAppLink(): string {
                return config.branchioLink + getAdChannelQueryParams(true);
            },

            //
            // When a user clicks on the download button, we log the analytics event.  The
            // click is usually an "@click" without ".prevent", so the href will be triggered.
            //
            analyticsExternalClick(type: string) {
                analytics.logAppInteraction(analytics.ANALYTICS_ACTION_APP_DOWNLOAD, type);
            },
        },
    });
