
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import { mapState } from 'vuex';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import { PageMessage } from '@/tsfiles/interfaces';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import NotificationVerifyEmail from '@/components/page-messages/NotificationVerifyEmail.vue';
    import NotificationEmailFlagged from '@/components/page-messages/NotificationEmailFlagged.vue';
    import NotificationVerifyPhone from '@/components/page-messages/NotificationVerifyPhone.vue';
    import NotificationPhoneFlagged from '@/components/page-messages/NotificationPhoneFlagged.vue';
    import PhoneEmailNeedVerification from '@/components/page-messages/PhoneEmailNeedVerification.vue';
    import DatingPaused from '@/components/page-messages/DatingPaused.vue';
    import OnboardingNotComplete from '@/components/page-messages/OnboardingNotComplete.vue';
    import { SharedConstants } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'PageMessageList',

        components: {
            'notification-verify-email': NotificationVerifyEmail,
            'notification-email-flagged': NotificationEmailFlagged,
            'notification-verify-phone': NotificationVerifyPhone,
            'notification-phone-flagged': NotificationPhoneFlagged,
            'phone-email-need-verification': PhoneEmailNeedVerification,
            'dating-paused': DatingPaused,
            'onboarding-not-complete': OnboardingNotComplete,
        },

        props: {},

        data() {
            return {
                dismissCountDowns: [] as number[], // List of count downs for pageMessages
                permanent: [] as boolean[], // List of permanent setting for pageMessages

                // Local copy of page messages shown, based on targetPages.
                messagesToShow: [] as PageMessage[],
            };
        },

        watch: {
            //
            // When the store's pageMessages changes, reset our local copy of the messages.
            // We keep a local copy to show/hide based on the 'targetPages
            //
            // tslint:disable-next-line
            pageMessages: {
                immediate: true,
                deep: true,
                handler(newVal: PageMessage[], _: PageMessage[]) {
                    if (newVal) {
                        this.setLocalMessagesToShow();
                    } else {
                        this.messagesToShow = [] as PageMessage[];
                    }
                },
            },

            //
            // When the route changes, some messages may now be shown, while
            // others will disappear.  Reset our local list, which triggers reactivity.
            //
            '$route.name': {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal && newVal !== oldVal) {
                        this.setLocalMessagesToShow();
                    }
                },
            },
        },

        computed: {
            //
            // Map Vuex store PageMessages, so we know when to display a new page message.
            //
            ...mapState(['pageMessages']),
        },

        methods: {
            //
            // Return true if the message event is legal (one this component handles).
            //
            isLegalMessageEvent(event: string): boolean {
                switch (event) {
                    case SharedConstants.NOTIFICATION_VERIFY_EMAIL:
                    case SharedConstants.NOTIFICATION_VERIFY_PHONE:
                    case SharedConstants.NOTIFICATION_EMAIL_FLAGGED:
                    case SharedConstants.NOTIFICATION_PHONE_FLAGGED:
                    case constants.FAKE_NOTIFICATION_EMAIL_PHONE_VERIFICATION_REQUIRED:
                    case constants.FAKE_NOTIFICATION_ONBOARDING_NOT_COMPLETE:
                    case constants.FAKE_NOTIFICATION_DATING_PAUSED:
                        return true;
                    default:
                        logInvalidParams(this.$options.name, 'isLegalMessage', event);
                        return false;
                }
            },

            //
            // Sets the local message list, and associated dismissCountDowns and permanent arrays.
            //
            setLocalMessagesToShow() {
                // Get the new list of messages to show.
                const newMessagesToShow = [] as PageMessage[];
                for (const msg of this.pageMessages) {
                    if (this.isLegalMessageEvent(msg.notification.event) && this.matchingTargetPage(msg)) {
                        newMessagesToShow.push(msg);
                    }
                }

                Vue.set(this, 'messagesToShow', newMessagesToShow);

                for (let idx = 0; idx < this.messagesToShow.length; idx++) {
                    if (this.messagesToShow[idx].secondsToDisplay) {
                        Vue.set(this.dismissCountDowns, idx, this.messagesToShow[idx].secondsToDisplay as number);
                        Vue.set(this.permanent, idx, false);
                    } else {
                        Vue.set(this.permanent, idx, true);
                        Vue.set(this.dismissCountDowns, idx, undefined as number | undefined);
                    }
                }
            },

            //
            // Return true if the given message should be shown on the current route.
            //
            matchingTargetPage(msg: PageMessage): boolean {
                if (!msg.targetPages || msg.targetPages.length === 0 || !this.$router.currentRoute.name) {
                    return true;
                }

                // The userhome page can one of the routes in the main menu, and can
                // also be used as a target.  Instead of a simple check:
                //    return msg.targetPages.includes(this.$router.currentRoute.name);
                // we need to loop through the targets, handling ROUTE_USER_HOME correctly.
                const currentRouteName = this.$router.currentRoute.name;
                for (const target of msg.targetPages) {
                    if (
                        target === constants.ROUTE_USER_HOME &&
                        (currentRouteName === constants.ROUTE_USER_CALENDAR ||
                            currentRouteName === constants.ROUTE_USER_PROSPECTS ||
                            currentRouteName === constants.ROUTE_USER_SETTINGS)
                    ) {
                        return true;
                    } else if (msg.targetPages.includes(this.$router.currentRoute.name)) {
                        return true;
                    }
                }

                return false;
            },

            //
            // Returns the desired dismiss count down for display.  One is added
            // to keep the UI showing a positive number.  For example, counting down
            // 5,4,3,2,1.  countDownChanged is called immediately, which would cause the
            // UI to show 4 down to 0, which we don't want.
            //
            getCurrentCount(idx: number): number {
                if (idx < 0 || idx >= this.pageMessages.length) {
                    logInvalidParams(this.$options.name, 'getCurrentCount');
                    return 0;
                }

                return this.dismissCountDowns[idx] + 1;
            },

            //
            // Called by the b-alert for the @dismiss-count-down event.  We pass in
            // the page list index being counted down.
            //
            countDownChanged(idx: number) {
                if (idx < 0 || idx >= this.pageMessages.length) {
                    logInvalidParams(this.$options.name, 'countDownChanged');
                    return;
                }

                Vue.set(this.dismissCountDowns, idx, this.dismissCountDowns[idx] - 1);
            },

            //
            // Clear the given page message from the list
            //
            dismissPageMessage(idx: number) {
                if (idx < 0 || idx >= this.pageMessages.length) {
                    logInvalidParams(this.$options.name, 'dismissPageMessage');
                    return;
                }

                this.$store.commit('clearPageMessage', this.pageMessages[idx].notification.id);
            },
        },
    });
