//
// Copyright 2022-2023 Lola - All rights reserved.
// File: profilerefresh.ts
// Project: lola
//
import store from './store';
import { ApiUtils } from '@/tsfiles/apiutils';
import { Utils } from '@/tsfiles/utils';
import { UserService, AuthedUser } from '@bostonventurestudio/lola-api';

//
// Refresh the user's profile, putting results in store.  This
// is called by various views and components, which is why
// it was made as a common ts file.
//
export async function profileRefresh() {
    try {
        const user = await ApiUtils.apiWrapper(UserService.getAuthedUser);
        store.commit('setAuthedUser', user);
    } catch (error) {
        Utils.CommonErrorHandler(error);
    }
}
