
    import Vue, { PropOptions } from 'vue';
    import { CountryCodeMenuItem } from '@/tsfiles/interfaces';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default Vue.extend({
        name: 'CountryCodeMenu',

        components: {},

        props: {
            countryCodes: {
                type: Array,
                required: true,
            },

            selectedItem: {
                type: String,
                required: false,
                default: '',
            },

            readOnly: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                // Flags go into public/flags and come from:
                //      https://www.countries-ofthe-world.com/flags-of-the-world.html
                // The size should be 100x59, with the name being <cc>-flag.png (e.g., us-flags.png)
                //
                availableCountries: [
                    { countryCode: 'au', countryName: 'Australia', flagUrl: '/flags/au-flag.png' },
                    { countryCode: 'ca', countryName: 'Canada', flagUrl: '/flags/ca-flag.png' },
                    { countryCode: 'gb', countryName: 'United Kingdom', flagUrl: '/flags/uk-flag.png' },
                    { countryCode: 'in', countryName: 'India', flagUrl: '/flags/in-flag.png' },
                    { countryCode: 'nz', countryName: 'New Zealand', flagUrl: '/flags/nz-flag.png' },
                    { countryCode: 'us', countryName: 'United States', flagUrl: '/flags/us-flag.png' },
                ] as CountryCodeMenuItem[],

                currentFlag: '',
            };
        },

        watch: {
            selectedItem: {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal && newVal !== oldVal) {
                        for (const item of this.availableCountries) {
                            if (item.countryCode === newVal) {
                                this.currentFlag = item.flagUrl;
                                return;
                            }
                        }

                        // Error if no match found
                        logInvalidParams(this.$options.name, 'selectedItem watcher');
                        this.currentFlag = '';
                    }
                },
            },
        },

        computed: {},

        methods: {
            getFlag(idx: number): string {
                if (idx < 0 || idx >= this.countryCodes.length) {
                    logInvalidParams(this.$options.name, 'getFlag');
                }

                // Find match from availableCountries
                for (const item of this.availableCountries) {
                    if (item.countryCode === this.countryCodes[idx]) {
                        return item.flagUrl;
                    }
                }

                logInvalidParams(this.$options.name, 'getFlag: invalid country code - ' + this.countryCodes[idx]);
                return '';
            },

            getName(idx: number): string {
                if (idx < 0 || idx >= this.countryCodes.length) {
                    logInvalidParams(this.$options.name, 'getName');
                }

                // Find match from availableCountries
                for (const item of this.availableCountries) {
                    if (item.countryCode === this.countryCodes[idx]) {
                        return item.countryName;
                    }
                }

                logInvalidParams(this.$options.name, 'getName: invalid country code - ' + this.countryCodes[idx]);
                return '';
            },

            flagSelected(idx: number) {
                if (idx < 0 || idx >= this.countryCodes.length) {
                    logInvalidParams(this.$options.name, 'flagSelected');
                }

                // Find match from availableCountries
                for (const item of this.availableCountries) {
                    if (item.countryCode === this.countryCodes[idx]) {
                        this.currentFlag = item.flagUrl;
                        this.$emit('country-selected', item.countryCode);
                        return;
                    }
                }

                logInvalidParams(this.$options.name, 'flagSelected: invalid country code - ' + this.countryCodes[idx]);
                return '';
            },
        },
    });
