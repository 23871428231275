import { render, staticRenderFns } from "./StatsAdmin.vue?vue&type=template&id=a3da7cca&scoped=true&"
import script from "./StatsAdmin.vue?vue&type=script&lang=ts&"
export * from "./StatsAdmin.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3da7cca",
  null
  
)

export default component.exports