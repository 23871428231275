
    import Vue from 'vue';
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    export default Vue.extend({
        name: 'CollectTextInput',
        components: { DatePicker },
        props: {
            title: {
                type: String,
                required: true,
            },

            date: {
                type: String,
                required: false,
                default: '2000-01-01',
            },

            minDate: {
                type: String,
                required: false,
                default: '1940-01-01',
            },

            maxDate: {
                type: String,
                required: false,
                default: new Date().toISOString().split('T')[0],
            },
            format: {
                type: String,
                required: true,
                default: 'MM-DD-YYYY',
            },
        },

        data() {
            return {
                dateValue: '2000-01-01',
            };
        },

        watch: {
            date: {
                immediate: true,
                handler: function (val) {
                    this.initData(val);
                },
            },
        },

        computed: {
            invalidInput(): boolean {
                const date = new Date(this.dateValue);
                return !this.dateValue || date < new Date(this.minDate) || date > new Date(this.maxDate);
            },
        },

        methods: {
            initData(data: string) {
                this.dateValue = data;
            },
        },
    });
