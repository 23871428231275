
    import Vue from 'vue';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import Avatar from '@/components/Avatar.vue';
    import UserNameDisplay from '@/components/user/UserNameDisplay.vue';
    import { UserService, PublicUser } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'BlockedUsers',

        components: {
            'url-avatar': Avatar,
            'user-name-display': UserNameDisplay,
        },

        props: {},

        data() {
            return {
                users: [] as PublicUser[],
            };
        },

        watch: {},

        mounted() {
            this.fetchBlockedUsers();
        },

        computed: {},

        methods: {
            async fetchBlockedUsers() {
                try {
                    const ret = await ApiUtils.apiWrapper(UserService.getBlockedUsers);
                    if (ret && ret.list) {
                        this.users = ret.list;
                    } else {
                        this.users = [] as PublicUser[];
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            async unblockUser(userId: number | undefined) {
                if (!userId) {
                    logInvalidParams(this.$options.name, 'unblockUser');
                    return;
                }

                try {
                    await ApiUtils.apiWrapper(UserService.unblockUser, userId);
                    this.fetchBlockedUsers();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },
        },
    });
