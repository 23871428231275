
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import VueConstants from '@/components/VueConstants';
    import PastDateRow from '@/components/dating/PastDateRow.vue';
    import { DatingService, SharedConstants, GetPastMatchesResponse, Match } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'PastDates',

        components: {
            'past-date': PastDateRow,
        },

        props: {},

        data() {
            return {
                dates: [] as Match[],
                filters: {} as any,
                fetchComplete: false,
                pagination: {
                    page: 1,
                    pageSize: SharedConstants.DATING_PAST_MATCHES_PAGE_SIZE,
                    totalRecords: 0,
                },
                noResult: false,
                currentPage: 1,
            };
        },

        watch: {},

        mounted() {
            this.fetchPastDates({ page: 1, pageSize: SharedConstants.DATING_PAST_MATCHES_PAGE_SIZE });
        },

        computed: {},

        methods: {
            async handleSearch(filters: any) {
                filters.page = 1;
                filters.pageSize = SharedConstants.DATING_PAST_MATCHES_PAGE_SIZE;
                await this.fetchPastDates(filters);
            },
            async handlePageChange(page: number) {
                // Update the page number and perform the search again
                var filters = this.filters;
                filters.page = page;
                await this.fetchPastDates(filters);
            },
            async fetchPastDates(filters: any) {
                try {
                    // Updated request format to include userId and pageRetrieval details
                    const ret = await ApiUtils.apiWrapper(DatingService.getPastMatches, {
                        page: filters.page || 1, // Use the page from filters, default to 1 if not provided
                        pageSize: filters.pageSize || SharedConstants.DATING_PAST_MATCHES_PAGE_SIZE, // Use pageSize from filters or default constant
                    });
                    if (ret && ret.matches && ret.matches.length > 0) {
                        this.dates = ret.matches;
                        this.pagination = {
                            page: ret.page ?? 1,
                            pageSize: ret.pageSize ?? SharedConstants.DATING_PAST_MATCHES_PAGE_SIZE,
                            totalRecords: ret.totalRecords ?? 0,
                        };
                        console.log(this.pagination);
                    } else {
                        this.dates = [] as Match[];
                        this.noResult = true;
                        this.dates = [] as Match[];
                        this.pagination = {
                            page: ret.page ?? 1,
                            pageSize: ret.pageSize ?? SharedConstants.DATING_PAST_MATCHES_PAGE_SIZE,
                            totalRecords: ret.totalRecords ?? 0,
                        };
                    }

                    this.fetchComplete = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            rowSelected(idx: number) {
                if (idx < 0 || idx > this.dates.length) {
                    logInvalidParams(this.$options.name, 'rowSelected');
                    return;
                }

                const item = this.dates[idx];
                if (!item || !item.matchId || !item.partnerUserId || item.partnerUserId <= 0) {
                    logInvalidParams(this.$options.name, 'rowSelected - invalid partnerUserId');
                    return;
                }

                this.$router.push({
                    name: constants.ROUTE_USER_PAST_DATE,
                    query: { matchId: item.matchId.toString() },
                });
            },

            showPagination() {
                return this.dates && this.dates?.length;
            },
            /**
             * Returns a string representing the pagination information.
             * @returns {string} The pagination information.
             */
            getPaginationInfo(): string {
                var showing = (this.pagination.page - 1) * this.pagination.pageSize + 1;
                return (
                    'Showing ' +
                    showing +
                    ' - ' +
                    Math.min(this.pagination.page * this.pagination.pageSize, this.pagination.totalRecords) +
                    ' of ' +
                    this.pagination.totalRecords +
                    ' dates.'
                );
            },
        },
    });
