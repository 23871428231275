
    import Vue from 'vue';

    export default Vue.extend({
        name: 'StarRating',

        components: {},

        props: {
            // Rating number, from 1 to 5, with fractions possible
            rating: {
                type: Number,
                required: true,
            },

            // Size of the stars
            size: {
                type: String,
                required: false,
                default: '',
            },

            // Is the user allowed to set the ratings?
            editable: {
                type: Boolean,
                required: false,
                default: false,
            },

            numberDisplay: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                localRating: this.rating,
            };
        },

        watch: {
            //
            // If the rating changes, copy to our local copy.  This local
            // copy is only really needed when editing, but used for
            // readonly as well.
            //
            rating: {
                immediate: true,
                handler(newVal, oldVal) {
                    this.localRating = newVal;
                },
            },
        },

        computed: {
            getNumberDisplayRating(): string {
                return this.rating.toFixed(1);
            },
        },

        methods: {
            getBindClass(starNumber: number): string {
                let ret = '';

                // Get size first
                switch (this.size) {
                    case 'sm':
                        ret = 'fa-sm';
                        break;
                    case 'lg':
                        ret = 'fa-lg';
                        break;
                    default:
                        break;
                }

                // Now determine if star should be selected fully or partially.
                if (this.localRating >= starNumber) {
                    ret += ' fal fa-star selected-star';
                } else if (Math.ceil(this.localRating) === starNumber) {
                    ret += ' fad fa-star-half selected-star';
                } else {
                    ret += ' fal fa-star';
                }

                if (this.editable) {
                    ret += ' show-pointer';
                }

                return ret;
            },

            selectStar(starNumber: number) {
                if (this.editable) {
                    this.localRating = starNumber;
                    this.$emit('new-rating', starNumber);
                }
            },
        },
    });
