
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { PageNavigationData } from '@/tsfiles/interfaces';
    import UserSettings from '@/components/user/UserSettings.vue';
    import UserProfileSettings from '@/components/user/UserProfileSettings.vue';
    import UserPhotoSettings from '@/components/user/UserPhotoSettings.vue';
    import UserTargetingSettings from '@/components/user/UserTargetingSettings.vue';
    import UserNotificationSettings from '@/components/user/UserNotificationSettings.vue';
    import DeleteUserModal from '@/components/user/DeleteUserModal.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { UserService, AuthedUser, Profile, ProfileDefinitions, TargetingPreferences, AuthService } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'Settings',

        components: {
            'user-settings': UserSettings,
            'user-profile-settings': UserProfileSettings,
            'user-photo-settings': UserPhotoSettings,
            'user-targeting-settings': UserTargetingSettings,
            'user-notification-settings': UserNotificationSettings,
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                user: undefined as AuthedUser | undefined,
                profileDefinitions: undefined as ProfileDefinitions | undefined,

                settingsDirty: false,
                showUnsavedChangesModal: false,
                unsavedChangesAlertText: 'You have unsaved profile changes.  Are you sure you want to leave this page?',
                unsavedChangesNextRoute: undefined as any | undefined,

                currentTab: 0,
                currentNavigation: { page: constants.ROUTE_USER_SETTINGS } as PageNavigationData,

                alertMessage: null as string | null,
                dismissCountDown: 0,
                permanent: false,
                alertType: 'success',
            };
        },

        mounted() {
            //
            // If page navigation contains data, process.
            //
            const nav = this.$store.getters.getPageNavigation(constants.ROUTE_USER_SETTINGS);
            if (nav && nav.tab) {
                this.$nextTick(() => {
                    this.currentTab = nav.tab;
                    this.currentNavigation.tab = nav.tab;
                });
            }

            this.fetchUser();
            this.fetchProfileDefinitions();
        },

        //
        // If the user has made changes to the settings page, make sure
        // they are ok losing the changes, or do they want to stay on that
        // page.  With a semi-single page design, this is messy.  You cannot
        // do beforeRouteLeave on subcomponents, so we use Store to keep the
        // settings is-dirty flag.  Why not have real components for each page?
        // That would cause hits to the api when moving around on the menu, which
        // we don't want.
        //
        // The only settings change that causes big problems with this mechanism is
        // when the userId changes.  Only when that happens does the settings page
        // do a router.replace to constants.ROUTE_USER_HOME to get the new userId
        // into the URL.  We need to special case that so we don't show the unsaved
        // change modal if go to constants.ROUTE_USER_HOME with a different URL.
        //
        beforeRouteLeave(to, from, next) {
            const allowEvenIfDirty = to.name === constants.ROUTE_USER_HOME && to.params.userId !== from.params.userId;

            if (!this.$store.getters.isSignedIn || !this.settingsDirty || allowEvenIfDirty) {
                if (allowEvenIfDirty) {
                    this.okToChangePage();
                }
                next();
                return;
            }

            this.unsavedChangesNextRoute = next;
            this.showUnsavedChangesModal = true;
            return false;
        },

        beforeDestroy() {
            this.$store.commit('setPageNavigation', this.currentNavigation);
        },

        computed: {},

        methods: {
            zeroValue(count: number | undefined): number {
                if (!count) {
                    return 0;
                }

                return count;
            },

            async fetchUser() {
                try {
                    const ret = (await ApiUtils.apiWrapper(UserService.getAuthedUser)) as AuthedUser;

                    Vue.set(this, 'user', ret);
                    this.$store.commit('setAuthedUser', ret);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            // This is a special case where the onboardingCompleteV2 told us to
            // refresh the user, including their roles.  This is only within the webUI,
            // since we have the ability to restart onboarding, get off the waitlist, etc.
            async reloadUser() {
                try {
                    const ident = await ApiUtils.apiWrapper(AuthService.getIdentity);
                    this.$store.commit('setAuthedUser', ident.user);
                    this.$store.commit('setUserRoles', ident.roles);

                    this.fetchUser(); // The setting pages need the updates as well.
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Retrieve the profile definitions from the server.  These are mainly the
            // common single and multiselect properties within the profile (e.g., gender, languages, etc.).
            // The profile definitions are shared with ProfileSettings and TargetingSettings.
            //
            async fetchProfileDefinitions() {
                try {
                    const ret = await ApiUtils.apiWrapper(UserService.getProfileDefinitions);
                    Vue.set(this, 'profileDefinitions', ret);
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // The user doesn't want to stay on the page, even though there are unsaved changes.
            // We just route to the next page (we saved that during beforeRouteLeave).
            //
            okToChangePage() {
                this.settingsDirty = false;
                this.showUnsavedChangesModal = false;
                if (this.unsavedChangesNextRoute) {
                    this.unsavedChangesNextRoute();
                }
            },

            //
            // The user wants to stay on the settings page.  Make sure to turn off the
            // modal and set the current menu back to the settings.
            //
            stayOnSettingsPage() {
                this.showUnsavedChangesModal = false;
                this.$store.commit('setCurrentMenu', constants.ROUTE_USER_PROFILE);
            },

            userUpdated(updatedUser: AuthedUser) {
                this.user = Utils.deepCopy(updatedUser);
                this.$store.commit('setAuthedUser', this.user);
                this.showAlert('Your profile has been updated.', 'success', false);
            },

            profileUpdated(updatedProfile: Profile) {
                if (!this.user || !this.user.publicUser) {
                    logInvalidParams(this.$options.name, 'profileUpdated');
                    return;
                }

                this.user.publicUser.profile = Utils.deepCopy(updatedProfile);
                this.$store.commit('setAuthedUser', this.user);
                this.showAlert('Your profile has been updated.', 'success', false);
            },

            targetingUpdated(updatedTargeting: TargetingPreferences) {
                if (!this.user) {
                    logInvalidParams(this.$options.name, 'targetingUpdated');
                    return;
                }

                this.user.targetingPreferences = Utils.deepCopy(updatedTargeting);
                this.showAlert('Your profile has been updated.', 'success', false);
            },

            notificationPreferencesUpdated(updatedUser: AuthedUser) {
                this.user = Utils.deepCopy(updatedUser);
                this.$store.commit('setAuthedUser', this.user);
                this.showAlert('Your notification preferences have been updated.', 'success', false);
            },

            showAlert(message: string, alertType: string, perm: boolean) {
                this.permanent = perm;
                if (!perm) {
                    this.dismissCountDown = 5;
                }
                this.alertMessage = message;
                this.alertType = alertType;
            },

            alertCountDownChanged(dismissCountDown: number) {
                this.dismissCountDown = dismissCountDown;
            },
        },
    });
