
    import Vue from 'vue';

    export default Vue.extend({
        name: 'DotAnimation',

        components: {},

        props: {},

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
