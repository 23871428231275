
    import Vue from 'vue';

    export default Vue.extend({
        name: 'Avatar',

        props: {
            // Real Url, our special initials url, or a base64 string of the image
            url: {
                type: String,
                required: true,
            },

            // Cached/Resized Url.  If supplied, this will be used first.  Should
            // not contain our special initials url
            cachedUrl: {
                type: String,
                required: false,
                default: '',
            },

            // Should we show the cursor pointer?
            showPointer: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        computed: {
            isInitialsAvatar(): boolean {
                return this.url.includes('initials.avatar');
            },

            initialsText(): string {
                const parsedUrl = new URL(this.url);
                if (parsedUrl) {
                    const val = parsedUrl.searchParams.get('text');
                    if (val != null) {
                        return val;
                    }
                }
                return '';
            },

            initialsStyle() {
                const parsedUrl = new URL(this.url);
                if (parsedUrl) {
                    const background = parsedUrl.searchParams.get('background') as string;
                    const color = parsedUrl.searchParams.get('color') as string;
                    if (background != null && color != null) {
                        return {
                            background: `#${background}`,
                            color: `#${color}`,
                        };
                    }
                }
                return {};
            },

            getUrl(): string {
                if (this.cachedUrl !== '') {
                    return this.cachedUrl;
                }

                return this.url;
            },
        },
    });
