
    import Vue, { PropOptions } from 'vue';
    import JSON5 from 'json5';
    import { DateTime as LuxonDateTime } from 'luxon';
    import { logInvalidParams } from '@/tsfiles/errorlog';
    import { Utils } from '@/tsfiles/utils';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { AdminService, PublicUser, SharedConstants, UserHistoryEntry } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'UserHistory',

        components: {},

        props: {
            user: {
                type: Object,
                required: true,
            } as PropOptions<PublicUser>,
        },

        data() {
            return {
                history: [] as UserHistoryEntry[],
                allItems: [] as any[],
                filterItemsType: '',

                historyFields: [
                    { key: 'category', tdClass: 'align-middle' },
                    { key: 'message', tdClass: 'align-middle' },
                    { key: 'details', tdClass: 'align-middle' },
                    { key: 'created', tdClass: 'align-middle' },
                ],
            };
        },

        watch: {
            user: {
                immediate: true,
                deep: true,
                handler(newVal: PublicUser, oldVal: PublicUser) {
                    if (newVal && newVal.userId && newVal !== oldVal) {
                        this.getUserHistory(newVal.userId);
                    }
                },
            },
        },

        computed: {},

        methods: {
            async getUserHistory(userId: number) {
                try {
                    const ret = await ApiUtils.apiWrapper(AdminService.getUserHistory, userId);
                    if (ret && ret.list) {
                        Vue.set(this, 'history', ret.list);
                    } else {
                        Vue.set(this, 'history', [] as UserHistoryEntry[]);
                    }

                    this.buildItemList();
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            buildItemList() {
                if (this.history.length === 0) {
                    this.allItems = [] as any[];
                    return;
                }

                const newItems = [] as any[];
                for (const item of this.history) {
                    switch (this.filterItemsType) {
                        case 'all':
                            break;
                        case SharedConstants.AUDIT_CATEGORY_USER:
                        case SharedConstants.AUDIT_CATEGORY_MEDIA:
                        case SharedConstants.AUDIT_CATEGORY_ADMIN:
                        case SharedConstants.AUDIT_CATEGORY_SYSTEM:
                            if (item.category !== this.filterItemsType) {
                                continue;
                            }
                            break;
                        default:
                            break;
                    }

                    let details = '';

                    if (item.jsonData && item.jsonData.length > 0) {
                        // Create an initial message string with most json formatting removed
                        details = JSON5.stringify(JSON.parse(item.jsonData as string), null, 1);

                        // Remove beginning and ending curly braces
                        details = details.substring(1);
                        details = details.substring(0, details.length - 3);
                    }

                    newItems.push({
                        category: item.category,
                        message: item.message,
                        details,
                        created: LuxonDateTime.fromISO(item.created).toISO({ suppressMilliseconds: true }),
                    });
                }

                this.allItems = newItems;
            },

            setItemsFilterType(type: string) {
                this.filterItemsType = type;
                this.buildItemList();
            },
        },
    });
