//
// Copyright 2022-2023 Lola - All rights reserved.
// File: utils.ts
// Project: lola
//
// cspell:ignore rfdc degr
//
import router from '@/tsfiles/router';
import { parsePhoneNumberFromString, getCountryCallingCode } from 'libphonenumber-js/max';
import { parseDomain, ParseResultType } from 'parse-domain';
import validator from 'validator';
import * as domainBlock from '@/tsfiles/domainblock';
import { signout } from '@/tsfiles/firebase';
import store from './store';
import * as constants from '@/tsfiles/constants';

//
// Use rfdc for fast deep copy.  We should do this everywhere a reference
// isn't good enough, especially with 'service' objects coming from grpc, since
// those could be enhanced at any time in the future (with embedded objects/arrays).
//
import rfdc from 'rfdc';

//
// Separate app imports, used with GetTopLevelVueAndRoutes()
//
import MainApp from '@/apps/MainApp.vue';
import ShareApp from '@/apps/ShareApp.vue';
//
// Separate app routes, used with GetTopLevelVueAndRoutes()
//
import MainRoutes from '@/apps/mainroutes';
import ShareRoutes from '@/apps/shareRoutes';
import { logInvalidParams } from './errorlog';
import { SharedConstants } from '@bostonventurestudio/lola-api';

//
// When doing the numeric keyboard hack for Samsung,
// or other devices without a minus on their numeric
// keypad, we use 'text' and need to make sure it's
// a legal number.  This regex is what we use in
// isNumberForKeyboardHack().
//
const keyboardHackRegex = new RegExp(/^-?[0-9]*(\.[0-9]+)?$/, 'i');

export class Utils {
    //
    // This function returns the renderApp and Routes array.  The render app is used
    // by main.ts to figure out which top-level app to use.  The routes is specific
    // to the app we want.  See vue.config.js for list of domains used during development.
    // Production uses the @/nginx/ configs.
    //
    public static GetTopLevelVueAndRoutes(): any {
        let renderApp: any; // Required for compile
        let allRoutes: any;

        // If we're on the share page, use the share app otherwise default to MainApp
        if (window.location.pathname.startsWith('/share')) {
            renderApp = ShareApp;
            allRoutes = ShareRoutes;
        } else {
            renderApp = MainApp;
            allRoutes = MainRoutes;
        }

        return { render: renderApp, routes: allRoutes };
    }

    //
    // Common error handling for most axios catches.
    // To start we'll just go to the Error page.  This might be incorporated
    // into App.vue in the future, if we don't want to go to a new page
    // (new location in the URL, which could cause confusion when the
    // user does a browser refresh).
    //
    public static CommonErrorHandler(error: any) {
        if (!error || !error.response) {
            return;
        }

        //
        // We probably do not need to call our log() here.  This error should
        // have come from the server, and would have logged the issue.  There's
        // no reason to call jslog if the server already knows about the problem.
        // For now, just send it to the console.
        //
        console.error('CommonErrorHandler: ', error.response);

        //
        // Why switch on 'true'?  It's a way to do a compound if-type
        // statement (see 500 error handling).
        //
        // All other 400 errors should be handled by the caller of the grpc
        // function, inside catch; otherwise nothing will happen other the
        // console.error() above.
        //
        switch (true) {
            case error.response.status === 401: // Unauthorized (server codes.Unauthenticated)
            case error.response.status === 403: // Forbidden (server codes.PermissionDenied)
                //
                // This can happen when we change the session id string in the gateway,
                // forcing everyone to sign out, then back in.  It can also happen
                // if we explicitly send back PermissionDenied (access denied) from the api.
                //
                // Second parameter of signout routers the user to the marketing page.
                signout(true, true);
                break;
            case error.response.status === 404: // Not Found (server codes.NotFound)
                //
                // If something is NotFound, and not intercepted by the caller, go to the
                // marketing page.  If the user is signed in, they will end up on their home page.
                //
                router.replace({ name: constants.ROUTE_MARKETING });
                break;
            case error.response.status === 408: // Request timeout (context canceled)
                alert('Request timeout');
                break;
            case error.response.status === 409: // Conflict (server codes.Aborted)
                switch (error.response.data.message) {
                    case SharedConstants.WARNING_RESOURCE_INACTIVE:
                        alert('User not active');
                        break;
                    case SharedConstants.WARNING_RESOURCE_EXISTS:
                        router.replace({ name: constants.ROUTE_USER_CALENDAR });
                        break;
                }
                break;
            case error.response.status === 413: // Request entity too large
                alert("The file you're trying to upload is too large. Please try a smaller file.");
                break;
            case error.response.status === 429: // Too Many Requests (server codes.ResourceExhausted)
                switch (error.response.data.message) {
                    case SharedConstants.WARNING_RESOURCE_EXHAUSTED:
                        // Right now the server only does this for soft-delete exceeded
                        alert('Action rejected by server: Too Many Requests');
                        break;
                    default:
                        if (error.response.data.message.includes('grpc: received message larger than max')) {
                            alert("The file you're trying to upload is too large. Please try a smaller file.");
                        }
                        break;
                }
                break;
            case error.response.status >= 500 && error.response.status < 600:
                router.replace({ name: 'error', params: { errorType: 'ServerError' } });
                break;
        }
    }

    //
    // deepCopy will do a fast deep copy of the given object.  This is better
    // than trying to use JSON (date, etc. issues), shallow copying, etc., especially for grpc
    // objects that may change over time (new embedded objects or arrays).
    //
    // @param {Object} obj        The object that needs deep copying
    // @return {Object}           Returns the cloned object
    //
    public static deepCopy(obj: any): any {
        const clone = rfdc();
        return clone(obj);
    }

    // Return true if the given object is undefined or null
    public static objectUndefined(obj: any): boolean {
        return obj === undefined || obj === null;
    }

    //
    // deepEqual tries to determine if one object has equal values to another.  Doing
    // something like json stringify won't work if the elements are not in the exact
    // order.  The original function came from a stackoverflow answer, which has been
    // modified slightly:
    //     https://stackoverflow.com/a/25456134
    //
    // WARNING: If you deepEqual arrays, the values must match order exactly.
    // ['a', 'b', 'c'] is not the same as ['a', 'c', b']
    // This function does not work well with data coming back from the server,
    // since fields can be undefined to start, but then get filled in as the user
    // is working on items (and potentially undoing/clearing that item).  The number
    // of properties in the obj can change.
    //
    // @param {Object} obj1       One of the objects being compared
    // @param {Object} obj2       The other object being compared
    // @return {Boolean}          Returns true if the objects match
    //
    public static deepEqual(obj1: any, obj2: any): boolean {
        // it's just the same object. No need to compare.
        if (obj1 === obj2) {
            return true;
        }

        // Equal if both are undefined, or undefined and or empty if array
        if (
            (Utils.objectUndefined(obj1) && Utils.objectUndefined(obj2)) ||
            (Utils.objectUndefined(obj1) && Array.isArray(obj2) && obj2.length === 0) ||
            (Utils.objectUndefined(obj2) && Array.isArray(obj1) && obj1.length === 0)
        ) {
            return true;
        }

        // Not equal if one is undefined and the other is an array with values
        if (
            (Utils.objectUndefined(obj1) && Array.isArray(obj2) && obj2.length > 0) ||
            (Utils.objectUndefined(obj2) && Array.isArray(obj1) && obj1.length > 0)
        ) {
            return false;
        }

        // compare primitives
        if (obj1 !== Object(obj1) && obj2 !== Object(obj2)) {
            if (typeof obj1 === 'string' && typeof obj2 === 'string') {
                return Utils.caselessSameString(obj1, obj2);
            }
            if (typeof obj1 === 'string' && Utils.objectUndefined(obj2)) {
                return obj1 === '';
            } else if (Utils.objectUndefined(obj1) && typeof obj2 === 'string') {
                return obj2 === '';
            } else if (typeof obj1 === 'boolean' || typeof obj2 === 'boolean') {
                // Handle false and undefined as being equal
                return (Utils.objectUndefined(obj1) && obj2 === false) || (obj1 === false && Utils.objectUndefined(obj2));
            }

            return obj1 === obj2;
        }

        // If one is undefined and the other one isn't, return false
        // NOTE: this doesn't handle undefined versus empty object or string, for example.
        if ((Utils.objectUndefined(obj1) && !Utils.objectUndefined(obj2)) || (!Utils.objectUndefined(obj1) && Utils.objectUndefined(obj2))) {
            return false;
        }

        if (Object.keys(obj1).length !== Object.keys(obj2).length) {
            return false;
        }

        // compare objects with same number of keys
        for (const key in obj1) {
            if (obj1.hasOwnProperty(key)) {
                if (!(key in obj2)) {
                    return false; // other object doesn't have this prop
                }

                if (!Utils.deepEqual(obj1[key], obj2[key])) {
                    return false;
                }
            }
        }

        return true;
    }

    //
    // Return true if the two given strings are identical caseless compared strings.
    // If both are undefined, return true
    // If one is undefined and the other is an empty string, return true
    //
    // @param {String} s1         First string
    // @param {String} s2         Second string
    // @return {Boolean}          Returns true if both strings are the same (caseless)
    //
    public static caselessSameString(s1: string | undefined, s2: string | undefined): boolean {
        if (!s1 && !s2) {
            return true;
        } else if ((s1 && !s2 && s1 === '') || (!s1 && s2 && s2 === '')) {
            return true;
        }

        // Lint doesn't realize s1 and s2 must be defined to get here, so check again
        if (s1 && s2) {
            return s1.toLowerCase().localeCompare(s2.toLowerCase()) === 0;
        }

        return false;
    }

    //
    // Return true if the email domain matches the given domain, caseless.
    //
    // @param {String} email      Fully qualified email to check
    // @param {String} domain     Top level domain
    // @return {Boolean}          Returns true if both the email domain matches top-level domain
    //
    public static emailMatchesDomain(email: string, domain: string): boolean {
        return Utils.caselessSameString(domain, email.substring(email.lastIndexOf('@') + 1));
    }

    //
    // setCookie wrapper.  Sets a browser cookie, based on the name, it's value,
    // and an expiration (as number of days).
    //
    // NOTE: In Safari and Brave, client side cookies cannot have an expiration
    // longer than 7 days.  If you want longer expiration, set in HTTP response(s):
    //    https://webkit.org/blog/8613/intelligent-tracking-prevention-2-1/
    //
    //
    // @param {String} cookieName        The cookie name the caller wants to set
    // @param {String} value             The value of the cookie
    // @param {Number} daysToExpiration  The number of days until expiration
    //
    public static setCookie(cookieName: string, value: string, daysToExpiration: number) {
        const d = new Date();
        d.setTime(d.getTime() + daysToExpiration * 24 * 60 * 60 * 1000);
        const expires = 'expires=' + d.toUTCString();
        document.cookie = cookieName + '=' + value + ';' + expires + ';path=/';
    }

    //
    // getCookie wrapper.  Returns the value of the cookie the caller wants,
    // or empty string if not found.
    //
    // @param  {String} cookieName  The cookie name the caller wants to retrieve
    // @return {String}             The value of the cookie value, or empty string if not found
    //
    public static getCookie(cookieName: string): string {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (const item of ca) {
            let c = item;
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return '';
    }

    //
    // hasPartialCookie returns true if the given cookie name exists
    // somewhere in the list of cookies.  Make sure the name is
    // pretty unique.
    //
    // @param  {String} cookieName  Is this cookieName part of an existing cookie
    // @return {Boolean}            Returns true if cookieName is part of an existing cookie
    //
    public static hasPartialCookie(cookieName: string): boolean {
        const reg = new RegExp('.*' + cookieName + '.*');
        return reg.test(document.cookie);
    }

    //
    // Shuffles an array in place.  This is a version of Fisher-Yates shuffle algorithm.
    //   https://en.wikipedia.org/wiki/Fisher–Yates_shuffle#The_modern_algorithm
    //
    // @param  {Array} arrayToShuffle  An array containing the items to be shuffled
    // @return {Array}                 Shuffled array
    //
    public static inPlaceArrayShuffle(arrayToShuffle: any[]) {
        let j;
        let x;
        for (let i = arrayToShuffle.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = arrayToShuffle[i];
            arrayToShuffle[i] = arrayToShuffle[j];
            arrayToShuffle[j] = x;
        }

        return arrayToShuffle;
    }

    //
    // Return true if the given domain is a legal base domain, without
    // any subdomain
    //
    public static legalDomainBasename(name: string): boolean {
        if (!name || name === '') {
            logInvalidParams('utils.ts', 'legalDomainBasename');
            return false;
        }

        const parsedDomain = parseDomain(name.toLowerCase());
        if (parsedDomain.type === ParseResultType.Listed) {
            return (
                parsedDomain.domain !== undefined &&
                parsedDomain.domain !== '' &&
                parsedDomain.topLevelDomains.length > 0 &&
                parsedDomain.subDomains.length === 0
            );
        }

        return false;
    }

    //
    // This function will return true if the domain is in the blocked map.  We
    // have to check for wildcards, against the base domain name, as well as exact matches
    // against the given name.
    //
    public static domainBlocked(name: string): boolean {
        if (!name || name === '') {
            logInvalidParams('utils.ts', 'legalDomainBasename');
            return false;
        }

        const parsedDomain = parseDomain(name.toLowerCase());
        if (parsedDomain.type === ParseResultType.Listed && parsedDomain.domain) {
            return (
                domainBlock.domainWildcardBlockMap.get(parsedDomain.domain) !== undefined || domainBlock.domainExactBlockMap.get(name) !== undefined
            );
        }

        return false;
    }

    //
    // This function pulls the specified query parameter from the given url string.  If
    // no URL string is provided, it defaults to window.location.href.
    // Pulled from an answer by jolly.exe in stackoverflow.com:
    //   https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
    //
    public static getQueryParameterByName(name: string, url?: string) {
        if (!url) {
            url = window.location.href;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        const results = regex.exec(url);

        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }

        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    //
    // Format the given phone number.  If the regionCode is the same as the number's
    // calling code, then use NATIONAL format; otherwise, use INTERNATIONAL format.
    //
    public static FormatPhoneNumberForDisplay(phone: string | undefined, regionCode: any): string {
        if (phone && phone !== '') {
            const parsedNumber = parsePhoneNumberFromString(phone, regionCode);
            if (parsedNumber) {
                if (getCountryCallingCode(regionCode) === parsedNumber.countryCallingCode) {
                    return parsedNumber.formatNational();
                }
                return parsedNumber.formatInternational();
            } else {
                return phone; // We couldn't parse, just send back original
            }
        }

        return '';
    }

    //
    // Format the given phone number, based on the E164 format
    //
    public static FormatPhoneNumberForStorage(phone: string | undefined, regionCode: any): string {
        if (phone && phone !== '') {
            const parsedNumber = parsePhoneNumberFromString(phone, regionCode);
            if (parsedNumber) {
                return parsedNumber.format('E.164');
            } else {
                return phone; // We couldn't parse, just send back original
            }
        }

        return '';
    }

    public static FormatUserNameForDisplay(firstName: string | undefined, lastName: string | undefined): string {
        if (!firstName) return '';
        return lastName ? `${firstName} ${lastName}` : firstName;
    }

    //
    // socialMediaInputValid returns true if the social media link for the provider
    // is valid.  It's not really a link, but can be a full URL or just the username.
    // '@' is handled specially, since users don't know when to put it in and when
    // not to.  We allow no '@' for medium, or allow '@' for twitter.  The '@' sign
    // may be added or removed before submitting to the server.
    //
    public static socialMediaInputValid(platform: string, value: string): boolean {
        switch (platform) {
            // Add all full url validation here
            case SharedConstants.SOCIAL_LINKEDIN:
            case SharedConstants.SOCIAL_PERSONAL:
                return validator.isURL(value);
                break;
            case SharedConstants.SOCIAL_FACEBOOK:
                // Alphanumeric characters(A–Z, 0–9) and periods ("."), must be >= 5 characters
                return value.length >= 5 && value.match(Utils.facebookRE) !== null;
                break;
            case SharedConstants.SOCIAL_TWITTER:
                // Alphanumeric characters(A–Z, 0–9) and underscores ("_"), must be <= 15 characters
                return value.length <= 15 && value.match(Utils.twitterRE) !== null;
                break;
            case SharedConstants.SOCIAL_INSTAGRAM:
                // Alphanumeric characters(A–Z, 0–9), underscores ("_"), and periods ("."), must be <= 30 characters
                return value.length <= 30 && value.match(Utils.instagramRE) !== null;
                break;
            case SharedConstants.SOCIAL_TIKTOK:
                // Alphanumeric characters(A–Z, 0–9), underscores ("_"), and periods ("."), must be <= 24 characters
                return value.length <= 24 && value.match(Utils.tiktokRE) !== null;
                break;
            case SharedConstants.SOCIAL_PINTEREST:
                // Alphanumeric characters(A–Z, 0–9), must be >= 3 && <= 30 characters
                return value.length >= 3 && value.length <= 30 && value.match(Utils.pinterestRE) !== null;
                break;
            case SharedConstants.SOCIAL_SOUNDCLOUD:
                // Lower case alphanumeric characters(a-z, 0–9), underscores ("_"), and hyphens ("-")
                return value.match(Utils.soundcloudRE) !== null;
                break;
        }

        //
        // Fallback, which just makes sure there's no http or https, since it should be a username
        // at this point.
        //
        return !value.toLowerCase().includes('http://') && !value.toLowerCase().includes('https://');
    }

    //
    // cleanSocialMediaLink returns a clean version of the social media link.
    // It's not really a link, but can be a full URL or just the username.
    // '@' is handled specially, since users don't know when to put it in and when
    // not to.  We allow no '@' for medium, or allow '@' for twitter.  The '@' sign
    // may be added or removed before submitting to the server.
    //

    public static cleanSocialMediaLink(platform: string, value: string): string {
        let newVal = value;

        switch (platform) {
            case SharedConstants.SOCIAL_TWITTER:
            case SharedConstants.SOCIAL_FACEBOOK:
            case SharedConstants.SOCIAL_INSTAGRAM:
            case SharedConstants.SOCIAL_GITHUB:
            case SharedConstants.SOCIAL_YOUTUBE:
            case SharedConstants.SOCIAL_PINTEREST:
            case SharedConstants.SOCIAL_SOUNDCLOUD:
            case SharedConstants.SOCIAL_SPOTIFY:
                if (value.startsWith('@')) {
                    newVal = value.substring(1);
                }
                break;
            case SharedConstants.SOCIAL_TIKTOK:
            case SharedConstants.SOCIAL_MEDIUM:
                if (value !== '' && !value.startsWith('@')) {
                    newVal = '@' + value;
                }
                break;
        }

        return newVal;
    }

    // Get the center of an array of lat/lon values.  Taken from
    // SO: https://stackoverflow.com/a/30033564
    //
    // @param latLonArray array of objects with latitude and longitude
    //   pairs in degrees.
    //
    // @return object with the center latitude longitude pairs in
    //   degrees.
    //
    public static getLatLonCenter(latLonArray: any[]) {
        let sumX = 0;
        let sumY = 0;
        let sumZ = 0;

        for (const val of latLonArray) {
            const lat = Utils.degr2rad(val.lat);
            const lng = Utils.degr2rad(val.lon);

            // sum of cartesian coordinates
            sumX += Math.cos(lat) * Math.cos(lng);
            sumY += Math.cos(lat) * Math.sin(lng);
            sumZ += Math.sin(lat);
        }

        const avgX = sumX / latLonArray.length;
        const avgY = sumY / latLonArray.length;
        const avgZ = sumZ / latLonArray.length;

        // convert average x, y, z coordinate to latitude and longitude
        const retLng = Math.atan2(avgY, avgX);
        const hyp = Math.sqrt(avgX * avgX + avgY * avgY);
        const retLat = Math.atan2(avgZ, hyp);

        return { lat: Utils.rad2degr(retLat), lon: Utils.rad2degr(retLng) };
    }

    //
    // Samsung devices have a numeric keypad without a minus sign.  For
    // input fields that can handle negative numbers, we cannot use
    // type="number".  They need to be text or tel, and we validate the input.
    // We only do this for specific devices.  Everything else should
    // use their normal numeric keypads if possible.
    // The mainApp caches this, so don't call during render, etc.
    //
    // tslint:disable-next-line:max-line-length
    // https://developer.samsung.com/forum/thread/predictive-text-service-not-honoring-auto-correct-attribute/201/315078?boardName=SDK& startId=zzzzz~
    // We decided on the type="tel" hack for now.  If Samsung honored the
    // autocomplete, autocorrect, autocapitalize, spellcheck fields, we could
    // go back to "text".  The "tel" keyboard is a pain for minus and period, but
    // most people won't need those anyway.
    //
    public static needNumericKeyboardHack(deviceInfo: string, userAgent: string): boolean {
        if (deviceInfo !== '' && deviceInfo.includes('samsung')) {
            return true;
        }

        if (userAgent === '') {
            return false;
        }

        //
        // Samsung's, mweb user agent does not appear to always include Samsung.  The model
        // number is included (e.g., SM-G955F).  Check for the name or the beginning
        // of the model.  If the model check starts to include too many devices that
        // have good keyboards, we could start checking for certain models only.  Super
        // hard to keep up to date though.
        //
        // We do not check for only 'mobile', since that would force every mweb user
        // to have a text keyboard for specificNumber inputs.  Maybe that's not
        // a big deal.
        //
        // Hopefully everyone uses the app, which has the proper detection (see above).
        //
        const caseless = userAgent.toLowerCase();
        return caseless.includes('mobile') && (caseless.includes('samsung') || caseless.includes('sm-'));
    }

    //
    // See note above about devices with unusable numeric keypads.  This utility
    // function sees if the input with type="text|tel" has a valid number or float.
    //
    public static isNumberForKeyboardHack(str: string | undefined): boolean {
        if (!str || str === '') {
            return false;
        }

        if (!str.match(keyboardHackRegex)) {
            return false;
        }

        return true;
    }

    //
    // Return true if inside one of our apps.  We are passed in the userAgent, and
    // we return true if it contains our special additions.  This will return
    // true if it's one of 'our' web views inside our app (we set the user agent).
    // If using Mobile Safari, this will be false (use MobileWeb function instead).
    //
    public static InsideApp(userAgent: string): boolean {
        return userAgent.includes('ios/' + constants.COMPANY_NAME) || userAgent.includes('android/' + constants.COMPANY_NAME);
    }

    // Return true if mobile browser on iPhone
    public static MobileWebIos(userAgent: string): boolean {
        return userAgent.includes('iPhone');
    }

    // Return true if mobile browser on Android
    public static MobileWebAndroid(userAgent: string): boolean {
        return userAgent.includes('Android');
    }

    //
    // All private stuff needs to be at the end
    //

    private static readonly facebookRE = new RegExp(/^[a-zA-Z0-9\.\@]+$/, 'i');
    private static readonly twitterRE = new RegExp(/^[a-zA-Z0-9\_\@]+$/, 'i');
    private static readonly instagramRE = new RegExp(/^[a-zA-Z0-9\_\.\@]+$/, 'i');
    private static readonly tiktokRE = new RegExp(/^[a-zA-Z0-9\_\.\@]+$/, 'i');
    private static readonly pinterestRE = new RegExp(/^[a-zA-Z0-9\@]+$/, 'i');
    private static readonly soundcloudRE = new RegExp(/^[a-z0-9\_\-\@]+$/, 'i');

    private static rad2degr(rad: number): number {
        return (rad * 180) / Math.PI;
    }
    private static degr2rad(degr: number): number {
        return (degr * Math.PI) / 180;
    }
}

const utils = new Utils();
export default utils;
