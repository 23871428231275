/*
 * Copyright 2022-2023 Lola - All rights reserved.
 * File: pubsub.ts
 * Project: lola
 */

import { log } from '@/tsfiles/errorlog';

//
// Should match server structure
//
interface PubSubEvent {
    event: string;
    jsonData: string;
}

class PubSubClient {
    private eventSource?: EventSource;
    private onNewPubSubMessage?: (message: PubSubEvent) => void;
    private clientOnError?: (e: Event) => void;

    constructor(onNewPubSubMessage: any, clientOnError: any) {
        if (typeof EventSource === 'undefined') {
            log('Browser does not support EventSource');
        } else {
            this.onNewPubSubMessage = onNewPubSubMessage;
            this.eventSource = new EventSource('/sub_upstream');

            const client = this;
            this.eventSource.onmessage = (e: MessageEvent) => {
                if (client.onNewPubSubMessage) {
                    client.onNewPubSubMessage(JSON.parse(e.data));
                }
            };

            this.eventSource.onerror = clientOnError;
        }
    }

    public stop() {
        if (this.eventSource) {
            this.eventSource.close();
            this.eventSource = undefined;
        }
    }
}

export { PubSubClient, PubSubEvent };
