
    import Vue, { PropOptions } from 'vue';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import Avatar from '@/components/Avatar.vue';
    import { SmartyService, SmartyFilterRequest, PublicUser } from '@bostonventurestudio/lola-api';

    export default Vue.extend({
        name: 'Smarty',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            // Id of this smarty, which is passed back to the parent on $emit
            id: {
                type: Number,
            },

            // Type of Smarty (content, people, location, etc.)
            smartyType: {
                type: String,
                required: true,
            },

            // Should a filter be applied to this search (job, ignoreUser, etc.)?
            filterType: {
                type: String,
                required: false,
                default: '',
            },

            // Any data needed to apply the filter
            filterData: {
                type: String,
                required: false,
                default: '',
            },

            // Used as the input placeholder text message
            smartyPlaceholder: {
                type: String,
                required: true,
            },

            // What the parent wants the initial value to be.
            initialValue: {
                type: String,
                required: false,
                default: '',
            },

            // For initial validity, pass in unique item as well as initialValue
            initialUniqueItemId: {
                type: Number,
                required: false,
                default: () => undefined as number | undefined,
            } as PropOptions<number | undefined>,

            // Default is black text on white, this reverses the colors
            reverseColors: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Is the user required to select or enter something?
            valueRequired: {
                type: Boolean,
                required: false,
                default: false,
            },

            // The user must select something, or only have 1 item in the list.
            selectionRequired: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Do we want default values on focus (no character input from user yet)?
            showDefaultValuesOnEmpty: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Should this be an autofocus input?  Set to true or false string
            setFocus: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Has embedded search icon on right
            embeddedSearchIconRight: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Has embedded search icon on left
            embeddedSearchIconLeft: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Has embedded map icon on left
            embeddedMapIcon: {
                type: Boolean,
                required: false,
                default: false,
            },

            // The left side borders are rounded
            roundedLeftBorders: {
                type: Boolean,
                required: false,
                default: false,
            },

            // The right side borders are rounded
            roundedRightBorders: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                inputData: this.initialValue,
                active: false,
                processingClickOnDropdown: false,
                processingTabOut: false,
                keyboardActiveIndex: -1,

                uniqueItemId: this.initialUniqueItemId, // Unique item item for the selected item, or initial one
                content: [] as string[], // non-bolded strings that go into inputData upon selection
                boldContent: [] as string[], // bolded list of dropdown strings
                people: [] as PublicUser[], // For people search

                sendSequence: 0,
                lastProcessed: 0,
            };
        },

        watch: {
            //
            // The parent can change the initialValue at any time.  When that happens, we
            // want to reset the input box value.
            //
            initialValue: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        this.inputData = newVal;
                    } else {
                        this.inputData = '';
                    }
                },
            },
        },

        computed: {
            uniqueId(): string {
                return Math.random().toString(36).substring(2, 15);
            },

            inputValid(): boolean {
                let valid = this.inputData !== '';
                if (!this.selectionRequired && !this.valueRequired) {
                    valid = true;
                } else if (this.selectionRequired) {
                    valid = this.inputData !== '' && (this.boldContent.length === 1 || (this.uniqueItemId !== undefined && !this.active));
                }

                this.$emit('smarty-validity', valid);
                return valid;
            },
        },

        methods: {
            //
            // Handle changes to the input.  We separate input change and keypress, since keypress on
            // mobile safari wouldn't set the inputData properly, even with a nextTick.
            //
            inputChanging() {
                const val = this.inputData.trim();
                this.$emit('smarty-input-changed', val);

                if (!this.showDefaultValuesOnEmpty && val === '') {
                    this.clearAllData(true);
                    return;
                }

                switch (this.smartyType) {
                    case 'people':
                        this.getPeople(this.inputData);
                        break;
                }
            },

            //
            // We put in a form, even though it's not needed, because of Chrome autocomplete.
            // If the form stays, we need to capture onSubmit, otherwise the entire page
            // will refresh when 'enter' is hit.
            //
            onSubmit(evt: any) {
                evt.preventDefault();
            },

            //
            // KeyDown only handles non-ajax smarty call events (arrows, escape, etc.).
            //
            keyDown(evt: KeyboardEvent) {
                switch (evt.keyCode) {
                    case 40:
                        // Down arrow
                        if (this.content.length === 0) {
                            return;
                        }

                        if (this.keyboardActiveIndex < 0 || this.keyboardActiveIndex === this.content.length - 1) {
                            this.keyboardActiveIndex = 0;
                        } else {
                            this.keyboardActiveIndex++;
                        }

                        this.inputData = this.content[this.keyboardActiveIndex];
                        break;
                    case 38:
                        // Up arrow
                        if (this.content.length === 0) {
                            return;
                        }

                        if (this.keyboardActiveIndex <= 0) {
                            this.keyboardActiveIndex = this.content.length - 1;
                        } else {
                            this.keyboardActiveIndex--;
                        }

                        this.inputData = this.content[this.keyboardActiveIndex];
                        break;
                    case 9:
                        // Tab (lose of focus)
                        this.processingTabOut = true;
                        this.tellParentAboutSelection(this.keyboardActiveIndex);
                        break;
                    case 13:
                        // Enter key
                        this.processingTabOut = true;
                        (this.$refs.smartyInput as HTMLInputElement).blur(); // Force focus lose??????? // TODO:

                        this.tellParentAboutSelection(this.keyboardActiveIndex);
                        break;
                    case 27:
                        // Escape.  Close smarty without saving, restoring original value passed in.
                        const hadUserInput = this.inputData && this.inputData !== '';
                        this.clearAllData(true);
                        this.inputData = this.initialValue;
                        this.$emit('smarty-input-changed', this.inputData);

                        //
                        // If there was no initialValue, escape might do something special.  For example,
                        // a popup box maybe be closed by the parent.
                        //
                        if (!hadUserInput && this.inputData === '') {
                            this.$emit('smarty-escape-on-empty');
                        }
                        break;
                }
            },

            //
            // Handle click inside on of the smarty list entries.  It's not really a click, it's a
            // mousedown.  A click would come after a blur, triggering the lostFocus() first.  We
            // want to handle the selection before the input box loses focus.
            //
            processClick(idx: number) {
                this.processingClickOnDropdown = true;
                this.tellParentAboutSelection(idx);

                //
                // Setting focus with nextTick() sounds like the right thing to do here,
                // but that causes the smarty to come back up (similar to losing focus then
                // gaining focus again).  When focus is set here, it looks dumb...
                //
                // Put focus back to input box
                // this.$nextTick(() => {
                //    (this.$refs.smartyInput as HTMLInputElement).focus();
                // });
            },

            //
            // When the input box gains focus, we want to pop up the smarty box.
            //
            gainFocus() {
                if (!this.showDefaultValuesOnEmpty && this.inputData === '') {
                    return;
                }

                switch (this.smartyType) {
                    case 'people':
                        this.getPeople(this.inputData);
                        break;
                }
            },

            //
            // Losing focus from the input box can happen when the user clicks anywhere on the page;
            // however, clicking on a dropdown item will also cause lose of focus.  To solve the
            // problems caused by this we use mousedown instead of click, which will happen before
            // the blur event.  Inside blur, we then check to make sure it wasn't caused by a click.
            //
            lostFocus() {
                if (!this.processingClickOnDropdown && !this.processingTabOut) {
                    this.tellParentAboutSelection(-1);
                }

                this.processingTabOut = false;
                this.processingClickOnDropdown = false;
            },

            clearContent() {
                this.content = [];
                this.people = [];
                this.boldContent = [];
            },

            //
            // Do not reset processingClickOnDropdown, since that would screw up the
            // mousedown/blur handling.
            //
            clearAllData(resetItemId: boolean) {
                this.clearContent();
                this.active = false;
                this.keyboardActiveIndex = -1;

                if (resetItemId) {
                    this.uniqueItemId = undefined as number | undefined;
                }
            },

            tellParentAboutSelection(idx: number) {
                if (idx >= 0) {
                    // Use currently selected item
                    switch (this.smartyType) {
                        case 'people':
                            this.uniqueItemId = this.people[idx].userId;
                            this.$emit('smarty-selection', {
                                id: this.id,
                                newVal: this.people[idx],
                            });
                            this.inputData = this.people[idx].firstName as string;
                            break;
                    }
                }

                this.clearAllData(false);
                // this.inputData = '';
            },

            // NOTE: RegExp will error out if user types in parens, and it matches a user?
            boldText(input: string, fullStr: string): string {
                const boldRes = fullStr;
                const re = new RegExp('(' + input + ')', 'gi');
                const boldText = boldRes.replace(re, '<b>$&</b>');
                return '<span class="d-inline-block text-truncate">' + boldText + '</span>';
            },

            async getPeople(inputStr: string) {
                const localSequence = this.sendSequence;
                this.sendSequence++;
                try {
                    const data = await ApiUtils.apiWrapper(SmartyService.getPeople, {
                        chars: inputStr,
                        filterType: this.filterType,
                        filterData: this.filterData,
                    } as SmartyFilterRequest);

                    // Prevent old queries from overwriting newer ones.
                    if (localSequence < this.lastProcessed) {
                        return; // Do nothing
                    }
                    this.lastProcessed = localSequence;

                    const list = data.results;
                    if (list !== null && list !== undefined && list.length > 0) {
                        this.clearContent();

                        for (const res of list) {
                            let displayName = '';
                            if (res.firstName) {
                                displayName = res.firstName;
                            }

                            if (res.lastName) {
                                if (displayName !== '') {
                                    displayName += ' ';
                                }
                                displayName += res.lastName;
                            }

                            let addOn = '';
                            if (displayName === '') {
                                displayName = '@' + res.userId;
                            } else if (res.userId) {
                                addOn += ' (@' + res.userId + ')';
                            }

                            if (displayName !== '') {
                                this.content.push(displayName);
                                this.people.push(res);
                                this.boldContent.push(this.boldText(displayName.includes(inputStr) ? inputStr : displayName, displayName + addOn));
                            } else {
                                // ERROR
                            }
                        }
                        this.active = true;
                    } else {
                        this.clearAllData(true);
                    }
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
                return;
            },

            //
            // If there's at least one logo in the result set, this function will
            // get called and we will create the row.  These are NOT used for people,
            // since those use the url-avatar component.
            //
            getImageHtml(input: string, fullStr: string, img: string | undefined, cachedImg: string | undefined, smartyType: string) {
                const boldText = this.boldText(input, fullStr);
                let html = '<div class="smarty-img-div mr-2 align-items-center text-center">';

                if (cachedImg !== undefined && cachedImg !== '') {
                    html += '<img class="smarty-img" referrerpolicy="no-referrer" src="' + cachedImg + '"> ';
                } else if (img !== undefined && img !== '') {
                    html += '<img class="smarty-img" referrerpolicy="no-referrer" src="' + img + '"> ';
                }

                html += '</div>';
                html += boldText;

                return html;
            },
        },
    });
