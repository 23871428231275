
    import mixins from 'vue-typed-mixins';
    import { mapGetters } from 'vuex';
    import VueConstants from '@/components/VueConstants';
    import * as constants from '@/tsfiles/constants';

    export default mixins(VueConstants).extend({
        name: 'MainMenu',

        components: {},

        props: {
            // Default is vertical unless horizontal set.
            horizontal: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                selection: this.$store.getters.getCurrentMenu,
            };
        },

        watch: {
            //
            // There are actually 2 menus rendered at the same time.  Both the horizontal and vertical
            // menu are available, just hidden depending on the size of the browser.  The other
            // menu needs to know when a route changed.
            //
            // tslint:disable-next-line
            menuChanged: {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal !== oldVal) {
                        this.selection = newVal;
                    }
                },
            },

            //
            // The route can change outside the menu, such as the user typing in the
            // address bar, or hitting the back/forward browser buttons.  However, we
            // only want to set the menu if it's a menu item :)
            //
            '$route.name': {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    if (newVal && newVal !== oldVal) {
                        //
                        // If not my own page or not on feed, unset menu
                        //
                        if (
                            newVal !== constants.ROUTE_USER_PROSPECTS &&
                            !this.$store.getters.isAuthedUserId(this.$router.currentRoute.params.userId)
                        ) {
                            this.$store.commit('setCurrentMenu', '');
                            return;
                        }

                        switch (newVal) {
                            case constants.ROUTE_USER_CALENDAR:
                            case constants.ROUTE_USER_PROSPECTS:
                            case constants.ROUTE_USER_PROFILE:
                                this.$store.commit('setCurrentMenu', newVal);
                                break;
                            default:
                                this.$store.commit('setCurrentMenu', '');
                                break;
                        }
                    }
                },
            },
        },

        computed: {
            ...mapGetters({
                menuChanged: 'getCurrentMenu',
            }),

            isSignedIn(): boolean {
                return this.$store.getters.isSignedIn;
            },
        },

        methods: {
            //
            // Something from the main menu was selected.  Goto the associated
            // page (route).  router.ts knows to go to FirebaseSignin, but we need to
            // do it here to get the redirect parameters added.
            //
            menuSelect(routeName: string) {
                this.$store.commit('setCurrentMenu', routeName);

                //
                // If not signed in, goto FirebaseSignin with
                // 'gotoPage' as the redirect if we are not hiding signin.
                //
                if (this.$store.getters.isSignedOut) {
                    if (this.$store.getters.hideWebsiteSignin) {
                        if (this.$router.currentRoute.name !== constants.ROUTE_MARKETING) {
                            this.$router.push({ name: constants.ROUTE_MARKETING });
                        }

                        return;
                    }

                    const query = {
                        redirect: 'aupr?page=' + routeName,
                    };

                    this.$router.push({
                        name: constants.ROUTE_FIREBASE_SIGNIN,
                        query,
                    });

                    return;
                }

                //
                // Goto the new route if not already there.  We need to check for
                // userId change as well, since the Lists have the same route (authed user
                // and public user).
                //
                if (this.$router.currentRoute.name !== routeName || this.$router.currentRoute.params.userId !== this.$store.getters.getAuthedUserId) {
                    this.$router.push({
                        name: routeName,
                        params: { userId: this.$store.getters.getAuthedUserId },
                    });
                }
            },
        },
    });
