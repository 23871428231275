
    import { Vue } from 'vue-property-decorator';
    import { PublicUserForAdmin } from '@bostonventurestudio/lola-api';
    import { PropType } from 'vue';

    export default Vue.extend({
        name: 'BadWordsModal',

        components: {},

        props: {},

        data() {
            return {};
        },

        mounted() {},

        computed: {},

        methods: {},
    });
