
    import Vue from 'vue';
    import validator from 'validator';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
    import { Bold, Italic, Link, Image, ListItem, BulletList, OrderedList } from 'tiptap-extensions';

    export default Vue.extend({
        name: 'RichText',

        components: {
            'editor-content': EditorContent,
            'editor-menu-bar': EditorMenuBar,
            'modal-alert': ModalAlert,
        },

        props: {
            // v-model value for textarea, or just plain value when readonly
            value: {
                type: String,
                required: true,
            },

            // If button text is not empty, show a submit button, when there's text
            buttonText: {
                type: String,
                required: false,
                default: '',
            },

            // Should the editor be in read only mode?
            readonly: {
                type: Boolean,
                required: false,
                default: false,
            },

            // Show a border?
            noBorder: {
                type: Boolean,
                required: false,
                default: false,
            },

            // What is the maximum number of characters allowed?
            maxCharacters: {
                type: Number,
                required: false,
                default: 2500,
            },
        },

        data() {
            return {
                editor: undefined as Editor | undefined,
                currentContentLength: 0,
                validInput: false,

                //
                // In order to allow parent component to use v-model, we need
                // to emit the input.  Since the parent can change the input, we
                // want to skip value changes in 'watch' if it was caused by
                // the emit.
                //
                emitAfterOnUpdate: false,

                // Link info
                showLinkModal: false,
                newLinkUrl: '',
                showRemoveLinkButton: false,
                linkNoSelection: false,
                linkCommand: undefined as any | undefined,

                // Image info
                showImageModal: false,
                newImageUrl: '',
                imageCommand: undefined as any | undefined,
            };
        },

        watch: {
            value: {
                immediate: true,
                handler(newVal: string, oldVal: string) {
                    // Ignore changes caused by our own emit.
                    if (this.emitAfterOnUpdate) {
                        this.emitAfterOnUpdate = false;
                        return;
                    }

                    if (this.editor) {
                        this.editor.setContent(newVal);
                        this.setCharacterLength();
                    }
                },
            },
        },

        mounted() {
            this.editor = new Editor({
                extensions: [new Bold(), new Italic(), new Link(), new Image(), new ListItem(), new BulletList(), new OrderedList()],
                content: this.value,
                onUpdate: this.onUpdate,
                editable: !this.readonly,
                editorProps: {
                    //
                    // Only allow input if we haven't hit the max characters (excluding tiptap markup).
                    //
                    handleTextInput: (view: any) => {
                        if (view.state.doc.textContent.length >= this.maxCharacters && view.state.selection.empty) {
                            return true;
                        }
                    },

                    //
                    // Ignore paste if it will make the number of characters too large.
                    // I tried to only paste the number of characters allowed, but had
                    // trouble.  Should try again...
                    //
                    handlePaste: (view: any, event: any, slice: any) => {
                        if (view.state.doc.textContent.length + slice.size > this.maxCharacters) {
                            return true;
                        }
                    },
                },
            });

            this.setCharacterLength();
            this.resetEditorFocus();
        },

        beforeDestroy() {
            if (this.editor) {
                this.editor.destroy();
            }
        },

        computed: {},

        methods: {
            //
            // urlValid is called to determine the input state, as well
            // as submit button disable.
            //
            urlValid(url: string): boolean {
                return validator.isURL(url);
            },

            //
            // Whenever input changes, tell the parent.  This is how
            // the parent can use v-model.
            //
            onUpdate(val: Editor) {
                if (val && val.getHTML) {
                    this.setCharacterLength();
                    this.emitAfterOnUpdate = true;
                    this.$emit('input', val.getHTML());
                }
            },

            //
            // How many characters are used so far, ignoring all the tiptap markup.
            //
            setCharacterLength() {
                // @ts-ignore
                if (this.editor && this.editor.state && this.editor.state.doc && this.editor.state.doc.textContent) {
                    // @ts-ignore
                    this.currentContentLength = this.editor.state.doc.textContent.length;
                } else {
                    this.currentContentLength = 0;
                }

                this.validInput = this.currentContentLength != 0;
            },

            //
            // If there's an editor, set focus to it.  Do it inside
            // nextTick, since this could be called in mounted, or when
            // another component is finishing up.
            //
            resetEditorFocus() {
                if (this.editor) {
                    this.$nextTick(() => {
                        if (this.editor) {
                            this.editor.focus();
                        }
                    });
                }
                this.linkNoSelection = false;
                this.showImageModal = false;
            },

            //
            // The user clicked on the link icon.  We want
            // to show the link add/edit modal, assuming the user
            // is on an existing link, or has text selected for a
            // new link.
            //
            bringUpLinkModal(command: any, attrs: any) {
                this.newLinkUrl = '';
                this.linkNoSelection = false;

                //
                // If there is an href, it means we are inside a link already.
                //
                if (attrs && attrs.href) {
                    this.newLinkUrl = attrs.href;
                    this.showRemoveLinkButton = true;
                } else {
                    //
                    // If not within a link and no text selected, show warning in modal.
                    // ts has no block level ignore yet...
                    //
                    // @ts-ignore
                    if (this.editor && this.editor.selection && this.editor.state) {
                        // @ts-ignore
                        const from = this.editor.selection.from;
                        // @ts-ignore
                        const to = this.editor.selection.to;

                        if (from === to) {
                            this.linkNoSelection = true;
                            return;
                        }
                    }
                }

                this.linkCommand = command;
                this.showLinkModal = true;
            },

            //
            // Complete add/edit of the link modal.
            //
            addEditLink() {
                if (this.linkCommand && this.newLinkUrl !== '') {
                    this.linkCommand({ href: this.newLinkUrl });
                }

                this.closeLinkModal();
            },

            //
            // Remove the link (not the text).
            //
            removeLink() {
                if (this.linkCommand) {
                    this.linkCommand({ href: null });
                }

                this.closeLinkModal();
            },

            //
            // Close the link modal, reseting the focus at the end.
            //
            closeLinkModal() {
                this.showLinkModal = false;
                this.showRemoveLinkButton = false;
                this.linkNoSelection = false;
                this.resetEditorFocus();
            },

            //
            // Bring up the image modal, filling in
            // newImageUrl if there's already an image selected.
            //
            bringUpImageModal(command: any, attrs: any) {
                this.newImageUrl = '';
                if (attrs && attrs.src) {
                    this.newImageUrl = attrs.src;
                }

                this.imageCommand = command;
                this.showImageModal = true;
            },

            //
            // Save the image src and reset the editor focus.
            //
            addEditImage() {
                this.showImageModal = false;
                if (this.imageCommand && this.newImageUrl !== '') {
                    this.imageCommand({ src: this.newImageUrl });
                }

                this.resetEditorFocus();
            },
        },
    });
