
    import Vue, { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import * as constants from '@/tsfiles/constants';
    import VueConstants from '@/components/VueConstants';
    import { getNotificationStrings, notificationClickHandler } from '@/tsfiles/notificationutils';
    import { PageMessage } from '@/tsfiles/interfaces';
    import Avatar from '@/components/Avatar.vue';
    import { SharedConstants, Notification, NotificationList } from '@bostonventurestudio/lola-api';
    import { logInvalidParams } from '@/tsfiles/errorlog';

    export default mixins(VueConstants).extend({
        name: 'notifications',

        components: {
            'url-avatar': Avatar,
        },

        props: {
            // Notifications list
            notificationList: {
                type: Object,
                required: false,
            } as PropOptions<NotificationList>,

            // Should we do the slide in animation
            slideInAnimation: {
                type: Boolean,
                required: false,
                default: false,
            },
        },

        data() {
            return {
                notifications: [] as Notification[],
                moreToShow: false,
                fromEmail: false,
                firstSlideInComplete: false,
                onNotificationsPage: false,
            };
        },

        watch: {
            //
            // Save this.onNotificationsPage when route is changing.  We don't want the
            // see-all when on the notification page.
            //
            '$route.name': {
                immediate: true,
                handler(newVal, oldVal) {
                    this.onNotificationsPage = newVal === constants.ROUTE_USER_NOTIFICATIONS;
                },
            },

            // tslint:disable-next-line
            notificationList: {
                immediate: true,
                deep: true,
                handler(newVal: NotificationList, oldVal: NotificationList) {
                    if (newVal && newVal !== oldVal) {
                        if (newVal.list && newVal.list.length > 0) {
                            this.notifications = newVal.list;

                            if (newVal.totalItemsIrregardlessOfPaging) {
                                this.moreToShow = newVal.list.length < newVal.totalItemsIrregardlessOfPaging;
                            } else {
                                this.moreToShow = false;
                            }
                        } else {
                            this.notifications = [] as Notification[];
                            this.moreToShow = false;
                        }
                    }
                },
            },

            // tslint:disable-next-line
            slideInAnimation: {
                immediate: true,
                handler(newVal, oldVal) {
                    if (newVal) {
                        // With the new aggregated notifications, don't do slide in if the first item is an aggregated
                        // notification and the count is > 1.  We also check firstSlideInComplete, since we want
                        // don't want a slide-in every time the aggregation count changes inside the notification.
                        //
                        if (
                            this.firstSlideInComplete &&
                            this.notifications &&
                            this.notifications[0] &&
                            this.notifications[0].count &&
                            this.notifications[0].type === SharedConstants.NOTIFICATION_TYPE_AGGREGATION &&
                            this.notifications[0].count > 1
                        ) {
                            // Tell parent animation is complete, even though we skipped
                            this.$emit('done-with-animation');
                            return;
                        }

                        this.firstSlideInComplete = false;

                        // Do in nextTick so the div is ready
                        this.$nextTick(() => {
                            this.doSlideIn();
                        });
                    }
                },
            },
        },

        computed: {},

        methods: {
            doSlideIn() {
                const div = this.$refs.slider as HTMLDivElement;
                if (div) {
                    div.classList.add('show-animation-in');
                    setTimeout(() => {
                        div.classList.remove('show-animation-in');
                        this.$emit('done-with-animation');
                        this.firstSlideInComplete = true;
                    }, 2200);
                } else {
                    this.$emit('done-with-animation');
                }
            },

            //
            // Called when the notification slider is activated and slides in from
            // the left.  We want a short string to display.
            //
            getShortNotificationString(msg: Notification): string {
                const shortAndLong = getNotificationStrings(msg);
                if (shortAndLong.short === '') {
                    logInvalidParams(this.$options.name, 'getShortNotificationString', msg);
                    return '';
                }

                return shortAndLong.short;
            },

            //
            // Called when the user clicks the notification icon to show
            // the popup list of notifications.  This string can be slightly
            // larger than the short one that slides in.
            //
            getLongNotificationString(msg: Notification): string {
                const shortAndLong = getNotificationStrings(msg);
                if (shortAndLong.long === '') {
                    logInvalidParams(this.$options.name, 'getLongNotificationString', msg);
                    return '';
                }

                return shortAndLong.long;
            },

            getNotificationImageUrl(msg: Notification): string {
                if (msg.isMe) {
                    return this.$store.getters.getAvatarUrl;
                } else if (msg.creator && msg.creator.avatar) {
                    if (msg.creator.avatar.cachedImageUrl) {
                        return msg.creator.avatar.cachedImageUrl;
                    } else if (msg.creator.avatar.imageUrl) {
                        return msg.creator.avatar.imageUrl;
                    }
                }

                return '/favicon-32x32.png';
            },

            //
            // When a user clicks on a notification message, we go to different places
            // depending on what the notification is. The MainApp gets the
            // closeSingleNotification and will handle deleting it.
            //
            notificationClick(msg: Notification) {
                //
                // For now, we only handle one click, then the popover is closed.
                //
                const popover = this.$refs.notificationPopover as any;
                if (popover) {
                    popover.$emit('close');
                }

                notificationClickHandler(msg);
                this.$emit('clear-single-notification', msg);
            },

            clearNotifications() {
                this.$emit('clear-notifications', null);
            },

            seeAllNotifications() {
                // Close popup
                const popover = this.$refs.notificationPopover as any;
                if (popover) {
                    popover.$emit('close');
                }

                if (this.$router.currentRoute.name !== constants.ROUTE_USER_NOTIFICATIONS) {
                    this.$router.push({
                        name: constants.ROUTE_USER_NOTIFICATIONS,
                        params: { userId: this.$store.getters.getAuthedUserId },
                    });
                }
            },
        },
    });
