
    import Vue from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { logInvalidParams, logAccessDenied } from '@/tsfiles/errorlog';
    import * as constants from '@/tsfiles/constants';
    import { ApiUtils } from '@/tsfiles/apiutils';
    import { Utils } from '@/tsfiles/utils';
    import { RoleUtils } from '@/tsfiles/roleutils';
    import AddAdminModal from '@/generic-modals/AddAdminModal.vue';
    import ModalAlert from '@/generic-modals/ModalAlert.vue';
    import { SharedConstants, AdminService, AdminRole, AdminRoleList } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'SuperAdminRoles',

        components: {
            'add-admin-modal': AddAdminModal,
            'modal-alert': ModalAlert,
        },

        props: {},

        data() {
            return {
                showAddAdminModal: false,
                submitting: false,
                adminsFetched: false,
                currentAdmins: [] as AdminRoleList[],
                selectedCheckboxes: [] as string[][],

                options: [
                    { text: 'Server Admin', value: SharedConstants.USER_SERVER_ADMIN },
                    { text: 'Support Admin', value: SharedConstants.USER_SUPPORT_ADMIN },
                    { text: 'Test Admin', value: SharedConstants.USER_TEST_ADMIN },
                ],
                filterOpenItemsType: '',
            };
        },

        mounted() {
            // Just double-check that the current user has access
            if (!RoleUtils.IsServerSuperAdmin()) {
                logAccessDenied(this.$options.name, 'mounted');
                this.$router.replace({ name: constants.ROUTE_MARKETING });
            }

            this.getSuperAdmins();
        },

        computed: {
            filteredAdmins() {
                return this.currentAdmins.filter((admin) => {
                    if (this.filterOpenItemsType === '') {
                        return true;
                    } else if (admin.roles) {
                        for (const role of admin.roles) {
                            if (role.role === this.filterOpenItemsType) {
                                return true;
                            }
                        }
                        return false;
                    }
                });
            },
        },

        methods: {
            setFilterType(type: string) {
                this.filterOpenItemsType = type;
            },

            //
            // If true is returned, the submit button will be disabled.  The submit
            // button should only be active if something changed.
            //
            disableSubmit(): boolean {
                return !this.anyAdminDirty() || !this.atLeastOneSuperAdmin();
            },

            //
            // Return true if any of the admins are dirty, meaning one of their
            // checkboxes has been changed.
            //
            anyAdminDirty(): boolean {
                for (let i = 0; i < this.currentAdmins.length; i++) {
                    if (this.isDirty(i)) {
                        return true;
                    }
                }

                return false;
            },

            //
            // Return true if at least one super admin as USER_SERVER_ADMIN role.
            // There needs to be at least one user that can edit roles.
            //
            atLeastOneSuperAdmin(): boolean {
                for (let i = 0; i < this.currentAdmins.length; i++) {
                    for (const checkboxValue of this.selectedCheckboxes[i]) {
                        if (checkboxValue === SharedConstants.USER_SERVER_ADMIN) {
                            return true;
                        }
                    }
                }

                return false;
            },

            //
            // Not very efficient doing double-loop twice.
            //
            isDirty(idx: number): boolean {
                // Loop1: anything in selectedCheckboxes but not in user's roles would be dirty
                for (const checkboxValue of this.selectedCheckboxes[idx]) {
                    let foundIt = false;
                    for (const role of this.currentAdmins[idx].roles as AdminRole[]) {
                        if (checkboxValue === role.role) {
                            foundIt = true;
                            break;
                        }
                    }

                    if (!foundIt) {
                        return true;
                    }
                }

                // Loop2: anything in user's roles but not in selectedCheckboxes would be dirty
                for (const role of this.currentAdmins[idx].roles as AdminRole[]) {
                    let foundIt = false;
                    for (const checkboxValue of this.selectedCheckboxes[idx]) {
                        if (checkboxValue === role.role) {
                            foundIt = true;
                            break;
                        }
                    }
                    if (!foundIt) {
                        return true;
                    }
                }

                return false;
            },

            adminDisplayText(admin: AdminRoleList): string {
                return admin.name + ' (@' + admin.userId + ')';
            },

            // Cancel button clicked, so reset checkboxes.
            cancel() {
                this.setCheckboxes();
            },

            //
            // Set the selectedCheckboxes array for each admin.  This string array
            // is used by b-form-checkbox-group as the v-model.
            //
            setCheckboxes() {
                for (let idx = 0; idx < this.currentAdmins.length; idx++) {
                    const roles = this.currentAdmins[idx].roles as AdminRole[];

                    Vue.set(this.selectedCheckboxes, idx, [] as string[]);

                    let num = 0;
                    for (const role of roles) {
                        this.selectedCheckboxes[idx][num] = role.role as string;
                        num++;
                    }
                }
            },

            //
            // Retrieve all the super admins from the server.
            //
            async getSuperAdmins() {
                try {
                    //
                    // Get the list of current admins first
                    //
                    const ret = await ApiUtils.apiWrapper(AdminService.getAdminUsers);
                    if (ret && ret.users && ret.users.length > 0) {
                        this.currentAdmins = JSON.parse(JSON.stringify(ret.users));
                    } else {
                        this.currentAdmins = [] as AdminRoleList[];
                    }

                    this.setCheckboxes();
                    this.adminsFetched = true;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            //
            // Submit will send in the list of checked values.  The server will
            // figure out what's new and what needs to be deleted.
            //
            async submit() {
                if (this.submitting) {
                    return;
                }
                this.submitting = true;

                //
                // Loop through each super admin, sending the new selected values to
                // the server if the roles have changed
                //
                try {
                    const currentUserRoles = [] as AdminRole[];
                    for (let i = 0; i < this.currentAdmins.length; i++) {
                        if (this.isDirty(i)) {
                            const ret = (await ApiUtils.apiWrapper(AdminService.updateAdminRoles, {
                                userId: this.currentAdmins[i].userId as number,
                                selectedRoles: this.selectedCheckboxes[i],
                            })) as AdminRoleList;

                            // If it's me being changed, I need to update my profile
                            if (this.$store.getters.isAuthedUserId(this.currentAdmins[i].userId)) {
                                this.$store.commit('setUserRoles', ret.roles);
                            }
                        }
                    }

                    //
                    // Refetch all super admins, which will reset the checkboxes.  Not
                    // super efficient, but ok for minimal usage it will get.
                    //
                    this.getSuperAdmins();
                    this.submitting = false;
                } catch (error) {
                    Utils.CommonErrorHandler(error);
                }
            },

            adminAdded() {
                this.showAddAdminModal = false;
                this.getSuperAdmins();
            },
        },
    });
