
    import { PropOptions } from 'vue';
    import mixins from 'vue-typed-mixins';
    import VueConstants from '@/components/VueConstants';
    import { Media } from '@bostonventurestudio/lola-api';

    export default mixins(VueConstants).extend({
        name: 'OffensiveMediaModal',

        components: {},

        props: {
            media: {
                type: Object,
                required: true,
            } as PropOptions<Media>,
        },

        data() {
            return {};
        },

        watch: {},

        computed: {},

        methods: {},
    });
